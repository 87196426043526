import sessionstorage from 'sessionstorage'

export const SET_USER = (state, user) => {
  state.user = user
  if (user) {
    sessionstorage.setItem('user', JSON.stringify(user))
  } else {
    sessionstorage.removeItem('user')
  }
}

export const BUSY_ON = (state) => {
  state.busy = true
}

export const BUSY_OFF = (state) => {
  state.busy = false
}

export const SERVICE_READY = (state, service) => {
  state.serviceFbReady = true
}

export const SET_HEADER_ACTIONS = (state, actions) => {
  state.headerActions = actions
}
