<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 mt-4 mb-2 text-center">
        <router-link to="/"><img src="../../assets/logo-med.jpg" class="logo" alt="" /></router-link>
      </div>
    </div>
    <div v-if="loginWithFacebookEnabled" class="row justify-content-center">
      <div class="col-sm-12 col-md-6 mt-2 text-center">
        <button type="button" class="btn btn-primary trans-uppercase" :disabled="!serviceFbReady" v-on:click="loginWithFacebook()">Login with Facebook</button>
      </div>
    </div>
    <div v-if="loginWithEmailAndPasswordEnabled">
      <div class="row justify-content-center" v-if="loginWithFacebookEnabled">
        <div class="col-sm-3 mt-4 text-center">
          <hr>
        </div>
      </div>

      <div class="row justify-content-center" v-if="loginWithFacebookEnabled">
        <div class="col-sm-12 mt-4 mb-4 text-center">
          <span>or login with username and password</span>
        </div>
      </div>

      <joi-form :data="data" :schema="validationSchema" v-on:formdataready="loginWithEmailAndPassword">
        <div class="form-row justify-content-center">
          <div class="form-group col-md-4">
            <input type="email" placeholder="email" v-model="data.email" name="email" class="form-control">
          </div>
        </div>

        <div class="form-row justify-content-center">
          <div class="form-group col-md-4">
            <input type="password" placeholder="password" v-model="data.password" name="password" class="form-control">
          </div>
        </div>

        <div class="form-row justify-content-center">
          <div class="form-group col-md-4 text-center">
            <button type="submit" class="btn btn-primary trans-uppercase">Login</button>
          </div>
        </div>
      </joi-form>
    </div>
    <error-reveal :error="error"></error-reveal>
  </div>
</template>

<script>

/* global SETTINGS */

import { mapState, mapMutations } from 'vuex'
import * as mutationTypes from '../store/mutation-types'
import authService from '../services/auth'
import ErrorReveal from '../errors/Reveal'
import JoiForm from '../inputs/joi-form'
import validationSchemas from '../validators/schemas'

export default {
  name: 'welcome',
  components: {
    JoiForm,
    ErrorReveal
  },
  data () {
    return {
      data: {
        email: null,
        password: null
      },
      validationSchema: validationSchemas['auth.login'],
      error: null,
      loginWithFacebookEnabled: SETTINGS.loginWithFacebookEnabled,
      loginWithEmailAndPasswordEnabled: SETTINGS.loginWithEmailAndPasswordEnabled
    }
  },
  computed: {
    ...mapState(['serviceFbReady'])
  },
  methods: {
    ...mapMutations([mutationTypes.SET_USER, mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),

    loginWithFacebook () {
      this[mutationTypes.BUSY_ON]()
      authService.loginWithFacebook().then((user) => {
        this[mutationTypes.BUSY_OFF]()
        this.handleUser(user)
      })
      .catch((err) => {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      })
    },

    async loginWithEmailAndPassword (formData) {
      this[mutationTypes.BUSY_ON]()
      try {
        let user = await authService.loginWithEmailAndPassword(formData)
        this.handleUser(user)
      } catch (error) {
        this.error = error
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },

    handleUser (user) {
      if (user.accessGranted) {
        this[mutationTypes.SET_USER](user)
        this.$router.replace('/')
      } else {
        this.$router.replace('/preregistered')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '../../styles/main';
</style>
