var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "joi-form",
        {
          attrs: { data: _vm.data, schema: _vm.validationSchema },
          on: { formdataready: _vm.save }
        },
        [
          _c("div", { staticClass: "card shadow mb-4" }, [
            _vm.client
              ? _c("div", { staticClass: "card-header py-3" }, [
                  _c(
                    "a",
                    {
                      staticClass: "float-right",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.back()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-fw fa-arrow-circle-left"
                      }),
                      _vm._v(" Back")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h6",
                    { staticClass: "m-0 font-weight-bold text-primary" },
                    [
                      _vm._v(_vm._s(_vm.client.partner.name) + " "),
                      _c("i", {
                        staticClass: "fas fa-fw fa-angle-double-right"
                      }),
                      _vm._v(" " + _vm._s(_vm.client.name) + " "),
                      _c("i", {
                        staticClass: "fas fa-fw fa-angle-double-right"
                      }),
                      _vm._v(" " + _vm._s(_vm.title))
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "card-body",
                class: { "danger-border": _vm.isDangerBorderNeeded }
              },
              [
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group 12" },
                    [
                      _c("error-reveal", { attrs: { error: _vm.error } }),
                      _vm._v(" "),
                      _c("info-reveal", { attrs: { message: _vm.info } })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("label", [_vm._v("Name:")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.name,
                              expression: "data.name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "name" },
                          domProps: { value: _vm.data.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "name", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("label", [_vm._v("DSA Payor:")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.dsa_payor,
                              expression: "data.dsa_payor"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "dsa_payor" },
                          domProps: { value: _vm.data.dsa_payor },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "dsa_payor",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("label", [_vm._v("DSA Beneficiary:")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.dsa_beneficiary,
                              expression: "data.dsa_beneficiary"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "dsa_beneficiary" },
                          domProps: { value: _vm.data.dsa_beneficiary },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "dsa_beneficiary",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("label", [_vm._v("Template ad FB ID:")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.lazy",
                              value: _vm.data.templateAdFbId,
                              expression: "data.templateAdFbId",
                              modifiers: { lazy: true }
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "templateAdFbId",
                            disabled: !_vm.client || _vm.data.id
                          },
                          domProps: { value: _vm.data.templateAdFbId },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.data,
                                "templateAdFbId",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          attrs: { "error-path": "templateAdFbId" }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-12" })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _c("label", [_vm._v("Facebook adset name:")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.externalNameTpl,
                              expression: "data.externalNameTpl"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", name: "externalNameTpl" },
                          domProps: { value: _vm.data.externalNameTpl },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "externalNameTpl",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-info" }, [
                          _vm._v("Serial number of the campaign: %serial% ")
                        ]),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-info" }, [
                          _vm._v("Name of the targeted page: %page% ")
                        ]),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-info" }, [
                          _vm._v("Current date: %today% ")
                        ]),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-info" }, [
                          _vm._v("Extract placeholders from message:")
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.customPlaceholders,
                              expression: "data.customPlaceholders"
                            }
                          ],
                          staticClass: "form-control mt-1 text-info",
                          attrs: { rows: "3" },
                          domProps: { value: _vm.data.customPlaceholders },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "customPlaceholders",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("small", { staticClass: "form-text text-muted" }, [
                          _vm._v("Sample: %name% = #news-[*]-category")
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          attrs: { "error-path": "externalNameTpl" }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.client && _vm.isInternalCampaignManagement
                      ? _c("div", { staticClass: "form-row" }, [
                          _c(
                            "div",
                            { staticClass: "form-group col-md-12" },
                            [
                              _c("label", [_vm._v("Targeted pages:")]),
                              _vm._v(" "),
                              _c("page-input", {
                                attrs: { pages: _vm.pages },
                                model: {
                                  value: _vm.data.pages,
                                  callback: function($$v) {
                                    _vm.$set(_vm.data, "pages", $$v)
                                  },
                                  expression: "data.pages"
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { attrs: { "error-path": "pages" } })
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.client && _vm.isExternalCampaignManagement
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-group col-md-12" }, [
                            _c("label", [_vm._v("Targeted pages:")]),
                            _vm._v(" "),
                            !_vm.data.templateAdFbId
                              ? _c(
                                  "small",
                                  { staticClass: "mt-0 form-text text-info" },
                                  [_vm._v("Please select an ad.")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.data.templateAdFbId && !_vm.data.pages.length
                              ? _c(
                                  "small",
                                  { staticClass: "mt-0 form-text text-info" },
                                  [
                                    _vm._v(
                                      "No page associated with the selected ad."
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.data.templateAdFbId && _vm.data.pages.length
                              ? _c(
                                  "p",
                                  { staticClass: "mt-0" },
                                  _vm._l(_vm.data.pages, function(page) {
                                    return _c(
                                      "small",
                                      {
                                        key: page.id,
                                        staticClass: "form-text text-info"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(page.name) +
                                            " (" +
                                            _vm._s(page.id) +
                                            ")"
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { attrs: { "error-path": "pages" } })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-12" },
                        [
                          _vm.client
                            ? _c("ad-management-policy", {
                                attrs: {
                                  currency: _vm.client.currency,
                                  savedAudiences: _vm.savedAudiences,
                                  adsPixels: _vm.adsPixels,
                                  marketingGoal: _vm.marketingGoal,
                                  actionCostMetricList:
                                    _vm.actionCostMetricList,
                                  resultMetricList: _vm.resultMetricList,
                                  errorPathPrefix: "adManagementPolicy"
                                },
                                model: {
                                  value: _vm.data.adManagementPolicy,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "adManagementPolicy",
                                      $$v
                                    )
                                  },
                                  expression: "data.adManagementPolicy"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-group col-md-12" }, [
                        _vm.data.id
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.showBufferDialog()
                                  }
                                }
                              },
                              [_vm._v("Buffer")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.data.id
                          ? _c(
                              "small",
                              { staticClass: "form-text text-info" },
                              [
                                _vm._v(
                                  "Buffer can be set after saving the adset."
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "card-footer text-muted" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary trans-uppercase",
                  attrs: { type: "submit" }
                },
                [_vm._v("Save")]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.data.id
        ? _c("buffer-dialog", {
            ref: "bufferDialog",
            attrs: { adsetprotoId: _vm.data.id, pages: _vm.pages }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }