<template>
  <div>
    <error-reveal :error="error"></error-reveal>
    <!-- <data-grid
      class="small"
      :items="items"
      :columns="columns"
      :addNewEnabled="false"
      emptytext="No items."
      refreshtext="Refresh"
      @refresh="onGridRefresh"
    ></data-grid>
    <data-store
      ref="store"
      :service="service"
      :filter="filter"
      :sort="sort"
      @beforeload="onBeforeLoad"
      @afterload="onAfterLoad"
      @dataloaded="onDataLoaded"
      @loaderror="onLoadError"
    ></data-store> -->
  </div>
</template>

<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import DataGrid from '../../lists/DataGrid'
import DataStore from '../../data/Store'
import ErrorReveal from '../../errors/Reveal'
import adsetprotoService from '../../services/adsetproto'
import i18nService from '../../services/i18n'

export default {
  name: 'buffer-item-list',
  components: {
    DataGrid,
    DataStore,
    ErrorReveal
  },
  props: {
    adsetprotoId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      columns: [{
        title: 'Created at',
        name: 'createdAt',
        width: 120
      }, {
        title: 'Processed at',
        name: 'processedAt',
        width: 120
      }, {
        title: '...',
        name: 'ads',
        align: 'center'
      }],
      service: null,
      filter: null,
      sort: null,
      error: null
    }
  },
  created () {
    this.service = () => {
      return adsetprotoService.loadBufferItems(this.adsetprotoId)
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onBeforeLoad () {
      this[mutationTypes.BUSY_ON]()
    },
    onAfterLoad () {
      this[mutationTypes.BUSY_OFF]()
    },
    onDataLoaded (items, result) {
      this.items = items.map(this.parseItem)
    },
    onLoadError (error) {
      this[mutationTypes.BUSY_OFF]()
      this.error = error
    },
    onGridRefresh () {
      this.$refs.store.load()
    },

    // '', 'contents', '', '', '', 'inspectResults', 'publishResults', ''

    parseItem (rawData) {
      return {
        id: rawData.id,
        guid: rawData.guid,
        processed: Boolean(rawData.processed),
        markedForDelete: Boolean(rawData.markedForDelete),
        processedAt: i18nService.formatDateTime(rawData.processedAt, i18nService.FMT_SHORT, i18nService.FMT_SHORT),
        createdAt: i18nService.formatDateTime(rawData.createdAt, i18nService.FMT_SHORT, i18nService.FMT_SHORT)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
