var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("error-reveal", { attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("agent-edit", {
        ref: "editDialog",
        attrs: { partner: _vm.partner },
        on: {
          saved: function($event) {
            return _vm.onAgentSaved()
          }
        }
      }),
      _vm._v(" "),
      _c("data-grid", {
        attrs: {
          items: _vm.items,
          columns: _vm.columns,
          emptytext: "No agents.",
          additemtext: "Add agent",
          refreshtext: "Refresh"
        },
        on: {
          additem: _vm.onAddNewItem,
          itemaction: _vm.onItemAction,
          refresh: _vm.onGridRefresh
        }
      }),
      _vm._v(" "),
      _c("data-store", {
        ref: "store",
        attrs: { service: _vm.service, filter: _vm.filter, sort: _vm.sort },
        on: {
          beforeload: _vm.onBeforeLoad,
          afterload: _vm.onAfterLoad,
          dataloaded: _vm.onDataLoaded,
          loaderror: _vm.onLoadError
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }