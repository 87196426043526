/* global SETTINGS */

import debug from 'debug'
import 'bootstrap'
import './styles/sb-admin-2'
import './styles/fontawesome-free/css/all.min'
import $ from 'jquery'
import 'datatables.net'
import dt from 'datatables.net-bs4'
dt(window, $)

import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import VueBreadcrumbs from './components/services/breadcrumbs'
import VueLayout from './components/services/layout'
import addInterceptors from './components/interceptors'
import App from './components/App'

import routes from './components/routes'
import store from './components/store'

if (SETTINGS.debug.enable) {
  debug.enable(SETTINGS.debug.namespaces)
} else {
  debug.disable()
}

Vue.use(VueResource)
Vue.use(VueRouter)
Vue.use(VueBreadcrumbs)
Vue.use(VueLayout)

addInterceptors(Vue.http)

export var router = new VueRouter({routes})

export var app = new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App)
})
