<template>
  <div class="form-row">
    <div class="col-md-10" v-bind:class="{'col-md-12': !files.length, 'col-md-10': files.length}">
      <div class="input-group fileupload">
        <input class="form-control" type="text" v-model="filename" readonly />
        <div class="input-group-append">
          <vue-file-upload class="btn btn-primary" v-model="files" :headers="headers" :post-action="postAction" ref="upload" :timeout="6000">Select file</vue-file-upload>
        </div>
        <!-- <button v-show="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true" type="button">Start upload</button> -->
        <!-- <table>
          <tbody>
            <tr v-for="(file, index) in files">
                  <td>{{index}}</td>
                  <td>{{file.id}}</td>
                  <td v-if="file.type.substr(0, 6) == 'image/' && file.blob">
                    <img :src="file.blob" width="50" height="auto" />
                  </td>
                  <td v-else></td>
                  <td>{{file.name}}</td>
                  <td>{{file.size}}</td>
                  <td>{{file.progress}} %</td>
                  <td>{{file.speed}}</td>
                  <td>{{file.active}}</td>
                  <td>{{file.error}}</td>
                  <td>{{file.success}}</td>
                  <td><button type="button" @click.prevent="abort(file)">Abort</button></td>
                  <td><button type="button" @click.prevent="customError(file)">custom error</button></td>
                  <td><button type="button" @click.prevent="remove(file)">x</button></td>
              </tr>
          </tbody>
        </table> -->
      </div>
    </div>
    <div v-if="files.length" class="form-group col-md-2 text-right columns">
      <button type="button" class="btn btn-primary upload" @click="onUploadClick">Upload</button>
    </div>
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import Promise from 'bluebird'
import merge from 'merge'
import _ from 'lodash'
export default {
  name: 'file-upload',
  props: ['multiple', 'headers', 'name', 'value', 'imagepreview', 'postAction'],
  components: {
    'vue-file-upload': FileUpload
  },
  data () {
    return {
      files: [],
      filename: ''
      // inputId: this.name + '-file'
    }
  },
  watch: {
    files (files, oldFiles) {
      if (this.multiple) {
        if (files.length) {
          this.filename = files[0].name
        }
      } else {
      }
    }
  },
  methods: {
    upload () {
      return new Promise((resolve, reject) => {
        var self = this
        self.$refs.upload.active = true

        // console.log('upload heads', this.headers)

        function check () {
          if (!self.$refs.upload.active) {
            var failFiles = []
            var succFiles = []
            self.files.forEach((file) => {
              if (file.error) {
                failFiles.push(file)
              } else if (file.progress >= 100) {
                succFiles.push(file)
              }
            })

            // console.log('checking', failFiles, succFiles)
            if (failFiles.length) {
              let errorMessage = _.get(failFiles[0], 'response.message')
              return reject(new Error(errorMessage ? `Cannot upload file, error: ${errorMessage}` : 'Cannot upload file'))
            }
            if (succFiles.length || !self.files.length) {
              let uploadResult = self._createUploadResultFromFiles(succFiles)
              return resolve(uploadResult)
            }
          }
          setTimeout(check, 500)
        }

        setTimeout(check, 500)
      })
    },
    clear () {
      this.$refs.upload.clear()
      this.filename = ''
    },
    updateValue (value) {
      var formattedFilename = value
      this.filename = formattedFilename
      this.$emit('input', value)
    },
    onUploadClick () {
      this.$emit('uploadclick')
    },
    _createUploadResultFromFiles (files) {
      console.log('fffffffffffffffffff', files);
      let result = files.map((file) => ({
        response: merge(true, file.response)
      }))
      return result
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // label.file-uploads {
  //   border-top-left-radius: 0;
  //   -moz-border-top-left-radius: 0;
  //   -webkit-border-top-left-radius: 0;

  //   border-bottom-left-radius: 0;
  //   -moz-border-bottom-left-radius: 0;
  //   -webkit-border-bottom-left-radius: 0;
  // }

  // .button.upload {
  //   width: 98%;
  //   padding-top: $input-padding * 1.9;
  //   padding-bottom: $input-padding * 1.9;
  // }
</style>
