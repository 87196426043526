<script>
import Vue from 'vue'
import $ from 'jquery'
import urllib from 'url'
import merge from 'merge'
import {rewriteUrl} from '../interceptors/ApiRewrite'

function handleResult (response) {
  // if (!response.body.success) {
  //   throw new Error(response.body.error)
  // }

  return response.body
}

export default {
  get (url, params) {
    return Vue.http.get('api://' + url, {
      params: params
    })
    .then(handleResult)
  },
  getData (url, params) {
    return Vue.http.get('api://' + url, {
      params: params
    })
    .then((response) => {
      return handleResult(response).data
    })
  },
  post (url, formData) {
    return Vue.http.post('api://' + url, formData)
    .then(handleResult)
  },
  put (url, formData) {
    return Vue.http.put('api://' + url, formData)
    .then(handleResult)
  },
  delete (url, formData) {
    return Vue.http.delete('api://' + url)
    .then(handleResult)
  },
  download (url, queryParams) {
    var container = $('#download-target-container')
    var parsedUrl

    if (!container.length) {
      container = $('<iframe id="download-target-container" style="display:none;"></iframe>').appendTo(document.body)
    }

    if (queryParams) {
      parsedUrl = urllib.parse(url)
      parsedUrl.query = merge(parsedUrl.query, queryParams)
      url = urllib.format(parsedUrl)
    }
    container.attr('src', rewriteUrl('api://' + url, true))
  }
}
</script>
