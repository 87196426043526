<template>
  <div id="wrapper">
    <!-- Sidebar -->
    <partials-sidebar></partials-sidebar>
    <!-- End of Sidebar -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Sidebar -->
        <partials-topbar></partials-topbar>
        <!-- End of Sidebar -->
        <!-- Begin Page Content -->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global  */

import PartialsSidebar from '../partials/Sidebar'
import PartialsTopbar from '../partials/Topbar'

export default {
  name: 'layout-secured',
  components: {
    PartialsSidebar,
    PartialsTopbar
  },
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
