var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-group col-md-6" },
        [_c("error-reveal", { attrs: { error: _vm.error } })],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-group col-md-12" },
        [
          _c("chart-time-series", {
            attrs: {
              title: "FB requests in the last 24 hours",
              subtitle: _vm.chartGenericSubtitle,
              height: 400,
              colors: _vm.chartData.colors,
              pointsInTime: _vm.chartData.pointsInTime,
              series: _vm.chartData.series,
              showLegend: true
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }