/* global */
'use strict'

const Joi = require('joi-browser')
import merge from 'merge'
import _ from 'lodash'
import $ from 'jquery'
import Vue from 'vue'
import ErrorText from './joi-form-error-text'

function callCb (cb, args) {
  if (cb) {
    cb(...args)
  }
}

export default Vue.extend({
  name: 'joi-form',
  template: '<form method="post" v-on:submit.prevent="submit"><slot></slot></form>',
  props: ['data', 'schema'],
  components: {
    'errot-text': ErrorText
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    submit (event) {
      this.validate()
    },
    validate (cb) {
      var options = {allowUnknown: true, abortEarly: false}
      this.clearInputErrors()
      Joi.validate(this.data, this.schema, options, (err, validatedData) => {
        if (err) {
          this.onValidationFailed(err, cb)
        } else {
          this.onValidationSuccess(validatedData, cb)
        }
      })
    },
    onValidationFailed (err, cb) {
      this.$emit('validationfailed', err)
      this.displayValidatoinErrors(err)

      callCb(cb, [err, null])
    },
    onValidationSuccess (validatedData, cb) {
      this.$emit('validationsuccess', validatedData)
      this.composeFormData(validatedData, cb)
    },
    composeFormData (validatedData, cb) {
      // var formData = new FormData()
      var formData = merge(true, validatedData)
      var self = this

      $('input[name],select[name],textarea[name]', this.$el).each(function () {
        var name = $(this).attr('name')
        var value = self.getFormDataForInput(this, validatedData[name])
        if (value && value.filename) {
          // formData.append(name, value.value, value.filename)
        } else {
          if (self.isValidValue(value)) {
            // formData.append(name, value)
            _.set(formData, name, value)
          }
        }
      })

      this.extendFormDataWithPropData(formData)

      this.$emit('formdataready', formData)

      callCb(cb, [null, formData])
    },
    extendFormDataWithPropData (formData) {
      _.forOwn(merge(true, this.data), (value, key) => {
        if (!formData.hasOwnProperty(key) && this.isValidValue(value)) {
          formData[key] = value
          // if (_.isArray(value)) {
          //   _.forEach(value, (item) => {
          //     formData.append(key + '[]', item)
          //   })
          // } else {
          //   formData.append(key, value)
          // }
        }
      })
    },
    isValidValue (value) {
      return !_.isUndefined(value) && !_.isNull(value)
    },
    getFormDataForInput (inputEl, validatedValue) {
      var el = $(inputEl)
      if (el.attr('type') === 'file') {
        if (!inputEl.files.length) {
          return null
        }
        return {
          value: inputEl.files[0],
          filename: validatedValue || el.val()
        }
      }

      return validatedValue === undefined ? el.val() : validatedValue
    },
    displayValidatoinErrors (error) {
      if (error.isJoi) {
        return this.displayJoiValidationErrors(error)
      }
    },
    displayJoiValidationErrors (validationError) {
      this.clearInputErrors()
      for (let inputError of validationError.details) {
        this.displayInputError(inputError)
      }
    },
    displayInputError (inputError) {
      var component = this.findErrorComponentByPath(inputError.path)

      if (!component) {
        component = this.createErrorComponentForInputError(inputError)
      }

      if (component) {
        component.message = inputError.message
        component.show()
      } else {
        console.log('Can not display error message', inputError)
      }
    },
    clearInputErrors () {
      this.queryErrorComponentsFor('input').forEach((component) => {
        component.message = null
        component.hide()
      })
    },
    createErrorComponentForInputError (inputError) {
      var inputEl = this.findInputElementByPath(inputError.path)
      var errEl = null
      var errElTarget = null

      if (inputEl.length) {
        errElTarget = $('<error-text></error-text>')
        inputEl.after(errElTarget)

        errEl = new ErrorText()
        errEl.for = 'input'
        errEl.$mount(errElTarget.get()[0])
        this.$children.push(errEl)
      }
      return errEl
    },
    queryErrorComponentsFor (filterFor) {
      return this.$children.filter((component) => {
        return component.$options.isFormError && (!filterFor || component.for === filterFor)
      })
    },
    findErrorComponentByPath (path) {
      return this.$children.find((component) => {
        return component.$options.isFormError && component.path === path
      })
    },
    findInputElementByPath (path) {
      var selectors = [
        '[error-path="' + path + '"]',
        'input[name="' + path + '"]',
        'textarea[name="' + path + '"]',
        'select[name="' + path + '"]'
      ]
      var result

      for (var i = 0; i < selectors.length; i++) {
        result = $(selectors[i], this.$el).first()
        if (result.length) {
          return result
        }
      }

      return []
    }
  }
})
