<script>

import merge from 'merge'
import Flatpickr from 'flatpickr'
require('flatpickr/dist/flatpickr.css')

export default {
  name: 'date-picker',
  render (el) {
    return el('input', {
      attrs: {
        'type': 'text',
        'data-input': true
      },
      props: {
        disabled: this.disabled
      },
      on: {
        // input: this.onInput
      }
    })
  },
  props: {
    name: {
      type: String
    },
    value: {
      type: [Date, Object, String]
    },
    config: {
      type: Object,
      default: () => ({
        wrap: false,
        defaultDate: null
      })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fromField: {
      type: String,
      default: 'from'
    },
    toField: {
      type: String,
      default: 'to'
    }
  },
  data () {
    return {
      fp: null
    }
  },
  mounted () {
    if (this.fp) {
      return
    }

    let safeConfig = merge.recursive(this.config)

    safeConfig.defaultDate = this.pickerValue(this.value) || safeConfig.defaultDate
    safeConfig.onChange = (selectedDates) => {
      this.updateValue(selectedDates)
    }

    this.fp = new Flatpickr(this.getElem(), safeConfig)
    this.$watch('disabled', this.watchDisabled, {immediate: true})
  },
  // watch: {
  //   value (selectedDates) {
  //     this.selectDates(selectedDates)
  //   }
  // },
  methods: {
    getElem () {
      return this.config.wrap ? this.$el.parentNode : this.$el
    },
    beforeDestroy () {
      if (this.fp) {
        // this.fpInput().removeEventListener('blur', this.onBlur);
        this.fp.destroy()
        this.fp = null
      }
    },
    updateValue (selectedDates) {
      let value

      switch (this.config.mode) {
        case 'single':
          value = this.getSingleValue(selectedDates)
          break
        case 'range':
          value = this.getRangeValue(selectedDates)
          break
      }

      if (value) {
        this.$emit('input', value)
      }
    },
    getSingleValue (selectedDate) {
      return selectedDate[0]
    },
    getRangeValue (selectedDates) {
      if (selectedDates && selectedDates.length === 2) {
        return {[this.fromField]: selectedDates[0], [this.toField]: selectedDates[1]}
      }
      return null
    },
    pickerValue (value) {
      switch (this.config.mode) {
        case 'single':
          return value
          break
        case 'range':
          return _.isObject(value) ? [value[this.fromField], value[this.toField]] : null
          break
      }
    },
    watchDisabled(newState) {
      if (newState) {
        this.fpInput().setAttribute('disabled', newState);
      } else {
        this.fpInput().removeAttribute('disabled');
      }
    },
    fpInput() {
      return this.fp.altInput || this.fp.input
    }
  }
//  value(newValue) {
//       // Prevent updates if v-model value is same as input's current value
//       if (newValue === this.$el.value) return;
//       // Make sure we have a flatpickr instance
//       this.fp &&
//       // Notify flatpickr instance that there is a change in value
//       this.fp.setDate(newValue, true);
// },
  //   selectDates (selectedDates) {
  //     if (this.picker) {
  //       switch (this.mode) {
  //         case 'single':
  //           this.picker.setDate(selectedDates, false)
  //           break
  //         case 'range':
  //           if (selectedDates[this.fromField] && selectedDates[this.toField]) {
  //             this.picker.setDate([selectedDates[this.fromField], selectedDates[this.toField]], false)
  //           }
  //           break
  //         default:
  //           console.log('Invalid mode for date picker: ' + this.mode)
  //           break
  //       }
  //     }
  //   }
}

</script>

<style lang="scss">
  .flatpickr-day.disabled {
    opacity: 0.5;
    color: #dc3545;
  }
</style>

