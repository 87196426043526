var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "joi-form",
    {
      attrs: { data: _vm.data, schema: _vm.validationSchema },
      on: { formdataready: _vm.save }
    },
    [
      _c("div", { staticClass: "card shadow mb-4" }, [
        _c("div", { staticClass: "card-header py-3" }, [
          _c(
            "a",
            {
              staticClass: "float-right",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.back()
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-fw fa-arrow-circle-left" }),
              _vm._v(" Back")
            ]
          ),
          _vm._v(" "),
          _c("h6", { staticClass: "m-0 font-weight-bold text-primary" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [_c("error-reveal", { attrs: { error: _vm.error } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Name:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.name,
                    expression: "data.name"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "name" },
                domProps: { value: _vm.data.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "name", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Contact name:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.contactName,
                    expression: "data.contactName"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "contactName" },
                domProps: { value: _vm.data.contactName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "contactName", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Contact phone:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.contactPhone,
                    expression: "data.contactPhone"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "contactPhone" },
                domProps: { value: _vm.data.contactPhone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "contactPhone", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer text-muted" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary trans-uppercase",
              attrs: { type: "submit" }
            },
            [_vm._v("Save")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }