<template>
  <div class="alert alert-danger alert-dismissible fade d-none" role="error-information">
    {{message}}
    <button type="button" class="close" @click="hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'error-callout',
  props: ['error'],
  data () {
    return {
    }
  },
  computed: {
    message () {
      return this.error ? this.error.message : ''
    }
  },
  watch: {
    error: 'onErrorChanged'
  },
  methods: {
    onErrorChanged (error) {
      if (error) {
        this.show()
      } else {
        this.hide()
      }
    },
    show: function () {
      $(this.$el).removeClass('d-none')
      $(this.$el).addClass('show')
    },
    hide: function () {
      $(this.$el).removeClass('show')
      $(this.$el).addClass('d-none')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '../../styles/main';
</style>
