import $ from 'jquery'
import Promise from 'bluebird'

function getTpl (title, message) {
  return `
    <div class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${title}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ${message}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal" data-action="yes">Yes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" data-action="no">No</button>
          </div>
        </div>
      </div>
    </div>
  `
}

export default {
  methods: {
    confirm (title, message) {
      return new Promise((resolve, reject) => {
        let modal = $(getTpl(title, message)).modal()
        $('button[data-action="yes"]', modal).on('click', () => {
          resolve(true)
        })
        $('button[data-action="no"]', modal).on('click', () => {
          resolve(false)
        })
      })
    }
  }
}
