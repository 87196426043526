var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("error-reveal", { attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("div", { staticClass: "card shadow mb-4" }, [
        _vm.client
          ? _c(
              "div",
              { staticClass: "card-header py-3" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "float-right",
                    attrs: {
                      to: {
                        name: "partner-client-list",
                        params: { partnerId: _vm.client.partner.id }
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-fw fa-arrow-circle-left" }),
                    _vm._v(" Back")
                  ]
                ),
                _vm._v(" "),
                _c(
                  "h6",
                  { staticClass: "m-0 mt-1 font-weight-bold text-primary" },
                  [
                    _vm._v(_vm._s(_vm.client.partner.name) + " "),
                    _c("i", { staticClass: "fas fa-fw fa-angle-double-right" }),
                    _vm._v(" " + _vm._s(_vm.client.name) + " "),
                    _c("i", { staticClass: "fas fa-fw fa-angle-double-right" }),
                    _vm._v(" Adsets")
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("card-list", {
              attrs: {
                items: _vm.items,
                emptytext: "No adsets.",
                additemtext: "Create adset",
                refreshtext: "Refresh"
              },
              on: { additem: _vm.onAddNewItem, refresh: _vm.onGridRefresh },
              scopedSlots: _vm._u([
                {
                  key: "card",
                  fn: function(slotProps) {
                    return [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c(
                              "span",
                              {
                                staticClass: "card-title clickable h5",
                                on: {
                                  click: function($event) {
                                    return _vm.onItemAction(
                                      "edit",
                                      slotProps.item
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(slotProps.item.name))]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6 text-right" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-xs",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.onItemAction(
                                      "edit",
                                      slotProps.item
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _c("i", { staticClass: "fas fa-sm fa-edit" })
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-xs",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.onItemAction(
                                      "view-analytics",
                                      slotProps.item
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", [
                                  _c("i", {
                                    staticClass: "fas fa-sm fa-chart-bar"
                                  })
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-2" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("p", { staticClass: "small text-muted mb-1" }, [
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(slotProps.item.adsStats)
                                }
                              })
                            ])
                          ])
                        ])
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("data-store", {
        ref: "store",
        attrs: { service: _vm.service, filter: _vm.filter, sort: _vm.sort },
        on: {
          beforeload: _vm.onBeforeLoad,
          afterload: _vm.onAfterLoad,
          dataloaded: _vm.onDataLoaded,
          loaderror: _vm.onLoadError
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }