<template>
  <div class="mt-2">

    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Placements:</label>
        <select :value="value.placementType" @input="emitValue('placementType', ...arguments)" class="form-control">
          <option value="">-</option>
          <option v-for="item of placementTypes" :value="item.name" v-bind:key="item.name">{{item.title}}</option>
        </select>
        <span error-path="adManagementPolicy.placementType"></span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Ad publishing method:</label>
        <select :value="value.method" @input="emitValue('method', ...arguments)" class="form-control">
          <option value="">-</option>
          <option v-for="item of methods" :value="item.name" v-bind:key="item.name">{{item.title}}</option>
        </select>
        <span error-path="adManagementPolicy.method"></span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label error-path="adManagementPolicy.savedAudienceId">Targeting by saved audience:</label>
          <select :value="value.savedAudienceId" @input="emitValue('savedAudienceId', ...arguments)" class="form-control">
            <option value="">-</option>
            <option v-for="item of savedAudiences" :value="item.id" v-bind:key="item.id">{{item.name}}</option>
          </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label error-path="adManagementPolicy.adsPixelId">Tracking by ads pixel:</label>
          <select :value="value.adsPixelId" @input="emitValue('adsPixelId', ...arguments)" class="form-control">
            <option value="">-</option>
            <option v-for="item of adsPixels" :value="item.id" v-bind:key="item.id">{{item.name}}</option>
          </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label error-path="adManagementPolicy.urlTags">URL Tags:</label>
        <input type="text" :value="value.urlTags" @input="emitValue('urlTags', ...arguments)" class="form-control">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label error-path="adManagementPolicy.promotedUrl">Promoted URL:</label>
        <input type="text" :value="value.promotedUrl" @input="emitValue('promotedUrl', ...arguments)" class="form-control">
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Action cost metric:</label>
        <select v-model="value.actionCostMetric" class="form-control">
          <option value="">-</option>
          <option v-for="item of actionCostMetricList" :value="item.value" v-bind:key="item.value">{{item.name}}<span v-if="item.custom_event_type"> ({{item.custom_event_type}})</span></option>
        </select>
        <span error-path="adManagementPolicy.actionCostMetric"></span>
      </div>
      <div class="form-group col-md-12">
        <label>Result metric:</label>
        <select v-model="value.resultMetric" class="form-control">
          <option value="">-</option>
          <option v-for="item of resultMetricList" :value="item.value" v-bind:key="item.value">{{item.name}}<span v-if="item.custom_event_type"> ({{item.custom_event_type}})</span></option>
        </select>
        <span error-path="adManagementPolicy.resultMetric"></span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-4">
        <label>
          Free run threshold:
        </label>
        <div class="input-group">
          <input class="form-control" type="number" min="0" step="1" :value="value.freerunThreshold" @input="emitValue('freerunThreshold', ...arguments)">
          <div class="input-group-append">
            <span class="input-group-text">{{currency}}</span>
          </div>
        </div>
        <span error-path="adManagementPolicy.freerunThreshold"></span>
      </div>
      <div class="form-group col-md-4">
        <label>Max avg. action cost:</label>
        <div class="input-group">
          <input class="form-control" type="number" min="0" step="0.00001" :value="value.maxAvgActionCost" @input="emitValue('maxAvgActionCost', ...arguments)" style="width: 100px">
          <div class="input-group-append">
            <span class="input-group-text">{{currency}}</span>
          </div>
        </div>
        <span error-path="adManagementPolicy.maxAvgActionCost"></span>
      </div>
      <div class="form-group col-md-4">
        <label>Auto resume threshold:</label>
        <div class="input-group">
          <input class="form-control" type="number" min="0" step="0.00001" :value="value.resumeAvgActionCost" @input="emitValue('resumeAvgActionCost', ...arguments)">
          <div class="input-group-append">
            <span class="input-group-text">{{currency}}</span>
          </div>
        </div>
        <span error-path="adManagementPolicy.resumeAvgActionCost"></span>

        <!-- <label>Auto stop threshold:</label>
        <div class="input-group">
          <input class="form-control" type="number" min="0" step="1" :value="value.autoStopThreshold" @input="emitValue('autoStopThreshold', ...arguments)">
          <div class="input-group-append">
            <span class="input-group-text">%</span>
          </div>
        </div>
        <span error-path="adManagementPolicy.autoStopThreshold"></span> -->
      </div>
    </div>



    <div class="form-row">
      <div class="form-group col-md-4">
        <label>Ad max. lifetime:</label>
        <div class="input-group">
          <input class="form-control" type="number" min="1" step="1" :value="value.adMaxLifetimeInHour" @input="emitValue('adMaxLifetimeInHour', ...arguments)">
          <div class="input-group-append">
            <span class="input-group-text">hour(s)</span>
          </div>
        </div>
        <span error-path="adManagementPolicy.adMaxLifetimeInHour"></span>
      </div>

      <div class="form-group col-md-4">
        <label>No. of max. running ad:</label>
        <input class="form-control" type="number" min="0" step="1" name="numberOfMaxRunningAd" :value="value.numberOfMaxRunningAd" @input="emitValue('numberOfMaxRunningAd', ...arguments)">
        <span error-path="adManagementPolicy.numberOfMaxRunningAd"></span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Maximum no. of ad per adset:</label>
        <input class="form-control w-25" type="number" min="0" step="1" name="numberOfMaxAdPerAdset" :value="value.numberOfMaxAdPerAdset" @input="emitValue('numberOfMaxAdPerAdset', ...arguments)">
        <span error-path="adManagementPolicy.numberOfMaxAdPerAdset"></span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Stop ads if "Unique inline link clicks" minimum:</label>
        <input class="form-control w-25" type="number" min="1" step="1" name="maxUniqueInlineLinkClicks" :value="value.maxUniqueInlineLinkClicks" @input="emitValue('maxUniqueInlineLinkClicks', ...arguments)">
        <span error-path="adManagementPolicy.maxUniqueInlineLinkClicks"></span>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-12">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input" id="switchNewAdsEnabled" :checked="value.newAdsEnabled" @input="emitValue('newAdsEnabled', ...arguments)">
          <label class="custom-control-label" for="switchNewAdsEnabled">Creating new ads enabled</label>
          <span error-path="adManagementPolicy.newAdsEnabled"></span>
        </div>
        <div class="custom-control custom-switch mt-1">
          <input type="checkbox" class="custom-control-input" id="switchAutoStopAdsEnabled" :checked="value.autoStopAdsEnabled" @input="emitValue('autoStopAdsEnabled', ...arguments)">
          <label class="custom-control-label" for="switchAutoStopAdsEnabled">Auto stop ads enabled</label>
          <span error-path="adManagementPolicy.autoStopAdsEnabled"></span>
        </div>
        <div class="custom-control custom-switch mt-1">
          <input type="checkbox" class="custom-control-input" id="switchAutoResumeAdsEnabled" :checked="value.autoResumeAdsEnabled" @input="emitValue('autoResumeAdsEnabled', ...arguments)">
          <label class="custom-control-label" for="switchAutoResumeAdsEnabled">Auto resume ads enabled</label>
          <span error-path="adManagementPolicy.autoResumeAdsEnabled"></span>
        </div>

        <div class="custom-control custom-switch mt-1">
          <input type="checkbox" class="custom-control-input" id="switchNoTrackingSpecs" :checked="value.noTrackingSpecs" @input="emitValue('noTrackingSpecs', ...arguments)">
          <label class="custom-control-label" for="switchNoTrackingSpecs">Do not add tracking specs to ads</label>
          <span error-path="adManagementPolicy.noTrackingSpecs"></span>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import ValuePercent from '../../inputs/value-percent'

function getEmptyValue () {
  return {
    placementType: null,
    savedAudienceId: null,
    adsPixelId: null,
    urlTags: null,
    promotedUrl: null,
    adMaxLifetimeInHour: null,
    adOpeningBudget: null,
    maxAvgActionCost: null,
    resumeAvgActionCost: null,
    freerunThreshold: null,
    // autoStopThreshold: null,
    numberOfMaxRunningAd: null,
    numberOfMaxAdPerAdset: null,
    maxUniqueInlineLinkClicks: null,
    newAdsEnabled: false,
    autoStopAdsEnabled: false,
    autoResumeAdsEnabled: false,
    noTrackingSpecs: false,
    actionCostMetric: null,
    resultMetric: null
  }
}

export default {
  name: 'ad-management-policy',
  components: {
    ValuePercent
  },
  props: {
    value: {
      type: Object,
      default: getEmptyValue
    },
    savedAudiences: {
      type: Array,
      required: true
    },
    adsPixels: {
      type: Array,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    errorPathPrefix: {
      type: String
    },
    features: {
      type: Array
    },
    marketingGoal: {
      type: String
    },
    actionCostMetricList: {
      type: Array
    },
    resultMetricList: {
      type: Array
    }
  },
  data () {
    return {
      methods: [{
        name: 'boost',
        title: 'Boost existing ads'
      }, {
        name: 'place',
        title: 'Place new ads'
      }],
      placementTypes: [{
        name: 'predefined',
        title: 'Predefined placements'
      }, {
        name: 'automatic',
        title: 'Automatic placements'
      }]
    }
  },
  watch: {
    currency: 'onCurrencyChanged'
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onCurrencyChanged (newCurrency) {
      this.emitValue('currency', newCurrency)
    },
    emitValue (propertyName, propertyValue) {
      if (propertyValue.target) {
        if ($(propertyValue.target).is(':checkbox')) {
          propertyValue = $(propertyValue.target).prop('checked')
        } else {
          propertyValue = propertyValue.target.value
        }
      }
      let newValue = _.pick(this.value, [
        'method', 'placementType', 'savedAudienceId', 'adsPixelId', 'urlTags', 'promotedUrl', 'adMaxLifetimeInHour',
        'maxAvgActionCost', 'freerunThreshold', 'resumeAvgActionCost', 'numberOfMaxRunningAd', 'numberOfMaxAdPerAdset',
        'maxUniqueInlineLinkClicks', 'newAdsEnabled', 'autoStopAdsEnabled', 'autoResumeAdsEnabled', 'noTrackingSpecs',
        'actionCostMetric', 'resultMetric'
      ])
      newValue[propertyName] = propertyValue
      newValue.currency = this.currency
      this.$emit('input', newValue)
    },
    hasFeature (featureName) {
      return _.isArray(this.features) && this.features.indexOf(featureName) !== -1
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .paused {
    opacity: 0.5;
  }
</style>
