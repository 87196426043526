<template>
  <div :id="domUid" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Information</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div v-html="safeMessage"></div>
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import _ from 'lodash'
import SafeString from '../utils/SafeString'

export default {
  name: 'info-reveal',
  props: ['message'],
  data () {
    return {
    }
  },
  computed: {
    domUid () {
      return 'info-reveal-' + this._uid
    },
    safeMessage () {
      let value = this.message
      return value instanceof SafeString ? value.toString() : _.escape(value)
    }
  },
  watch: {
    message: 'onMessageChanged'
  },
  mounted: function () {
  },
  methods: {
    onMessageChanged (message) {
      if (message) {
        this.show()
      } else {
        this.hide()
      }
    },
    show () {
      if (this.modalElement) {
        this.modalElement.modal('show')
      } else {
        this.modalElement = $(this.$el).modal({
          focus: true,
          show: true
        })
      }
    },
    hide () {
      if (this.modalElement) {
        this.modalElement.modal('hide')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
