<template>
  <div class="card" @click="onClick">
      <header>
        <div class="image" v-if="data.headerImage">
          <img :src="data.headerImage" alt="" />
        </div>
        <div class="text" v-bind:class="{'with-image': data.headerImage}">
          <p class="title">{{data.title}}</p>
          <p class="description">{{data.description}}</p>
        </div>
      </header>
      <div class="body" v-if="data.image">
        <img :src="data.image" alt="" @click="onImageClick">
      </div>
      <footer>
        <button
          v-for="action of actions"
          class="button"
          :class="action.class"
          @click="onAction(action)"
        >{{action.text}}</button>
      </footer>
  </div>
</template>

<script>
export default {
  name: 'card-item',
  props: ['data', 'actions'],
  data () {
    return {
    }
  },
  methods: {
    onClick () {
      this.$emit('click', this.data)
    },
    onImageClick () {
      this.$emit('imageclick', this.data)
    },
    onAction (action) {
      this.$emit('action', action, this.data)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
