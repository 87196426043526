<template>
  <input type="text" class="typeahead form-control" />
</template>

<script>

import Bloodhound from 'typeahead.js'
import 'vendors/bootstrap4-tagsinput/tagsinput.css'
import 'vendors/bootstrap4-tagsinput/tagsinput.js'

export default {
  name: 'page-input',
  props: {
    name: String,
    value: Array,
    pages: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      items: []
    }
  },
  watch: {
    pages (newPages) {
      this.recreateInput()
    },
    value (items) {
      items = _.isArrayLike(items) ? items : []
      this.setTags(items)
    }
  },
  mounted () {
    this.recreateInput()
  },
  methods: {
    recreateInput () {
      if (this.tagsinput) {
        $(this.$el).tagsinput('destroy')
        this.tagsinput = null
      }
      this.idsInTokens = []

      var pagesSource = new Bloodhound({
        local: this.pages.slice(),
        identify: function(obj) { return obj.id },
        datumTokenizer: function (datum) {
          return Bloodhound.tokenizers.whitespace(datum.name)
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace
      });

      this.tagsinput = $(this.$el).tagsinput({
        itemValue: 'id',
        itemText: 'name',
        freeInput: false,
        onTagExists: function () {},
        typeaheadjs: [{
          hint: true,
          highlight: true,
          minLength: 1,
          display: 'name'
        }, {
          name: 'pages',
          display: 'name',
          source: pagesSource
        }]
      })

      $(this.$el).on('itemAdded', (event) => {
        this.addItem(event.item)
      })

      $(this.$el).on('itemRemoved', (event) => {
        this.deleteItem(event.item)
      })

      if (_.isArrayLike(this.value)) {
        this.setTags(this.value)
      }
    },

    addItem (item) {
      if (this._isRegisteredItemId(item.id)) {
        return
      }

      this._registerItemId(item.id)
      this.items.push(this._pickItemFields(item))
      this.updateValue()
    },

    deleteItem (item) {
      var idx = this.items.findIndex((e) => e.id === item.id)
      this._unregisterItemId(item.id)
      if (idx !== -1) {
        this.items.splice(idx, 1)
        this.updateValue()
      }
    },

    _pickItemFields (item) {
      return {
        id: item.id,
        name: item.name
      }
    },

    _isRegisteredItemId (itemId) {
      return this.idsInTokens.indexOf(itemId) !== -1
    },

    _registerItemId (itemId) {
      this.idsInTokens.push(itemId)
    },

    _unregisterItemId (itemId) {
      var idx = this.idsInTokens.indexOf(itemId)
      if (idx !== -1) {
        this.idsInTokens.splice(idx, 1)
      }
    },

    setTags (items) {
      items.forEach((item) => {
        if (!this._isRegisteredItemId(item.id)) {
          this._registerItemId(item.id)
          this.items.push(this._pickItemFields(item))
          $(this.$el).tagsinput('add', this._pickItemFields(item))
        }
      })
    },

    updateValue () {
      this.$emit('input', this.items)
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '../../styles/main';

  .bootstrap-tagsinput {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  span.twitter-typeahead {
    width: 100%;
  }

  .tt-input {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .tt-menu {
    @extend .list-group;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    width: 100%;
  }

  .tt-suggestion {
    @extend .list-group-item;
  }

  .tt-selectable {
    @extend .list-group-item-action;
    cursor: pointer;
  }
</style>
