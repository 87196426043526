var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass:
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion",
      attrs: { id: "accordionSidebar" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider my-0" }),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider" }),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar-heading" }, [
        _vm._v("\n     Collections\n   ")
      ]),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          class: { active: _vm.isActive("/partners/?") }
        },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: { name: "partners" } } },
            [
              _c("i", { staticClass: "fas fa-fw fa-user-circle" }),
              _vm._v(" "),
              _c("span", [_vm._v("Partners")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          class: { active: _vm.isActive("/insights/?") }
        },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: { name: "insights" } } },
            [
              _c("i", { staticClass: "fas fa-fw fa-chart-line" }),
              _vm._v(" "),
              _c("span", [_vm._v("Insights")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          class: { active: _vm.isActive("/users/?") }
        },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: { name: "users" } } },
            [
              _c("i", { staticClass: "fas fa-fw fa-users" }),
              _vm._v(" "),
              _c("span", [_vm._v("Users")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider" }),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar-heading" }, [
        _vm._v("\n     Admin\n   ")
      ]),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          class: { active: _vm.isActive("/system-analytics/?") }
        },
        [
          _c(
            "router-link",
            {
              staticClass: "nav-link",
              attrs: { to: { name: "system-analytics" } }
            },
            [
              _c("i", { staticClass: "fas fa-fw fa-chart-bar" }),
              _vm._v(" "),
              _c("span", [_vm._v("System analytics")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          class: { active: _vm.isActive("/settings/?") }
        },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: { name: "settings" } } },
            [
              _c("i", { staticClass: "fas fa-fw fa-cog" }),
              _vm._v(" "),
              _c("span", [_vm._v("Settings")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          class: { active: _vm.isActive("/tools/?") }
        },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: { name: "tools" } } },
            [
              _c("i", { staticClass: "fas fa-fw fa-tools" }),
              _vm._v(" "),
              _c("span", [_vm._v("Tools")])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider d-none d-md-block" }),
      _vm._v(" "),
      _c("div", { staticClass: "text-center d-none d-md-inline" }, [
        _c("button", {
          staticClass: "rounded-circle border-0",
          attrs: { id: "sidebarToggle" },
          on: {
            click: function($event) {
              return _vm.toggleSidebar()
            }
          }
        })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "sidebar-brand d-flex align-items-center justify-content-center",
        attrs: { href: "index.html" }
      },
      [
        _c("img", { attrs: { src: require("../../assets/logo-small.jpg") } }),
        _vm._v(" "),
        _c("div", { staticClass: "sidebar-brand-text mx-3" }, [
          _vm._v("AdFlow")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }