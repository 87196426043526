<script>
import api from './api'
import fbService from './fb'

export default {
  loginWithFacebook () {
    return fbService.login().then((identity) => {
      return api.post('auth/connected', {provider: 'facebook', identity: identity})
    })
  },
  loginWithEmailAndPassword (data) {
    return api.post('auth/login', data)
  },
  logout () {
  }
}
</script>
