<script>
import api from './api'
export default {
  loadsettings () {
    return api.getData('insights/loadsettings')
  },
  saveSettings (formData) {
    return api.post('insights/savesettings', formData)
  },
  queryAds (params) {
    return api.get('insights/ads', params)
  },
  queryAdInsights (params) {
    return api.get('insights/adinsights', params)
  },
  querySpendInsights (params) {
    return api.get('insights/spends', params)
  },
  downloadData (params) {
    return api.download('insights/download', params)
  },
  loadPages (ids) {
    return api.get('insights/pages', {ids: ids})
  }
}
</script>
