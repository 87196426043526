<script>
import api from './api'
export default {
  load (id) {
    return api.get(`adsetprotos/${id}`)
  },
  save (id, formData) {
    if (!id) {
      return api.post('adsetprotos', formData)
    }
    return api.put('adsetprotos/' + id, formData)
  },
  remove (id) {
    return api.delete(`adsetprotos/${id}`)
  },
  list (params) {
    return api.get('adsetprotos', params)
  },
  refresh (id) {
    return api.get(`adsetprotos/${id}/refresh`)
  },
  download (id) {
    return api.download(`adsetprotos/${id}/download`)
  },
  loadAdPerformance (id) {
    return api.get(`adsetprotos/${id}/adperformance`)
  },
  addBufferItem (id, formData) {
    return api.post(`adsetprotos/${id}/buffer/item`, formData)
  },
  loadSources (adsetProtoId, params) {
    return api.get(`adsetprotos/${adsetProtoId}/sources`, params)
  },
  saveSource (adsetProtoId, sourceId, formData) {
    if (!sourceId) {
      return api.post(`adsetprotos/${adsetProtoId}/sources`, formData)
    }
    return api.put(`adsetprotos/${adsetProtoId}/sources/${sourceId}`, formData)
  },
  removeSource (adsetProtoId, sourceId) {
    return api.delete(`adsetprotos/${adsetProtoId}/sources/${sourceId}`)
  },
  loadBufferItems (adsetProtoId, params) {
    return api.get(`adsetprotos/${adsetProtoId}/buffer/items`, params)
  }
}
</script>
