module.exports = exports = function (Joi) {
  return {
    base: Joi.string(),
    name: 'string',
    language: {
      phone: '!!A telefonszám csak számjegyeket és plusz jelet tartalmazhat'
    },
    rules: [
      {
        name: 'phone',
        setup (params) {
          this._flags.phone = true // Set a flag for later use
        },
        validate (params, value, state, options) {
          var formatRe = [
            {test: /^\+?361([0-9]{3})([0-9]{4})$/i, replace: '+361 $1 $2'},
            {test: /^\+?36([0-9]{2})([0-9]{3})([0-9]{4})$/i, replace: '+36$1 $2 $3'},
            {test: /^\+?36([0-9]{2})([0-9]{3})([0-9]{3})$/i, replace: '+36$1 $2 $3'},
            {test: /^\+?([0-9]{4})([0-9]{3})([0-9]{3,6})$/i, replace: '+$1 $2 $3'}
          ]
          var found = false

          if (!value.match(/^\+?\d+$/) || value.length < 6) {
            return this.createError('string.phone', {
              v: value
            }, state, options)
          }

          found = formatRe.some((reParams) => {
            if (value.match(reParams.test)) {
              value = value.replace(reParams.test, reParams.replace)
              return true
            }
          })

          if (!found) {
            return this.createError('string.phone', {
              v: value
            }, state, options)
          }

          return value
        }
      }
    ]
  }
}
