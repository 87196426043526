
<template>
  <joi-form :data="data" :schema="validationSchema" v-on:formdataready="save">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <a @click.prevent="back()" href="#" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</a>
        <h6 class="m-0 font-weight-bold text-primary" v-if="partner">{{partner.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{title}}</h6>
      </div>

      <div class="card-body" :class="{'danger-border': isDangerBorderNeeded}">
        <div class="form-row">
          <div class="form-group col-md-6">
            <error-reveal :error="error"></error-reveal>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Name:</label>
            <input type="text" v-model="data.name" name="name" class="form-control">
          </div>
          <div class="form-group col-md-6">
            <label>Campaign management:</label>
            <div class="form-group">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="radioExternalCampaignManagementNo" name="externalCampaignManagement" v-model="data.externalCampaignManagement" :value="false" :disabled="data.id" class="custom-control-input">
                <label class="custom-control-label" for="radioExternalCampaignManagementNo">Internal</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="radioExternalCampaignManagementYes" name="externalCampaignManagement" v-model="data.externalCampaignManagement" :value="true" :disabled="data.id" class="custom-control-input">
                <label class="custom-control-label" for="radioExternalCampaignManagementYes">External</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Agent:</label>
            <select v-model="data.agent" class="form-control">
              <option value="">-</option>
              <option v-for="item of agents" :value="item.user.id" v-bind:key="item.id">{{item.name}}</option>
            </select>
            <span error-path="agent"></span>
          </div>
          <div class="form-group col-md-6">
          </div>
        </div>

        <div class="form-row" v-if="isExternalCampaignManagement">
          <div class="form-group col-md-6">
            <label>Facebook campaign ID:</label>
            <input type="text" v-model.lazy="data.externalCampaignId" name="externalCampaignId" class="form-control" :disabled="!loadedAgent || data.id">
            <span error-path="externalCampaignId"></span>
          </div>
          <div class="form-group col-md-6">
          </div>
        </div>

        <div class="form-row" v-if="isExternalCampaignManagement">
          <div class="form-group col-md-6">
            <label>Facebook campaign name:</label>
            <input type="text" :value="data.externalNameTpl" class="form-control" :disabled="true">
          </div>
          <div class="form-group col-md-6">
          </div>
        </div>

        <div class="form-row" v-if="isInternalCampaignManagement">
          <div class="form-group col-md-6">
            <label>Facebook campaign name:</label>
            <input type="text" v-model="data.externalNameTpl" name="externalNameTpl" class="form-control" :disabled="isExternalCampaignManagement">
            <small class="form-text text-info">Serial number of the campaign: %serial% </small>
            <span error-path="externalNameTpl"></span>
          </div>
          <div class="form-group col-md-6">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Ad account:</label>
            <select v-model="data.accountId" class="form-control" :disabled="!loadedAgent || data.id || isExternalCampaignManagement">
              <option value="">-</option>
              <option v-for="item of adAccounts" :value="item.account_id" v-bind:key="item.account_id">{{item.name}} ({{item.account_id}}; {{item.currency}}; {{accountStatusText(item.account_status)}})</option>
            </select>
            <span error-path="accountId"></span>
          </div>
          <div class="form-group col-md-6">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Marketing goal:</label>
            <select v-model="data.marketingGoal" :disabled="!data.accountId || data.id || isExternalCampaignManagement" class="form-control">
              <option value="">-</option>
              <option v-for="item of marketingGoals" :value="item.name" v-bind:key="item.name">{{item.title}}</option>
            </select>
            <span error-path="marketingGoal"></span>
          </div>
          <div class="form-group col-md-6">
          </div>
        </div>

        <div class="form-row" v-if="isInternalCampaignManagement">
          <div class="form-group col-md-6">
            <label>Period budget:</label>
            <div class="input-group">
              <input type="number" v-model="data.periodBudget" :disabled="!data.accountId" name="periodBudget" class="form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{data.currency}}</span>
              </div>
            </div>
            <span error-path="periodBudget"></span>
          </div>
          <div class="form-group col-md-6">
            <label>Daily budget:</label>
            <div class="input-group">
              <input type="number" v-model="data.dailyBudget" :disabled="!data.accountId" name="dailyBudget" class="form-control">
              <div class="input-group-append">
                <span class="input-group-text">{{data.currency}}</span>
              </div>
            </div>
            <span error-path="dailyBudget"></span>
          </div>
        </div>

        <div class="form-row" v-if="isExternalCampaignManagement">
          <div class="form-group col-md-12">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="switchCampaignTracking" v-model="data.trackCampaign">
              <label class="custom-control-label" for="switchCampaignTracking">Track external campaign</label>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-12">
            <client-schedule v-if="isInternalCampaignManagement" v-model="data.scheduling" errorPathPrefix="scheduling."></client-schedule>
            <span error-path="scheduling"></span>
            <small class="form-text text-info" v-if="data.timezone">Timezone: {{data.timezone}}</small>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="switchPolitical" v-model="data.isPolitical">
              <label class="custom-control-label" for="switchPolitical">Political ads</label>
              <span error-path="isPolitical"></span>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-12">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="switchNotifOnStoppingAds" v-model="data.notifOnStoppingAds">
              <label class="custom-control-label" for="switchNotifOnStoppingAds">Send notification on stopping ads</label>
              <span error-path="notifOnStoppingAds"></span>
            </div>
          </div>
        </div>

        <div class="form-row" v-if="data.notifOnStoppingAds">
          <div class="form-group col-md-6">
            <label>Recepients:</label>
            <div class="form-group">
              <textarea class="form-control" rows="6" v-model="data.notifOnStoppingAdsRecepients"></textarea>
              <small class="form-text text-muted">One mail address per line</small>
              <span error-path="notifOnStoppingAdsRecepients"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-muted">
        <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
      </div>
    </div>
  </joi-form>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import Promise from 'bluebird'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import JoiForm from '../../inputs/joi-form'
import ErrorReveal from '../../errors/Reveal'
import validationSchemas from '../../validators/schemas'
import fbService from '../../services/fb'
import clientService from '../../services/client'
import partnerService from '../../services/partner'
import userService from '../../services/user'
import PartnerRequired from '../../mixins/PartnerRequired'
import ClientSchedule from './Schedule'
import Debug from 'debug'
const debug = Debug('app:client:edit')

const marketingGoals = []

_.forOwn(CONSTANTS.MARKETING_GOALS, (title, name) => {
  marketingGoals.push({title, name})
})

export default {
  name: 'clients-edit',
  mixins: [PartnerRequired],
  components: {
    JoiForm,
    ErrorReveal,
    ClientSchedule
  },
  data () {
    return {
      data: {
        id: null,
        name: null,
        externalCampaignManagement: false,
        externalCampaignId: null,
        externalCampaignAccountId: null,
        externalCampaignObjective: null,
        externalNameTpl: null,
        agent: null,
        periodBudget: null,
        partnerId: null,
        accountId: null,
        currency: '',
        marketingGoal: null,
        timezone: null,
        scheduling: null,
        trackCampaign: false,
        isPolitical: false,
        notifOnStoppingAds: false,
        notifOnStoppingAdsRecepients: null
      },
      partner: null,
      agents: [],
      title: 'Create client',
      validationSchema: validationSchemas['client.edit'],
      externalCampaignName: null,
      adAccounts: [],
      marketingGoals,
      loadedAgent: null,
      error: null
    }
  },
  computed: {
    isInternalCampaignManagement () {
      return this.data.externalCampaignManagement === false
    },
    isExternalCampaignManagement () {
      return this.data.externalCampaignManagement === true
    },
    isDangerBorderNeeded () {
      return this.data.currency && this.data.currency !== 'HUF'
    }
  },
  created: function () {
  },
  watch: {
    'data.agent': function (agentUserId) {
      if (agentUserId) {
        this.loadDataSourcesForAgent(agentUserId)
      }
    },
    'data.externalCampaignId': function (externalCampaignId) {
      if (externalCampaignId && this.loadedAgent) {
        this.loadExternalCampaign(externalCampaignId)
      }
    },
    'data.accountId': function (newAccountId) {
      if (this.loadedAgent === this.data.agent) {
        this.handleAccountChange()
      }
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    async onPartnerLoaded (error, partner) {
      this.error = error
      if (partner) {
        this.partner = partner
        await this.loadAgents()
        if (this.$route.params.id) {
          this.load(this.$route.params.id)
        }
      } else {
        this.partner = null
      }
    },
    back () {
      this.$router.push({name: 'partner-client-list', params: {partnerId: this.partner.id}})
    },
    async loadAgents () {
      this[mutationTypes.BUSY_ON]()

      try {
        this.agents = await partnerService.listAgents(this.partner.id)
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },
    loadDataSourcesForAgent (agentUserId) {
      this[mutationTypes.BUSY_ON]()

      debug('Loading data sources for owner %s', agentUserId)

      return Promise.all([
        userService.listAdAccounts(agentUserId),
      ])
      .spread((adAccountResult) => {
        debug('Data sources loaded for owner %s', agentUserId)
        this[mutationTypes.BUSY_OFF]()
        this.adAccounts = adAccountResult
        this.loadedAgent = agentUserId
        if (this.data.accountId) {
          this.handleAccountChange()
        }
      })
      .catch((err) => {
        this.error = err
        this[mutationTypes.BUSY_OFF]()
      })
    },
    async loadExternalCampaign (externalCampaignId) {
      let agentId = this.loadedAgent
      this.externalCampaignName = null
      this.externalCampaignAccountId = null
      this.externalCampaignObjective = null
      this.data.accountId = null
      this.data.marketingGoal = null

      this[mutationTypes.BUSY_ON]()

      debug('Loading external campaign %s', externalCampaignId)

      try {
        let campaignDetails = await userService.readCampaignDetails(agentId, externalCampaignId)
        debug('Campaign details loaded for owner %s', agentId)
        this.data.externalNameTpl = campaignDetails.name
        this.externalCampaignAccountId = campaignDetails.account_id
        this.externalCampaignObjective = campaignDetails.objective
      } catch (error) {
        console.log('Cannot load external campaign, error:', error)
        this.error = new Error('Cannot read campaign details')
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }

      if (this.externalCampaignAccountId) {
        let adAccount = _.find(this.adAccounts, {account_id: this.externalCampaignAccountId})
        if (adAccount) {
          this.data.accountId = adAccount.account_id

          let marketingGoal = _.find(this.marketingGoals, {name: this.externalCampaignObjective})
          if (marketingGoal) {
            this.data.marketingGoal = marketingGoal.name
          } else {
            this.error = new Error('Unsupported marketing goal')
          }

        } else {
          this.error = new Error('Unknown campaign account ID')
        }
      }
    },
    handleAccountChange () {
      this.updateSelectedCurrency(this.data.accountId)
      this.updateSelectedTimezone(this.data.accountId)
    },
    updateSelectedCurrency (accountId) {
      let adAccount = _.find(this.adAccounts, {account_id: accountId})
      this.data.currency = adAccount ? adAccount.currency.toUpperCase() : ''
    },
    updateSelectedTimezone (accountId) {
      let adAccount = _.find(this.adAccounts, {account_id: accountId})
      this.data.timezone = adAccount ? adAccount.timezone_name : ''
    },
    load (id) {
      this.reset()
      this[mutationTypes.BUSY_ON]()
      return clientService.load(id)
      .then((data) => {
        this[mutationTypes.BUSY_OFF]()
        this.title = 'Update client'
        this.data = merge(true, data)
      })
      .catch((err) => {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      })
    },
    reset () {
    },
    async save (formData) {
      this[mutationTypes.BUSY_ON]()

      try {
        await clientService.save(this.data.id, merge(true, this.data, {partner: this.partner.id}))
        this[mutationTypes.BUSY_OFF]()
        this.back()
      } catch (err) {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      }
    },
    accountStatusText (statusNum) {
      let labels = {
        1: 'ACTIVE',
        2: 'DISABLED',
        3: 'UNSETTLED',
        7: 'PENDING_RISK_REVIEW',
        8: 'PENDING_SETTLEMENT',
        9: 'IN_GRACE_PERIOD',
        100: 'PENDING_CLOSURE',
        101: 'CLOSED',
        201: 'ANY_ACTIVE',
        202: 'ANY_CLOSED'
      }
      return labels.hasOwnProperty(statusNum) ? labels[statusNum] : 'unknown'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .danger-border {
    border-left: 5px solid red;
  }
</style>
