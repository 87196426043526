import Vue from 'vue'
import $ from 'jquery'
export default Vue.extend({
  name: 'joi-form-error-text',
  template: '<div class="invalid-feedback">{{message}}</div>',
  props: ['for', 'path', 'message'],
  isFormError: true,
  data () {
    return {
    }
  },
  methods: {
    show: function () {
      $(this.$el).show()
    },
    hide: function () {
      $(this.$el).hide()
    }
  }
})
