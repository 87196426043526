import clientService from '../services/client'

function requireClient (clientId, next) {
  clientService.load(clientId)
    .then((client) => {
      next(vm => {
        vm.onClientLoaded(null, client)
      })
    })
    .catch((error) => {
      next(vm => {
        vm.onClientLoaded(error, null)
      })
    })
}

export default {
  beforeRouteEnter: (to, from, next) => {
    let clientId = to.params.clientId
    requireClient(clientId, next)
  },
  beforeRouteUpdate: (to, from, next) => {
    let clientId = to.params.clientId
    requireClient(clientId, next)
  }
}
