var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row" }, [
    _c(
      "div",
      {
        staticClass: "col-md-10",
        class: { "col-md-12": !_vm.files.length, "col-md-10": _vm.files.length }
      },
      [
        _c("div", { staticClass: "input-group fileupload" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filename,
                expression: "filename"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", readonly: "" },
            domProps: { value: _vm.filename },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.filename = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "input-group-append" },
            [
              _c(
                "vue-file-upload",
                {
                  ref: "upload",
                  staticClass: "btn btn-primary",
                  attrs: {
                    headers: _vm.headers,
                    "post-action": _vm.postAction,
                    timeout: 6000
                  },
                  model: {
                    value: _vm.files,
                    callback: function($$v) {
                      _vm.files = $$v
                    },
                    expression: "files"
                  }
                },
                [_vm._v("Select file")]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _vm.files.length
      ? _c("div", { staticClass: "form-group col-md-2 text-right columns" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary upload",
              attrs: { type: "button" },
              on: { click: _vm.onUploadClick }
            },
            [_vm._v("Upload")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }