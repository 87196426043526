<template>
  <div class="form-row">
    <div class="form-group col-md-7">
      <div class="input-group">
        <input :disabled="!reference" class="form-control" type="number" step="0.00001" v-model="fixed" v-on:change="onFixedInputChange">
        <div class="input-group-append">
          <span class="input-group-text">{{unit}}</span>
        </div>
      </div>
    </div>
    <div class="form-group col-md-1 text-center align-middle pt-2">/</div>
    <div class="form-group col-md-4">
      <div class="input-group">
        <input :disabled="!reference" class="form-control" type="number" step="1" v-model="percent" v-on:change="onPercentInputChange">
        <div class="input-group-append">
          <span class="input-group-text">%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global */
import _ from 'lodash'

export default {
  name: 'value-percent',
  props: ['name', 'value', 'unit', 'reference'],
  data () {
    return {
      fixed: null,
      percent: null
    }
  },
  mounted () {
    this.debouncedReferenceChangeHandler = _.debounce(this.referenceChangeHandler.bind(this), 500)
    this.handleValueChange(this.value)
    this.referenceChangeHandler()
  },
  watch: {
    value (newValue) {
      this.handleValueChange(newValue)
    },
    reference (newReference) {
      this.debouncedReferenceChangeHandler()
    },
    fixed (newFixed) {
    },
    percent (newPercent) {
    }
  },
  methods: {
    handleValueChange (newValue) {
      if (newValue) {
        let floatVal = parseFloat(newValue)
        if (!isNaN(floatVal)) {
          this.fixed = floatVal
        } else {
          if (newValue.hasOwnProperty('fixed')) {
            this.fixed = newValue.fixed
          }
          if (newValue.hasOwnProperty('percent')) {
            this.percent = newValue.percent
          }
        }
      } else {
        this.fixed = this.percent = null
      }
    },
    referenceChangeHandler () {
      let parsedReference = parseFloat(this.reference)
      if (isNaN(parsedReference)) {
        this.calc()
      } else if (this.percent) {
        this.calc('percent', this.percent)
      } else if (this.fixed) {
        this.calc('fixed', this.fixed)
      }
    },
    onFixedInputChange (e) {
      this.calc('fixed', e.target.value)
    },
    onPercentInputChange (e) {
      this.calc('percent', e.target.value)
    },
    calc (source, newValue) {
      let reference = parseInt(this.reference, 10)
      let value = {fixed: null, percent: null}

      if (!isNaN(reference)) {
        switch (source) {
          case 'fixed':
            newValue = parseInt(newValue, 10)

            if (!isNaN(newValue)) {
              if (newValue > reference) {
                this.fixed = reference
              }

              this.percent = Math.round(this.fixed / reference * 100)
            }
            break
          case 'percent':
            newValue = parseInt(newValue, 10)

            if (!isNaN(newValue)) {
              newValue = Math.min(newValue, 100)
              this.percent = newValue
              this.fixed = Math.round(reference * newValue / 100)
            }
            break
        }

        value.fixed = this.fixed
        value.percent = this.percent
      }
      this.$emit('input', value)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // .value-percent {
  //   .separator {
  //     line-height: rem-calc(36);
  //   }
  // }
</style>
