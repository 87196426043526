
<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <error-reveal :error="error"></error-reveal>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <chart-time-series title="Published ads in the last 24 hours" :subtitle="chartGenericSubtitle" :height="400" :colors="adCreatedStoppedChartData.colors" :pointsInTime="adCreatedStoppedChartData.pointsInTime" :series="adCreatedStoppedChartData.series" :showLegend="true"></chart-time-series>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <chart-time-series title="Managed ads in the last 24 hours" :subtitle="chartGenericSubtitle" :height="400" :colors="adManagedChartData.colors" :pointsInTime="adManagedChartData.pointsInTime" :series="adManagedChartData.series" :showLegend="true"></chart-time-series>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import ChartTimeSeries from '../../charts/TimeSeries.vue'
import ErrorReveal from '../../errors/Reveal'
import analyticsService from '../../services/analytics'
import moment from 'moment'
import 'moment-timezone'

export default {
  name: 'system-analytics-ads',
  components: {
    ErrorReveal,
    ChartTimeSeries
  },
  props: {
    adsetProtoId: String,
    partnerId: String,
    clientId: String,
    campaignId: String,
    agentId: String
  },
  data () {
    let baseTime = moment()
    let from = baseTime.clone().subtract(24, 'hours')
    let to = baseTime.clone()

    return {
      error: null,
      chartsInterval: {
        from,
        to
      },
      adCreatedStoppedChartData: {
        colors: ['#6E9130', '#e80202'],
        pointsInTime: [],
        series: [
          {
            name: 'Created',
            visible: true,
            data: []
          },
          {
            name: 'Stopped',
            visible: true,
            data: []
          }
        ]
      },
      adManagedChartData: {
        pointsInTime: [],
        series: [
          {
            name: 'Restarted',
            visible: true,
            data: []
          },
          {
            name: 'Aborted',
            visible: true,
            data: []
          },
          {
            name: 'Suspended',
            visible: true,
            data: []
          },
          {
            name: 'Resumed',
            visible: true,
            data: []
          }
        ]
      },
    }
  },
  computed: {
    chartGenericSubtitle () {
      return this.chartsInterval.from.format('YYYY.MM.DD HH:mm') + ' - ' + this.chartsInterval.to.format('YYYY.MM.DD HH:mm')
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    async load () {
      this.info = null
      this[mutationTypes.BUSY_ON]()
      try {
        let rawData = await analyticsService.queryAdAnalytics({
          from: this.chartsInterval.from.format(),
          to: this.chartsInterval.to.format(),
          partner: this.partnerId,
          client: this.clientId,
          campaign: this.campaignId,
          agent: this.agentId,
          adsetProto: this.adsetProtoId
        })
        this[mutationTypes.BUSY_OFF]()
        this.buildChartsData(rawData)
      } catch (err) {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      }
    },
    buildChartsData (rawData) {
      this.mergeChartsDataInto(rawData, ['ad_creates', 'ad_stops'], 'adCreatedStoppedChartData')
      this.mergeChartsDataInto(rawData, ['ad_restarts', 'ad_aborts', 'ad_suspends', 'ad_resumes'], 'adManagedChartData')
    },
    mergeChartsDataInto (rawData, measurements, dataKey) {
      this[dataKey].pointsInTime = []

      let timeSeriesKey = _.findKey(rawData, (entries, key) => entries.length || measurements.includes(key))
      if (timeSeriesKey) {
        this[dataKey].pointsInTime = rawData[timeSeriesKey].map(dataPoint => moment(dataPoint.time).tz('Europe/Budapest'))
      }

      for (let [seriesIdx, measurement] of measurements.entries()) {
        this[dataKey].series[seriesIdx].data = []

        if (rawData[measurement]) {
          for (let dataPoint of rawData[measurement]) {
            this[dataKey].series[seriesIdx].data.push(dataPoint.value || 0)
          }
        } else {
          this[dataKey].series[seriesIdx].data = []
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
