var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card shadow mb-4" },
    [
      _c("error-reveal", { attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("div", { staticClass: "card-header py-3" }, [
        _c(
          "a",
          {
            staticClass: "float-right",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.back()
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-fw fa-arrow-circle-left" }),
            _vm._v(" Back")
          ]
        ),
        _vm._v(" "),
        _c("h6", { staticClass: "m-0 font-weight-bold text-primary" }, [
          _vm._v("Insights "),
          _c("i", { staticClass: "fas fa-fw fa-angle-double-right" }),
          _vm._v(" " + _vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _vm.results
          ? _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-8" }, [
                _c("h5", [
                  _vm._v(
                    "Partner: " +
                      _vm._s(_vm.fmtPartnerInfo(_vm.results.partner))
                  )
                ]),
                _vm._v(" "),
                _c("h6", [
                  _vm._v(
                    "Client: " + _vm._s(_vm.fmtClientInfo(_vm.results.client))
                  )
                ]),
                _vm._v(" "),
                _c("h6", [
                  _vm._v(
                    "Adset: " +
                      _vm._s(_vm.fmtAdsetProtoInfo(_vm.results.adsetProto))
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-4 text-right" }, [
                _c("label", { staticClass: "text-info" }, [
                  _vm._v(_vm._s(_vm.fmtFrom) + " - " + _vm._s(_vm.fmtTo))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.results
          ? _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  !_vm.results.marketingGoals
                    ? _c("div", [
                        _vm._v(
                          "No available campaigns for the selected options."
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.results.marketingGoals, function(
                    marketingGoalResults
                  ) {
                    return _c(
                      "div",
                      { key: marketingGoalResults.name, staticClass: "mb-4" },
                      [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "bg-primary text-white p-2 mb-0 rounded-top"
                          },
                          [_vm._v(_vm._s(marketingGoalResults.title))]
                        ),
                        _vm._v(" "),
                        marketingGoalResults.collection
                          ? _c(
                              "table",
                              {
                                staticClass:
                                  "table table-bordered border-primary small"
                              },
                              [
                                _vm._m(0, true),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    marketingGoalResults.collection,
                                    function(campaign) {
                                      return _c("tr", { key: campaign.id }, [
                                        _c(
                                          "td",
                                          { staticClass: "border-primary" },
                                          [
                                            _vm._v(
                                              _vm._s(campaign.name) +
                                                " (" +
                                                _vm._s(
                                                  _vm.formatDateInterval(
                                                    campaign.interval
                                                  )
                                                ) +
                                                ") "
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "text-primary cursor-pointer fas fa-fw fa-external-link-square-alt",
                                              on: {
                                                click: function($event) {
                                                  return _vm.openCampaignEdit(
                                                    campaign.partner,
                                                    campaign.client,
                                                    campaign.id
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "border-primary text-right"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatSum(
                                                  campaign.results,
                                                  0
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "border-primary text-right"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatMoneyAmount(
                                                  _vm.calcAvgCostPerAction(
                                                    campaign
                                                  ),
                                                  2
                                                )
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "small" },
                                              [
                                                _vm._v(
                                                  _vm._s(campaign.currency)
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "border-primary text-right"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatMoneyAmount(
                                                  campaign.spend,
                                                  2
                                                )
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "small" },
                                              [
                                                _vm._v(
                                                  _vm._s(campaign.currency)
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _c("tfoot", [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticClass: "border-primary",
                                        attrs: { scope: "col" }
                                      },
                                      [_vm._v("SUM")]
                                    ),
                                    _vm._v(" "),
                                    _vm._m(1, true),
                                    _vm._v(" "),
                                    _vm._m(2, true),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticClass:
                                          "border-primary text-right",
                                        staticStyle: { width: "130px" },
                                        attrs: { scope: "col" }
                                      },
                                      _vm._l(
                                        marketingGoalResults.summary
                                          .byCurrencies,
                                        function(summary, currency) {
                                          return _c(
                                            "p",
                                            {
                                              key: currency,
                                              staticClass: "m-0"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatMoneyAmount(
                                                    summary.spend,
                                                    2
                                                  )
                                                ) + " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "small" },
                                                [_vm._v(_vm._s(currency))]
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  ])
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !marketingGoalResults.collection
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-muted small p-2 border border-primary"
                              },
                              [_vm._v("No results.")]
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "border-primary", attrs: { scope: "col" } }, [
          _vm._v("Campaign")
        ]),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "border-primary text-right",
            staticStyle: { width: "130px" },
            attrs: { scope: "col" }
          },
          [_vm._v("Results")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "border-primary text-right",
            staticStyle: { width: "150px" },
            attrs: { scope: "col" }
          },
          [_vm._v("Avg. cost per action")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "border-primary text-right",
            staticStyle: { width: "130px" },
            attrs: { scope: "col" }
          },
          [_vm._v("Spend")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "th",
      {
        staticClass: "border-primary text-right",
        staticStyle: { width: "130px" },
        attrs: { scope: "col" }
      },
      [_c("p", { staticClass: "m-0" }, [_vm._v("-")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "th",
      {
        staticClass: "border-primary text-right",
        staticStyle: { width: "130px" },
        attrs: { scope: "col" }
      },
      [_c("p", { staticClass: "m-0" }, [_vm._v("-")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }