<template>
  <div class="">
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>Scheduling:</label>
        <select v-model="data.type" class="form-control">
          <option value="">-</option>
          <option value="static">Static</option>
          <option value="periodic">Periodic</option>
        </select>
        <span :error-path="errorPathPrefix + 'type'"></span>
      </div>

      <div class="form-group col-md-6" v-if="data.type === 'static'">
        <label>Interval:</label>
        <date-picker key="staticDatePicker" v-model="data.static.interval" :config="staticFlatpickrConfig" class="form-control" />
        <span error-path="data.static.interval.from"></span>
        <span error-path="data.static.interval.to"></span>
      </div>

      <div class="form-group col-md-3" v-if="data.type === 'periodic'">
        <label>Start:</label>
         <date-picker key="periodicDatePicker" v-model="data.periodic.start" :config="periodicFlatpickrConfig" class="form-control" />
        <span error-path="data.periodic.start"></span>
      </div>

      <div class="form-group col-md-3" v-if="data.type === 'periodic'">
        <label>Period:</label>
        <div class="row no-gutters">

          <div class="col-md-5">
            <input type="number" v-model="data.periodic.count" class="form-control">
          </div>
          <div class="col-md-7">
            <select v-model="data.periodic.unit" class="form-control">
              <option value="">-</option>
              <option v-for="item of periodUnits" :value="item.name" v-bind:key="item.name">{{item.title}}</option>
            </select>
          </div>
        </div>
        <span error-path="data.periodic.start"></span>
      </div>

    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import moment from 'moment'
import i18nService from '../../services/i18n'
import DatePicker from '../../inputs/date-picker'


function getEmptyValue () {
  return {
    type: null,
    static: getEmptyValueForStatic(),
    periodic: getEmptyValueForPeriodic()
  }
}

function getEmptyValueForStatic () {
  return {
    interval: {
      from: moment().toDate(),
      to: moment().add(30, 'days').toDate()
    }
  }
}

function getEmptyValueForPeriodic () {
  return {
    start: moment().endOf('month').toDate(),
    count: 1,
    unit: 'months'
  }
}

export default {
  name: 'client-schedule',
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Object
    },
    errorPathPrefix: {
      type: String
    }
  },
  data () {
    return {
      staticFlatpickrConfig: i18nService.flatpickrConfig({mode: 'range'}),
      periodicFlatpickrConfig: {
        ...i18nService.flatpickrConfig({mode: 'single'}),
        disable: [
          function(date) {
            return date.getDate() > 27
          }
        ]
      },
      data: getEmptyValue(),
      periodUnits: [{
        name: 'days',
        title: 'day(s)'
      }, {
        name: 'weeks',
        title: 'week(s)'
      }, {
        name: 'months',
        title: 'month(s)'
      }]
    }
  },
  watch: {
    data: {
      handler: function (val, oldVal) {
        this.emitValueFromData(val)
      },
      deep: true
    },
    value: {
      handler: function (val, oldVal) {
        this.updateData(val)
      },
      deep: true
    }
  },
  created () {
    if (this.value) {
      this.updateData(this.value)
    }
  },
  methods: {
    updateData (params) {
      if (params.type) {
        this.data.type = params.type
      }
      if (params.static) {
        this.data.static = params.static
      }
      if (params.periodic) {
        this.data.periodic = params.periodic
      }
    },
    emitValueFromData (data) {
      let val = null

      if (data.type) {
        val = {type: data.type}
        switch (val.type) {
          case 'static':
            val.static = data.static || getEmptyValueForStatic()
          break
          case 'periodic':
            val.periodic = data.periodic || getEmptyValueForPeriodic()
          break
        }
      }

      this.$emit('input', val)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
