<script>
import api from './api'
export default {
  load (id) {
    return api.get('partners/' + id)
  },
  save (id, formData) {
    if (!id) {
      return api.post('partners', formData)
    }
    return api.put('partners/' + id, formData)
  },
  list (params) {
    return api.get('partners', params)
  },
  listAgents (partnerId, params) {
    return api.get(`partners/${partnerId}/agents`, params)
  },
  loadAgent (partnerId, id) {
    return api.get(`partners/${partnerId}/agents/${id}`)
  },
  saveAgent (partnerId, id, formData) {
    if (!id) {
      return api.post(`partners/${partnerId}/agents`, formData)
    }
    return api.put(`partners/${partnerId}/agents/${id}`, formData)
  },
  listAgents (partnerId, params) {
    return api.get(`partners/${partnerId}/agents`, params)
  }
}
</script>
