<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-4">
        <button v-if="addNewEnabled" type="button" name="button" class="btn btn-primary btn-sm" v-on:click="onAddNewItem"><i class="fas fa-fw fa-plus"></i> {{additemtext}}</button>
        &nbsp;
      </div>
      <div class="col-sm-4">
        {{title}}
      </div>
      <div class="col-sm-4 text-right">
        &nbsp;
        <button v-if="refreshEnabled" type="button" name="button" class="btn btn-secondary btn-sm" v-on:click="onRefresh"><i class="fas fa-fw fa-sync-alt"></i> {{refreshtext}}</button>
      </div>
    </div>
    <div v-if="!items || !items.length" class="text-center m-2">{{emptytext}}</div>
    <table v-if="items && items.length" class="table table-hover table-responsive-sm table-bordered table-striped table-sm" width="100%" cellspacing="0">
      <thead>
        <tr>
          <th v-if="enableRowSelect" width="30" class="text-center"><input type="checkbox" v-model="masterRowCheck" @click="onRowSelectCheckboxClick()"></th>
          <th v-for="column of columns" :width="column.width" v-bind:class="{'text-left': getColumnTitleAlign(column) === 'left', 'text-center': getColumnTitleAlign(column) === 'center', 'text-right': getColumnTitleAlign(column) === 'right'}" :align="column.align" v-html="getColumnTitle(column)"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item of items" @click="onItemClick(item)" :class="getMetaClassName(item)">
          <td v-if="enableRowSelect" class="text-center"><input type="checkbox" @click="onRowSelectCheckboxClick()"></td>
          <td v-for="column of columns" :width="column.width" @click="onItemColumnClick(item, column)" v-bind:class="{'text-left': column.align === 'left', 'text-center': column.align === 'center', 'text-right': column.align === 'right'}" :valign="column.valign">
            <span v-if="column.name" class="content-cell" v-bind:class="{'clickable': column.clickable}" v-html="getColumnValue(item, column.name)"></span>
            <span v-if="column.actions" class="actions">
              <span v-for="action of column.actions" class="action">
                <button type="button" class="btn btn-primary btn-xs" :class="action.class" @click="onItemAction(action, item)">
                  <span v-html="action.html" v-if="action.html"></span><span v-if="action.text">{{action.text}}</span>
                </button>
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import _ from 'lodash'
import $ from 'jquery'
import SafeString from '../utils/SafeString'
// import './dataTables.bootstrap4'

export default {
  name: 'data-grid',
  components: {
  },
  props: {
    title: {
      default: '',
      type: String
    },
    items: {
      default: [],
      type: Array
    },
    columns: {
      default: [],
      type: Array
    },
    emptytext: {
      default: 'Nincs megjeleníthető adat.',
      type: String
    },
    additemtext: {
      default: 'Hozzáadás',
      type: String
    },
    refreshtext: {
      default: 'Frissítés',
      type: String
    },
    addNewEnabled: {
      default: true,
      type: Boolean
    },
    refreshEnabled: {
      default: true,
      type: Boolean
    },
    enableRowSelect: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      masterRowCheck: false
    }
  },
  methods: {
    getColumnTitle (column) {
      let value = _.get(column, 'title')
      return value instanceof SafeString ? value.toString() : _.escape(value)
    },
    getColumnTitleAlign (column) {
      let align = _.get(column, 'titleAlign')
      if (align) {
        return align
      }
      return _.get(column, 'align')
    },
    getColumnValue (item, path) {
      let value = _.get(item, path)
      return value instanceof SafeString ? value.toString() : _.escape(value)
    },
    getMetaClassName (item) {
      return _.get(item, '_meta.class', '')
    },
    onAddNewItem () {
      this.$emit('additem')
    },
    onItemClick (item) {
      this.$emit('itemclick', item)
    },
    onItemColumnClick (item, column) {
      this.$emit('itemcolumnclick', item, column)
    },
    onItemAction (action, item) {
      this.$emit('itemaction', action, item)
    },
    onRefresh () {
      this.$emit('refresh')
    },
    onRowSelectCheckboxClick () {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/main';

  .clickable {
    color: theme-color();
    cursor: pointer;
  }
</style>
