<template>
  <joi-form :data="data" :schema="validationSchema" v-on:formdataready="save">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">{{title}}</h6>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <error-reveal :error="error"></error-reveal>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Partner:</label>
            <select v-model="data.partnerId" class="form-control">
              <option value="">-</option>
              <option v-for="item of partners" :value="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Client:</label>
            <select v-model="data.clientId" class="form-control">
              <option value="">- All -</option>
              <option v-for="item of clients" :value="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="switchClientStatusAll" v-model="listAllClients">
              <label class="custom-control-label" for="switchClientStatusAll">List inactive clients</label>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Adset:</label>
            <select v-model="data.adsetProtoId" class="form-control">
              <option value="">- All -</option>
              <option v-for="item of adsetProtos" :value="item.id">{{item.name}}</option>
            </select>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Interval:</label>
            <date-picker v-model="data.interval" :config="intervalFlatpickrConfig" error-path="interval" class="form-control"></date-picker>
            <span error-path="interval.min"></span>
            <span error-path="interval.max"></span>
          </div>
        </div>

      </div>
      <div class="card-footer text-muted">
        <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
      </div>
    </div>
  </joi-form>
</template>

<script>
import { mapMutations } from 'vuex'
import Promise from 'bluebird'
import _ from 'lodash'
import moment from 'moment'
import i18nService from '../../services/i18n'
import * as mutationTypes from '../../store/mutation-types'
import JoiForm from '../../inputs/joi-form'
import DatePicker from '../../inputs/date-picker'
import ErrorReveal from '../../errors/Reveal'
import validationSchemas from '../../validators/schemas'
import partnerService from '../../services/partner'
import clientService from '../../services/client'
import adsetProtoService from '../../services/adsetproto'

// import i18nService from '../../services/i18n'

export default {
  name: 'insights-query',
  components: {
    JoiForm,
    DatePicker,
    ErrorReveal
  },
  data () {
    return {
      partners: [],
      clients: [],
      adsetProtos: [],
      intervalFlatpickrConfig: i18nService.flatpickrConfig({mode: 'range'}),
      data: {
        partnerId: null,
        clientId: null,
        adsetProtoId: null,
        interval: {
          from: moment().subtract(1 + 30, 'days').toDate(),
          to: moment().subtract(1, 'days').toDate()
        }
      },
      title: 'Query parameters',
      validationSchema: validationSchemas['insights.query'],
      error: null,
      queryParams: null,
      listAllClients: false
    }
  },
  created: function () {
    this.queryParams = this.$route.query
    this.load()
  },
  watch: {
    'data.partnerId': 'onPartnerChanged',
    'data.clientId': 'onClientChanged',
    'listAllClients': 'onListAllClientsChanged'
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    load () {
      this.reset()
      if (this.queryParams) {
        if (this.queryParams.from && this.queryParams.to) {
          this.data.interval = {
            from: this.queryParams.from,
            to: this.queryParams.to
          }
        }
        this.data.partnerId = this.queryParams.partnerId || null
        this.data.clientId = this.queryParams.clientId || ''
        this.data.adsetProtoId = this.queryParams.adsetProtoId || ''
        if (this.queryParams.listAllClients !== undefined) {
          this.listAllClients = this.queryParams.listAllClients
        }
      }

      this.loadPartners()
    },
    async loadPartners () {
      this[mutationTypes.BUSY_ON]()

      try {
        this.partners = await partnerService.list()
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },
    onPartnerChanged () {
      this.clients = []
      if (this.data.partnerId) {
        this.loadClients()
      }
    },
    async loadClients () {
      this[mutationTypes.BUSY_ON]()

      try {
        let options = {partner: this.data.partnerId}
        if (!this.listAllClients) {
          options.active = true
        }
        this.clients = await clientService.list(options)
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },
    onClientChanged () {
      this.adsetProtos = []
      if (this.data.clientId) {
        this.loadAdsets()
      }
    },
    onListAllClientsChanged () {
      if (this.data.partnerId) {
        this.loadClients()
      }
    },
    async loadAdsets () {
      this[mutationTypes.BUSY_ON]()

      try {
        this.adsetProtos = await adsetProtoService.list({client: this.data.clientId})
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },
    reset () {
      this.data = {
        partnerId: null,
        clientId: null,
        adsetProtoId: null,
        interval: {
          from: moment().subtract(1 + 30, 'days').toDate(),
          to: moment().subtract(1, 'days').toDate()
        }
      }
    },
    save (formData) {
      let query = {}
      query.from = moment(this.data.interval.from).startOf('day').format()
      query.to = moment(this.data.interval.to).endOf('day').format()
      query.partnerId = this.data.partnerId
      query.clientId = this.data.clientId
      query.adsetProtoId = this.data.adsetProtoId
      query.listAllClients = this.listAllClients
      this.$router.push({name: 'insights-display', query: query})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
