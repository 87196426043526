var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card shadow mb-4" }, [
    _c("div", { staticClass: "card-header py-3" }, [
      _c("h6", { staticClass: "m-0 font-weight-bold text-primary" }, [
        _c("i", { staticClass: "fas fa-fw fa-cog" }),
        _vm._v(" " + _vm._s(_vm.title))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "card-body" }, [
      _c("div", {}, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-content", attrs: { id: "nav-tabContent" } },
          [
            _c(
              "div",
              {
                staticClass:
                  "tab-pane fade show active p-3 border-left border-bottom border-right",
                attrs: {
                  id: "nav-cache",
                  role: "tabpanel",
                  "aria-labelledby": "nav-cache-tab"
                }
              },
              [_c("settings-cache")],
              1
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c(
        "div",
        {
          staticClass: "nav nav-tabs",
          attrs: { id: "nav-tab", role: "tablist" }
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-item nav-link active",
              attrs: {
                id: "nav-cache-tab",
                "data-toggle": "tab",
                href: "#nav-cache",
                role: "tab",
                "aria-controls": "nav-cache",
                "aria-selected": "true"
              }
            },
            [_vm._v("Cache")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }