var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "form-row" }, [
      _c(
        "div",
        { staticClass: "form-group col-md-6" },
        [
          _c("error-reveal", { attrs: { error: _vm.error } }),
          _vm._v(" "),
          _c("info-reveal", { attrs: { message: _vm.info } })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-6" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary trans-uppercase",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.clearCache()
              }
            }
          },
          [_vm._v("Clear cache")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }