<template>
  <div class="layout-container">
    <div class="layout-canvas-wrap">
      <div class="layout-canvas">
        <router-view></router-view>
      </div>
    </div>
    <partials-footer></partials-footer>
  </div>
</template>

<script>
import PartialsFooter from '../partials/Footer'
export default {
  name: 'layout-public',
  components: {
    'partials-footer': PartialsFooter
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // @import '../../sass/foundation-import';
  // @import '../../sass/layouts/fullscreen';

  // .layout-canvas-wrap {
  //   background: $body-background url("../../assets/bg.jpg") repeat right top;
  // }

</style>
