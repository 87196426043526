<template>
  <footer>
    <!-- &copy; 2017. ... -->
  </footer>
</template>

<script>
export default {
  name: 'partials-footer',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  // @import '../../sass/foundation-import';

  // footer {
  //   position: relative;
  //   margin-top: -$footer-height; /* negative value of footer height */
  //   height: $footer-height;
  //   clear:both;
  //   background: $footer-background;
  //   text-align: center;
  //   color: $white;
  //   font-size: $footer-font-size;
  //   line-height: $footer-height;
  // }
</style>
