/* global API_URL */

import urllib from 'url'
import querystring from 'querystring'
import _ from 'lodash'
import merge from 'merge'
import store from '../store'

function convertResponseToError (response) {
  console.log('API request failed, response: ', response)
  if (response.body && response.body) {
    return new Error(response.body.message, response.body.code)
  }
  if (!response.status && !response.statusText) {
    response.status = 500
    response.statusText = 'API internal server error'
  }

  return new Error(response.statusText, response.status)
}

function rewriteUrl (url, addHeadersAsParams) {
  var parsedUrl
  var headerParams = {}

  if (addHeadersAsParams) {
    if (store.state.user) {
      headerParams.access_token = store.state.user.token
    }

    parsedUrl = urllib.parse(url)
    if (parsedUrl.search) {
      parsedUrl.query = merge(parsedUrl.query, querystring.decode(parsedUrl.search.substring(parsedUrl.search.indexOf('?') + 1)))
      parsedUrl.search = null
    }
    parsedUrl.query = merge(parsedUrl.query, headerParams)
    url = urllib.format(parsedUrl)
  }

  if (_.startsWith(url, 'api://')) {
    return API_URL + url.substr(5)
  }

  return url
}

function getHeaders () {
  var headers = {}
  if (store.state.user) {
    headers['Authorization'] = 'Bearer ' + store.state.user.token
  }

  return headers
}

function interceptor (request, next) {
  var headers = {}
  if (_.startsWith(request.url, 'api://')) {
    request.url = rewriteUrl(request.url)
    headers = getHeaders()
    _.forOwn(headers, (value, name) => {
      request.headers.set(name, value)
    })
  }
  next((response) => {
    if (!response.ok) {
      return convertResponseToError(response)
    }
  })
}

export {interceptor, rewriteUrl, getHeaders}
