import partnerService from '../services/partner'

function requirePartner (partnerId, next) {
  partnerService.load(partnerId)
    .then((partner) => {
      next(vm => {
        vm.onPartnerLoaded(null, partner)
      })
    })
    .catch((error) => {
      next(vm => {
        vm.onPartnerLoaded(error, null)
      })
    })
}

export default {
  beforeRouteEnter: (to, from, next) => {
    let partnerId = to.params.partnerId
    requirePartner(partnerId, next)
  },
  beforeRouteUpdate: (to, from, next) => {
    let partnerId = to.params.partnerId
    requirePartner(partnerId, next)
  }
}
