// 23844778915700249
// „Nem a legjobbak a kilátások...”
// adset: Fociclub teszt


<template>
  <div>
    <joi-form :data="data" :schema="validationSchema" v-on:formdataready="save">
      <div class="card shadow mb-4">
        <div class="card-header py-3" v-if="client">
          <a @click.prevent="back()" href="#" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</a>
          <h6 class="m-0 font-weight-bold text-primary">{{client.partner.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{client.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{title}}</h6>
        </div>
        <div class="card-body" :class="{'danger-border': isDangerBorderNeeded}">
          <div class="form-row">
            <div class="form-group 12">
              <error-reveal :error="error"></error-reveal>
              <info-reveal :message="info"></info-reveal>
            </div>
          </div>

          <div class="row">
            <!-- Start left pane -->
            <div class="col-md-6">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Name:</label>
                  <input type="text" v-model="data.name" name="name" class="form-control">
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>DSA Payor:</label>
                  <input type="text" v-model="data.dsa_payor" name="dsa_payor" class="form-control">
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>DSA Beneficiary:</label>
                  <input type="text" v-model="data.dsa_beneficiary" name="dsa_beneficiary" class="form-control">
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Template ad FB ID:</label>
                  <input type="text" v-model.lazy="data.templateAdFbId" name="templateAdFbId" class="form-control" :disabled="!client || data.id">
                  <span error-path="templateAdFbId"></span>
                </div>
                <div class="form-group col-md-12">
                </div>
              </div>

              <!-- <div class="form-row" v-if="client && client.marketingGoal === 'LEAD_GENERATION'">
                <div class="form-group col-md-12">
                  <label>Template ad FB id:</label>
                  <input type="text" v-model="data.templateAdFbId" name="templateAdFbId" class="form-control">
                </div>
              </div>
 -->

              <div class="form-row">
                <div class="form-group col-md-12">
                  <label>Facebook adset name:</label>
                  <input type="text" v-model="data.externalNameTpl" name="externalNameTpl" class="form-control">
                  <small class="form-text text-info">Serial number of the campaign: %serial% </small>
                  <small class="form-text text-info">Name of the targeted page: %page% </small>
                  <small class="form-text text-info">Current date: %today% </small>
                  <small class="form-text text-info">Extract placeholders from message:</small>
                  <textarea class="form-control mt-1 text-info" rows="3" v-model="data.customPlaceholders"></textarea>
                  <small class="form-text text-muted">Sample: %name% = #news-[*]-category</small>
                  <span error-path="externalNameTpl"></span>
                </div>
              </div>

              <div class="form-row" v-if="client && isInternalCampaignManagement">
                <div class="form-group col-md-12">
                  <label>Targeted pages:</label>
                  <page-input :pages="pages" v-model="data.pages"></page-input>
                  <span error-path="pages"></span>
                </div>
              </div>

              <div class="form-row" v-if="client && isExternalCampaignManagement">
                <div class="form-group col-md-12">
                  <label>Targeted pages:</label>
                  <small v-if="!data.templateAdFbId" class="mt-0 form-text text-info">Please select an ad.</small>
                  <small v-if="data.templateAdFbId && !data.pages.length" class="mt-0 form-text text-info">No page associated with the selected ad.</small>
                  <p v-if="data.templateAdFbId && data.pages.length" class="mt-0">
                    <small class="form-text text-info" v-for="page in data.pages" v-bind:key="page.id">{{page.name}} ({{page.id}})</small>
                  </p>
                  <span error-path="pages"></span>
                </div>
              </div>

              <div class="form-row">
                <div class="form-group col-md-12">
                  <ad-management-policy
                    v-if="client"
                    v-model="data.adManagementPolicy"
                    :currency="client.currency"
                    :savedAudiences="savedAudiences"
                    :adsPixels="adsPixels"
                    :marketingGoal="marketingGoal"
                    :actionCostMetricList="actionCostMetricList"
                    :resultMetricList="resultMetricList"
                    errorPathPrefix="adManagementPolicy"
                  ></ad-management-policy>
                </div>
              </div>

            </div>
            <!-- End left pane -->

            <!-- Start right pane -->
            <div class="col-md-6">
              <div class="form-row">
                <div class="form-group col-md-12">
                  <button v-if="data.id" type="button" class="btn btn-primary" @click="showBufferDialog()">Buffer</button>
                  <small v-if="!data.id" class="form-text text-info">Buffer can be set after saving the adset.</small>
                </div>
              </div>
            </div>
            <!-- End right pane -->
          </div>
        </div>
        <div class="card-footer text-muted">
          <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
          <!-- <button type="button" class="btn btn-info trans-uppercase" v-if="data.id" @click="download">Download</button> -->
          <!-- <button type="button" class="btn btn-danger trans-uppercase float-right" v-if="data.id" @click="remove">Delete</button> -->
        </div>
      </div>
    </joi-form>
    <buffer-dialog v-if="data.id" ref="bufferDialog" :adsetprotoId="data.id" :pages="pages"></buffer-dialog>
  </div>
  <!-- <button type="button" class="button secondary small" @click="openAddFbPostDialog">Add existing Facebook post to the buffer</button> -->
  <!-- <fb-post-dialog ref="fbPostDialog"></fb-post-dialog> -->

</template>

<script>
/* global API_URL */

import Debug from 'debug'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import Promise from 'bluebird'
import moment from 'moment'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import {getHeaders} from '../../interceptors/ApiRewrite'
import JoiForm from '../../inputs/joi-form'
import ErrorReveal from '../../errors/Reveal'
import InfoReveal from '../../containers/InfoReveal'
import validationSchemas from '../../validators/schemas'
import AdManagementPolicy from './AdManagementPolicy'
import userService from '../../services/user'
import adsetprotoService from '../../services/adsetproto'
import ClientRequired from '../../mixins/ClientRequired'
import BufferDialog from '../contentbuffers/BufferDialog'
import PageInput from '../../inputs/page-input'
// import i18nService from '../../services/i18n'
import Confirm from '../../mixins/Confirm'

const debug = Debug('app:adsetproto:edit')

function getEmptyAdManagementPolicy () {
  return {
    method: null,
    savedAudienceId: null,
    adsPixelId: null,
    urlTags: null,
    promotedUrl: null,
    adPeriodInHour: null,
    adOpeningBudget: null,
    maxAvgActionCost: null,
    freerunThreshold: null,
    resumeAvgActionCost: null,
    // autoStopThreshold: null,
    numberOfMaxRunningAd: null,
    numberOfMaxAdPerAdset: null,
    maxUniqueInlineLinkClicks: null,
    newAdsEnabled: false,
    autoStopAdsEnabled: false,
    autoResumeAdsEnabled: false,
    noTrackingSpecs: false,
    actionCostMetric: null,
    resultMetric: null
  }
}

function getInitData () {
  return {
    id: undefined,
    name: null,
    externalCampaignManagement: false,
    templateAdFbId: null,
    pages: [],
    adManagementPolicy: getEmptyAdManagementPolicy()
  }
}

function getCustomConversationActionCostMetricName (customConversationId) {
  return `cost_per_action_type.offsite_conversion.custom.${customConversationId}`
}

function getCustomConversationResultMetricName (customConversationId) {
  return `actions.offsite_conversion.custom.${customConversationId}`
}

export default {
  name: 'adsetprotos-edit',
  mixins: [Confirm, ClientRequired],
  components: {
    JoiForm,
    ErrorReveal,
    InfoReveal,
    PageInput,
    AdManagementPolicy,
    BufferDialog
  },
  data () {
    return {
      client: null,
      data: getInitData(),
      title: 'Create adset',
      validationSchema: validationSchemas['adsetproto.edit'],
      error: null,
      owners: [],
      pages: [],
      savedAudiences: [],
      adsPixels: [],
      info: null,
      isCampaignEmpty: true,
      marketingGoal: '',
      actionCostMetricList: [],
      resultMetricList: []
    }
  },
  computed: {
    isInternalCampaignManagement () {
      return this.data.externalCampaignManagement === false
    },
    isExternalCampaignManagement () {
      return this.data.externalCampaignManagement === true
    },
    isDangerBorderNeeded () {
      return this.client && this.client.currency !== 'HUF'
    }
  },
  watch: {
    '$route': function (newVal, oldVal) {
      if (newVal.params.id) {
        this.load(newVal.params.id)
      }
    },
    'data.templateAdFbId': function (templateAdFbId) {
      if (templateAdFbId && this.client) {
        this.loadAdTemplateDetails(templateAdFbId)
      }
    },
  },
  created () {
    if (this.$route.params.id) {
      this.load(this.$route.params.id)
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onClientLoaded (error, client) {
      this.error = error
      if (client) {
        this.client = client
        this.data.externalCampaignManagement = this.client.externalCampaignManagement
        this.marketingGoal = this.client.marketingGoal
        this.loadDataSourcesForClient()
      } else {
        this.client = null
      }
    },
    onAddNewItem () {
      this.$router.push('adsetprotos/edit')
    },
    back () {
      this.$router.push({name: 'client-adsetproto-list', params: {clientId: this.client.id}})
    },
    load (id) {
      this.reset()
      this[mutationTypes.BUSY_ON]()
      adsetprotoService.load(id)
        .then((data) => {
          this[mutationTypes.BUSY_OFF]()
          this.title = 'Update adset'
          let totalAdCount = _.get(data, 'stats.ads.total')
          this.data = merge.recursive(getInitData(), data)
          this.data.externalCampaignManagement = this.client.externalCampaignManagement
          this.bufferUploadEndpoint = `${API_URL}/adsetprotos/${data.id}/uploadBuffer`
        })
        .catch((err) => {
          this[mutationTypes.BUSY_OFF]()
          this.error = err
        })
    },
    loadDataSourcesForClient () {
      debug('Loading data sources for client %s', this.client.accountId)
      this[mutationTypes.BUSY_ON]()
      return Promise.all([
        this.loadPages(this.client.agent),
        this.loadSavedAudiences(this.client.accountId),
        this.loadAdsPixels(this.client.accountId),
        this.loadMetricLists(this.client.accountId)
      ])
      .tap(() => {
        debug('Data sources loaded for client %s', this.client.accountId)
        this[mutationTypes.BUSY_OFF]()
      })
      .catch((err) => {
        this.error = err
        this[mutationTypes.BUSY_OFF]()
      })
    },
    loadPages (agentUserId) {
      return userService.listPages(this.client.agent)
        .then((pageResult) => {
          this.pages = pageResult
        })
    },
    loadSavedAudiences (accountId) {
      debug('Loading saved audiences for account %s', accountId)
      return userService.listSavedAudiences(this.client.agent, accountId)
        .then((savedAudiences) => {
          debug('Saved audiences loaded for account %s', accountId)
          this.savedAudiences = savedAudiences.map((savedAudience) => ({
            id: savedAudience.id,
            name: savedAudience.name,
            approximate_count: savedAudience.approximate_count
          }))
        })
    },
    loadAdsPixels (accountId) {
      debug('Loading ad pixels for account %s', accountId)
      return userService.listAdsPixels(this.client.agent, accountId)
        .then((adsPixels) => {
          debug('Ad pixels loaded for account %s', accountId)
          this.adsPixels = adsPixels.map((adsPixel) => ({
            id: adsPixel.id,
            name: adsPixel.name
          }))
        })
    },
    loadMetricLists (accountId) {
      debug('Loading metric lists for account %s', accountId)

      if (this.marketingGoal === CONSTANTS.MARKETING_GOALS.CONVERSIONS) {
        return userService.listCustomConversions(this.client.agent, accountId)
          .then((customConversions) => {
            debug('Custom conversion loaded for account %s', accountId)
            this.actionCostMetricList = customConversions.map((customConversion) => ({
              value: getCustomConversationActionCostMetricName(customConversion.id),
              name: customConversion.name,
              description: customConversion.description,
              custom_event_type: customConversion.custom_event_type
            }))
            this.resultMetricList = customConversions.map((customConversion) => ({
              value: getCustomConversationResultMetricName(customConversion.id),
              name: customConversion.name,
              description: customConversion.description,
              custom_event_type: customConversion.custom_event_type
            }))
          })
      } else {
        this.actionCostMetricList = [{
          value: 'auto',
          name: 'AUTO',
          description: '',
          custom_event_type: ''
        }]
        this.resultMetricList = [{
          value: 'auto',
          name: 'AUTO',
          description: '',
          custom_event_type: ''
        }]
      }

    },
    async loadAdTemplateDetails (templateAdFbId) {
      let agentId = this.client.agent

      if (!this.data.id) {
        this.data.externalNameTpl = null
      }
      this.data.pages = []

      this[mutationTypes.BUSY_ON]()

      debug('Loading FB template ad data %s', templateAdFbId)

      try {
        let adTemplateDetails = await userService.readAdTemplateDetails(agentId, templateAdFbId)
        debug('Ad template details loaded for owner %s', agentId)
        if (!this.data.externalNameTpl) {
          this.data.externalNameTpl = adTemplateDetails.adset.name
        }
        this.data.pages = [...adTemplateDetails.pages]
      } catch (error) {
        this.error = new Error('Cannot read ad template details')
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },

    reset () {
    },
    async save (formData) {
      this[mutationTypes.BUSY_ON]()

      try {
        await adsetprotoService.save(this.data.id, merge(true, this.data, {client: this.client.id}))
        this.back()
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },
    remove () {
      this.confirm('Delete', 'Are you sure?').then((confirmed) => {
        if (confirmed) {
          this[mutationTypes.BUSY_ON]()
          adsetprotoService.remove(this.data.id).then((result) => {
            this[mutationTypes.BUSY_OFF]()
            this.back()
          }).catch((err) => {
            this[mutationTypes.BUSY_OFF]()
            this.error = err
          })
        }
      })
    },
    // download () {
    //   if (this.data.id) {
    //     adsetprotoService.download(this.data.id)
    //   }
    // },
    async openAddFbPostDialog () {
      this.$refs.fbPostDialog.show(async (formData) => {
        let bufferItemData = merge(true, formData, {creativeType: 'link'})
        return adsetprotoService.addBufferItem(this.data.id, bufferItemData)
      })
    },
    showBufferDialog () {
      this.$refs.bufferDialog.show(this.client.id, (partnerId, clientId) => {
        // adsetprotoService.moveTo(item.id, clientId)
        //   .then(() => {
        //     this.onGridRefresh()
        //   })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .danger-border {
    border-left: 5px solid red;
  }
</style>
