<template>
  <div class="alert alert-success alert-dismissible fade d-none" role="info-information">
    <span v-html="safeMessage"></span>
    <button type="button" class="close" @click="hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
import _ from 'lodash'
import $ from 'jquery'
import SafeString from '../utils/SafeString'

export default {
  name: 'info-callout',
  props: ['message'],
  data () {
    return {
    }
  },
  computed: {
    safeMessage () {
      let value = this.message
      return value instanceof SafeString ? value.toString() : _.escape(value)
    }
  },
  watch: {
    message: 'onMessageChanged'
  },
  methods: {
    onMessageChanged (info) {
      if (info) {
        this.show()
      } else {
        this.hide()
      }
    },
    show: function () {
      $(this.$el).removeClass('d-none')
      $(this.$el).addClass('show')
    },
    hide: function () {
      $(this.$el).removeClass('show')
      $(this.$el).addClass('d-none')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '../../styles/main';
</style>
