<template>
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
      <img src="../../assets/logo-small.jpg" />
      <div class="sidebar-brand-text mx-3">AdFlow</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <!-- <li class="nav-item active">
      <a class="nav-link" href="index.html">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>Dashboard</span></a>
    </li> -->

    <!-- Divider -->
    <!-- <hr class="sidebar-divider"> -->

    <!-- Heading -->
    <!-- <div class="sidebar-heading">
      Interface
    </div> -->

    <!-- Nav Item - Pages Collapse Menu -->
    <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Components</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Custom Components:</h6>
          <a class="collapse-item" href="buttons.html">Buttons</a>
          <a class="collapse-item" href="cards.html">Cards</a>
        </div>
      </div>
    </li> -->

    <!-- Nav Item - Utilities Collapse Menu -->
    <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
        <i class="fas fa-fw fa-wrench"></i>
        <span>Utilities</span>
      </a>
      <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Custom Utilities:</h6>
          <a class="collapse-item" href="utilities-color.html">Colors</a>
          <a class="collapse-item" href="utilities-border.html">Borders</a>
          <a class="collapse-item" href="utilities-animation.html">Animations</a>
          <a class="collapse-item" href="utilities-other.html">Other</a>
        </div>
      </div>
    </li>
 -->
    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      Collections
    </div>

    <!-- Nav Item - Pages Collapse Menu -->
    <!-- <li class="nav-item">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages" aria-expanded="true" aria-controls="collapsePages">
        <i class="fas fa-fw fa-folder"></i>
        <span>Pages</span>
      </a>
      <div id="collapsePages" class="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
        <div class="bg-white py-2 collapse-inner rounded">
          <h6 class="collapse-header">Login Screens:</h6>
          <a class="collapse-item" href="login.html">Login</a>
          <a class="collapse-item" href="register.html">Register</a>
          <a class="collapse-item" href="forgot-password.html">Forgot Password</a>
          <div class="collapse-divider"></div>
          <h6 class="collapse-header">Other Pages:</h6>
          <a class="collapse-item" href="404.html">404 Page</a>
          <a class="collapse-item" href="blank.html">Blank Page</a>
        </div>
      </div>
    </li> -->

    <!-- Nav Item - Partners -->
    <li class="nav-item" v-bind:class="{ active: isActive('/partners/?') }">
      <router-link :to="{name: 'partners'}" class="nav-link">
        <i class="fas fa-fw fa-user-circle"></i>
        <span>Partners</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: isActive('/insights/?') }">
      <router-link :to="{name: 'insights'}" class="nav-link">
        <i class="fas fa-fw fa-chart-line"></i>
        <span>Insights</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: isActive('/users/?') }">
      <router-link :to="{name: 'users'}" class="nav-link">
        <i class="fas fa-fw fa-users"></i>
        <span>Users</span>
      </router-link>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider">

    <!-- Heading -->
    <div class="sidebar-heading">
      Admin
    </div>

    <li class="nav-item" v-bind:class="{ active: isActive('/system-analytics/?') }">
      <router-link :to="{name: 'system-analytics'}" class="nav-link">
        <i class="fas fa-fw fa-chart-bar"></i>
        <span>System analytics</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: isActive('/settings/?') }">
      <router-link :to="{name: 'settings'}" class="nav-link">
        <i class="fas fa-fw fa-cog"></i>
        <span>Settings</span>
      </router-link>
    </li>

    <li class="nav-item" v-bind:class="{ active: isActive('/tools/?') }">
      <router-link :to="{name: 'tools'}" class="nav-link">
        <i class="fas fa-fw fa-tools"></i>
        <span>Tools</span>
      </router-link>
    </li>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block">

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle" @click="toggleSidebar()"></button>
    </div>

  </ul>
</template>

<script>
/* global */

import $ from 'jquery'

export default {
  name: 'partials-sidebar',
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    isActive: function (path) {
      return this.$route.path.match(path)
    },
    toggleSidebar () {
      $("body").toggleClass("sidebar-toggled")
      $(".sidebar").toggleClass("toggled")
      if ($(".sidebar").hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
