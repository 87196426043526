<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import _ from 'lodash'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'

export default {
  name: 'chart-time-series',
  components: {
    highcharts: Chart
  },
  props: {
    title: String,
    subtitle: String,
    tooltipFormatter: Function,
    type: {
      type: String,
      default: 'spline'
    },
    pointsInTime: {
      type: Array,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    height: {
      type: Number,
      default: 400
    },
    colors: {
      type: Array
    },
  },
  data () {
    return {
    }
  },
  computed: {
    chartOptions () {
      let chartTimeFormat = 'MM.DD HH:mm'
      // let categories = this.pointsInTime.map(momentTime => momentTime.format(chartTimeFormat))
      let categories = this.pointsInTime.map(momentTime => momentTime.valueOf())
      let tooltipFormatter = this.tooltipFormatter || function () {
        return '<b>' + this.series.name + '</b>: ' + Highcharts.numberFormat(this.y, 2, '.', ' ')
      }

      return {
        colors: this.colors || ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
        chart: {
          type: this.type,
          zoomType: 'x',
          height: this.height,
          borderWidth: 1,
          borderColor: '#AAA'
        },
        title: {
          text: this.title,
          align: 'center'
        },
        subtitle: {
          text: this.subtitle,
          align: 'center'
        },
        tooltip: {
          formatter: tooltipFormatter
        },
        xAxis: {
          type: 'datetime',
          labels: {
            format: '{value:%H:%M}',
          },
          // tickInterval: 50,
          categories
        },
        yAxis: {
          title: {
            text: ''
          },
          plotLines: [{
            value: 0,
            width: 1,
            color: '#808080'
          }]
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
          borderWidth: 0,
          enabled: this.showLegend
        },
        series: this.series
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '../../styles/main';
</style>
