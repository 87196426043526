var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-sm-2 mt-4 text-center" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary trans-uppercase",
            attrs: { href: "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goBack($event)
              }
            }
          },
          [_vm._v("Back")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c(
        "div",
        { staticClass: "col-sm-2 mt-4 text-center" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn btn-secondary trans-uppercase",
              attrs: { to: "/" }
            },
            [_vm._v("Home")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 mt-4  small-centered text-center" }, [
        _c("h3", [_vm._v("Page not found")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }