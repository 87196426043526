
<template>
  <nav aria-label="You are here:" role="navigation" v-if="$breadcrumbs.length">
    <ul class="breadcrumbs">
      <li v-for="(crumb, idx) in $breadcrumbs">
        <span v-if="idx == $breadcrumbs.length - 1"><span class="show-for-sr">Current: </span> {{getItemText(crumb)}}</span>
        <router-link :to="crumb.path" v-if="idx < $breadcrumbs.length - 1">{{getItemText(crumb)}}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'partials-breadcrumbs',
  data () {
    return {
    }
  },
  methods: {
    getItemText: function (crumb) {
      if (typeof crumb.meta.breadcrumb === 'function') {
        return crumb.meta.breadcrumb.call(crumb, this.$route)
      }
      return crumb.meta.breadcrumb
    }
  }
}
</script>

<style lang="scss" scoped>
  // @import '../../sass/foundation-import';
</style>
