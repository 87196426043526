<template>
  <div class="card shadow mb-4">
    <error-reveal :error="error"></error-reveal>
    <div class="card-header py-3">
      <a @click.prevent="back()" href="#" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</a>
      <h6 class="m-0 font-weight-bold text-primary">Insights <i class="fas fa-fw fa-angle-double-right"></i> {{title}}</h6>
    </div>
    <div class="card-body">
      <div class="form-row" v-if="results">
        <div class="form-group col-md-8">
          <h5>Partner: {{fmtPartnerInfo(results.partner)}}</h5>
          <h6>Client: {{fmtClientInfo(results.client)}}</h6>
          <h6>Adset: {{fmtAdsetProtoInfo(results.adsetProto)}}</h6>
        </div>
        <div class="form-group col-md-4 text-right">
          <label class="text-info">{{fmtFrom}} - {{fmtTo}}</label>
        </div>
      </div>

      <div class="row" v-if="results">
        <div class="col-md-12">
          <div v-if="!results.marketingGoals">No available campaigns for the selected options.</div>
          <div class="mb-4" v-for="marketingGoalResults of results.marketingGoals" v-bind:key="marketingGoalResults.name">
            <h6 class="bg-primary text-white p-2 mb-0 rounded-top">{{marketingGoalResults.title}}</h6>
            <table class="table table-bordered border-primary small" v-if="marketingGoalResults.collection">
              <thead>
                <tr>
                  <th scope="col" class="border-primary">Campaign</th>
                  <th scope="col" class="border-primary text-right" style="width:130px;">Results</th>
                  <th scope="col" class="border-primary text-right" style="width:150px;">Avg. cost per action</th>
                  <th scope="col" class="border-primary text-right" style="width:130px;">Spend</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="campaign of marketingGoalResults.collection" v-bind:key="campaign.id">
                  <td class="border-primary">{{campaign.name}} ({{formatDateInterval(campaign.interval)}}) <i class="text-primary cursor-pointer fas fa-fw fa-external-link-square-alt" @click="openCampaignEdit(campaign.partner, campaign.client, campaign.id)"></i></td>
                  <td class="border-primary text-right">{{formatSum(campaign.results, 0)}}</td>
                  <td class="border-primary text-right">{{formatMoneyAmount(calcAvgCostPerAction(campaign), 2)}} <span class="small">{{campaign.currency}}</span></td>
                  <td class="border-primary text-right">{{formatMoneyAmount(campaign.spend, 2)}} <span class="small">{{campaign.currency}}</span></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th scope="col" class="border-primary">SUM</th>
                  <th scope="col" class="border-primary text-right" style="width:130px;">
                    <p class="m-0">-</p>
                  </th>
                  <th scope="col" class="border-primary text-right" style="width:130px;">
                    <p class="m-0">-</p>
                  </th>
                  <th scope="col" class="border-primary text-right" style="width:130px;">
                    <p v-for="(summary, currency) in marketingGoalResults.summary.byCurrencies" v-bind:key="currency" class="m-0">{{formatMoneyAmount(summary.spend, 2)}} <span class="small">{{currency}}</span></p>
                  </th>
                </tr>
              </tfoot>
            </table>
            <div v-if="!marketingGoalResults.collection" class="text-muted small p-2 border border-primary">No results.</div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="card-footer text-muted">
      <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
    </div> -->
  </div>
</template>

<script>

import { mapMutations } from 'vuex'
import moment from 'moment'
import 'moment-timezone'
import merge from 'merge'
import _ from 'lodash'
import * as mutationTypes from '../../store/mutation-types'
import SafeString from '../../utils/SafeString'
import ErrorReveal from '../../errors/Reveal'
import insightsService from '../../services/insights'
import i18nService from '../../services/i18n'

function floatVal (value) {
  let parsed = parseFloat(value)
  return parsed || 0
}

function getDefaultSummary () {
  return {
    spent: 0,
    uniqueInlineLinkClicks: 0
  }
}

export default {
  name: 'insights-display',
  components: {
    ErrorReveal
  },
  data () {
    return {
      fmtFrom: null,
      fmtTo: null,
      error: null,
      loadParams: null,
      results: null,
      title: 'Results'
    }
  },
  created: function () {
    this.queryParams = this.$route.query
    this.load(this.$route.query)
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF, mutationTypes.SET_HEADER_ACTIONS]),

    back () {
      this.$router.push({name: 'insights-query', query: this.queryParams})
    },

    async load (params) {
      this.reset()
      this.lastLoadParams = merge(true, params)
      this.loadParams = {
        interval: {
          from: params.from,
          to: params.to
        }
      }
      this.loadParams.partnerId = params.partnerId || null
      this.loadParams.clientId = params.clientId || null
      this.loadParams.adsetProtoId = params.adsetProtoId || null

      this[mutationTypes.BUSY_ON]()
      try {
        this.results = await insightsService.querySpendInsights(this.loadParams)
        this.calcCurrencySums()
        this.fmtFrom = moment(this.loadParams.interval.from).tz('Europe/Budapest').format('YYYY.MM.DD')
        this.fmtTo = moment(this.loadParams.interval.to).tz('Europe/Budapest').format('YYYY.MM.DD')
      } catch (error) {
        this.error = error
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },

    reset () {
    },

    calcCurrencySums () {
      if (this.results.marketingGoals) {
        for (let marketingGoal of this.results.marketingGoals) {
          if (marketingGoal.collection && marketingGoal.collection.length) {
            marketingGoal.summary = {
              byCurrencies: {}
            }
            for (let campaign of marketingGoal.collection) {
              let currency = campaign.currency
              if (!marketingGoal.summary.byCurrencies[currency]) {
                marketingGoal.summary.byCurrencies[currency] = {spend: 0}
              }

              marketingGoal.summary.byCurrencies[currency].spend += campaign.spend
            }
          }
        }
      }
    },

    calcAvgCostPerAction (campaign) {
      let results = campaign.results
      return !results ? 0 : campaign.spend / results
    },

    formatMoneyAmount (sum, precision) {
      return i18nService.formatMoneyAmount(sum, precision)
    },

    formatSum (sum, precision) {
      return i18nService.formatSum(sum, precision)
    },

    formatSum (sum, precision) {
      return i18nService.formatSum(sum, precision)
    },

    formatDateInterval (interval) {
      return i18nService.formatDateInterval(interval)
    },

    fmtPartnerInfo (partner) {
      return partner ? partner.name : 'All'
    },

    fmtClientInfo (client) {
      return client ? client.name : 'All'
    },

    fmtAdsetProtoInfo (adsetProto) {
      return adsetProto ? adsetProto.name : 'All'
    },

    openCampaignEdit (partnerId, clientId, adsetId) {
      this.$router.push({name: 'client-campaign-edit', params: {clientId: clientId, id: adsetId}})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
