'use strict'
const Joi = require('./joi')
const _ = require('lodash')

const MAX_LIMIT = 101

// const CURRENCY_TABLE = {
//   HUF: {
//     minBudget: 300
//   },
//   USD: {
//     minBudget: 1
//   },
//   EUR: {
//     minBudget: 1
//   }
// }

const CONST = require('../../../constants')
const MARKETING_GOALS = _.keys(CONST.MARKETING_GOALS)

const AdManagementPolicy = Joi.object().keys({
  method: Joi.string().required().valid(['boost', 'place']),
  placementType: Joi.string().required().valid(['predefined', 'automatic']),
  savedAudienceId: Joi.string().trim().optional().allow('', null),
  adsPixelId: Joi.string().trim().optional().allow('', null),
  urlTags: Joi.string().trim().optional().allow('', null),
  // autoStopThreshold: Joi.number().required().min(0),
  maxAvgActionCost: Joi.number().required().min(0),
  resumeAvgActionCost: Joi.number().min(0).max(Joi.ref('maxAvgActionCost')).when('autoResumeAdsEnabled', {is: true, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  freerunThreshold: Joi.number().required(),
  adMaxLifetimeInHour: Joi.number().optional().allow('', null),
  promotedUrl: Joi.string().trim().optional().allow('', null),
  numberOfMaxRunningAd: Joi.number().integer().min(0).allow('', null),
  numberOfMaxAdPerAdset: Joi.number().integer().min(1).max(45).allow('', null),
  maxUniqueInlineLinkClicks: Joi.number().integer().min(1).allow('', null),
  newAdsEnabled: Joi.boolean().required(),
  autoStopAdsEnabled: Joi.boolean().required(),
  autoResumeAdsEnabled: Joi.boolean().required(),
  actionCostMetric: Joi.string().trim().required(),
  resultMetric: Joi.string().trim().required()
})

const Partner = Joi.object().keys({
  name: Joi.string().trim().required(),
  contactName: Joi.string().trim().allow('', null),
  contactPhone: Joi.string().trim().allow('', null)
})

const PartnerAgent = Joi.object().keys({
  name: Joi.string().trim().required(),
  accessToken: Joi.string().trim().required()
})

const ClientScheduling = Joi.object().keys({
  type: Joi.string().trim().required().valid(['static', 'periodic']),
  static: Joi.object(),
  periodic: Joi.object()
})

const Client = Joi.object().keys({
  agent: Joi.string().trim().required(),
  name: Joi.string().trim().required(),
  externalCampaignManagement: Joi.boolean().required(),
  externalCampaignId: Joi.string().trim().when('externalCampaignManagement', {is: true, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  externalNameTpl: Joi.string().trim().when('externalCampaignManagement', {is: false, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  periodBudget: Joi.number().integer().min(0).when('externalCampaignManagement', {is: false, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  dailyBudget: Joi.number().integer().min(0).when('externalCampaignManagement', {is: false, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  accountId: Joi.string().trim().required(),
  marketingGoal: Joi.string().trim().required().valid(MARKETING_GOALS),
  scheduling: ClientScheduling.when('externalCampaignManagement', {is: false, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  trackCampaign: Joi.boolean().when('externalCampaignManagement', {is: true, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  isPolitical: Joi.boolean(),
  notifOnStoppingAds: Joi.boolean(),
  notifOnStoppingAdsRecepients: Joi.string().trim().optional().allow('', null)
})

const AdSetProto = Joi.object().keys({
  name: Joi.string().trim().required(),
  dsa_payor: Joi.string().trim().optional().allow('', null),
  dsa_beneficiary: Joi.string().trim().optional().allow('', null),
  externalCampaignManagement: Joi.boolean().required(),
  externalNameTpl: Joi.string().trim().optional().allow('', null),
  customPlaceholders: Joi.string().trim().optional().allow('', null),
  templateAdFbId: Joi.string().trim().when('externalCampaignManagement', {is: true, then: Joi.required(), otherwise: Joi.optional().allow('', null)}),
  pages: Joi.array().items(
    Joi.object({
      id: Joi.string().trim().required(),
      name: Joi.string().trim()
    })
  ).min(1).required(),
  adManagementPolicy: AdManagementPolicy
})

var schemas = {
  'auth.login': Joi.object().keys({
    email: Joi.string().trim().email().required(),
    password: Joi.string().trim().required()
  }),
  'partner.get': Joi.object().keys({
    id: Joi.string().trim().required()
  }),
  'partner.list': Joi.object().keys({
    q: Joi.string().trim(),
    offset: Joi.number().integer().min(0),
    limit: Joi.number().integer().min(1).max(MAX_LIMIT)
  }),
  'partner.create': Partner,
  'partner.update': Partner.keys({
    id: Joi.string().trim().required()
  }),
  'partner.edit': Partner,
  'partner.agent.create': PartnerAgent,
  'partner.agent.update': PartnerAgent.keys({
    accessToken: Joi.string().trim().optional().allow('', null)
  }),
  'client.edit': Client,
  'adsetproto.get': Joi.object().keys({
    id: Joi.string().trim().required()
  }),
  'adsetproto.download': Joi.object().keys({
    id: Joi.string().trim().required()
  }),
  'adsetproto.list': Joi.object().keys({
    q: Joi.string().trim(),
    offset: Joi.number().integer().min(0),
    limit: Joi.number().integer().min(1).max(MAX_LIMIT)
  }),
  'adsetproto.create': AdSetProto,
  'adsetproto.update': AdSetProto.keys({
    id: Joi.string().trim().required()
  }),
  'adsetproto.edit': AdSetProto,
  'adsetproto.delete': Joi.object().keys({
    id: Joi.string().trim().required()
  }),
  // 'adsetproto.uploadContents': Joi.object().keys({
  //   id: Joi.string().trim().required(),
  //   originalname: Joi.string().required(),
  //   destination: Joi.string().required(),
  //   filename: Joi.string().required(),
  //   path: Joi.string().required(),
  //   size: Joi.number().integer().min(1).required()
  //   // mimetype: Joi.string().valid(CV_MIME_TYPES).required()
  // }),
  'adsetproto.source.edit': Joi.object().keys({
    type: Joi.string().trim().required().valid([CONST.CAMPAIGNSOURCE.FEED, CONST.CAMPAIGNSOURCE.PAGEPOSTS]),
    params: Joi.alternatives()
    .when(
      'type', {
        is: CONST.CAMPAIGNSOURCE.FEED,
        then: Joi.object().keys({
          url: Joi.string().trim().required()
        })
      }
    )
    .when(
      'type', {
        is: CONST.CAMPAIGNSOURCE.FILE,
        then: Joi.object().keys({
          url: Joi.string().trim().required()
        })
      }
    )
    .when(
      'type', {
        is: CONST.CAMPAIGNSOURCE.PAGEPOSTS,
        then: Joi.object().keys({
          pages: Joi.array().min(1).items(
            Joi.object({
              id: Joi.string().trim().required(),
              name: Joi.string().trim()
            })
          ),
          maxNumOfItems: Joi.number().integer().min(1),
          postTypes: Joi.array().min(1).items(
            Joi.string().trim()
          ),
          strategy: Joi.string().trim(),
          thresholds: Joi.object().keys({
            spanHours: Joi.number().integer().min(0).max(72).allow('', null),
            like: Joi.number().integer().min(0).allow('', null),
            share: Joi.number().integer().min(0).allow('', null),
            comment: Joi.number().integer().min(0).allow('', null),
            reach: Joi.number().integer().min(0).allow('', null),
            click: Joi.number().integer().min(0).allow('', null)
          }),
          top: Joi.object().keys({
            numOf: Joi.number().integer().min(1).allow('', null),
            orderBy: Joi.string().trim().allow('', null),
            spanHours: Joi.number().integer().min(0).max(72).allow('', null),
            referTime: Joi.string().trim().allow('', null)
          }),
          linkType: Joi.string().trim(),
          contentsType: Joi.string().trim(),
          urlParameters: Joi.string().trim().optional().allow('', null),
          filters: Joi.object()
        })
      }
    )
  }),
  'adaccount.list': Joi.object().keys({
    q: Joi.string().trim(),
    offset: Joi.number().integer().min(0),
    limit: Joi.number().integer().min(1).max(MAX_LIMIT),
    currency: Joi.string().trim()
  }),
  'page.list': Joi.object().keys({
    q: Joi.string().trim(),
    offset: Joi.number().integer().min(0),
    limit: Joi.number().integer().min(1).max(MAX_LIMIT),
    moderated: Joi.boolean().default(true)
  }),
  'insights.query': Joi.object().keys({
    partnerId: Joi.string().trim().required(),
    clientId: Joi.string().trim().allow('', null),
    campaignId: Joi.string().trim().allow('', null),
    interval: Joi.object().required().keys({
      from: Joi.date().required(),
      to: Joi.date().required()
    })
  }),
  'insights.page.list': Joi.object().keys({
    ids: Joi.array().min(1)
  }),
  'insights.ad.list': Joi.object().keys({
    accountId: Joi.string().trim(),
    pages: Joi.array().min(1),
    interval: Joi.object().required().keys({
      from: Joi.date().required(),
      to: Joi.date().required()
    })
  }),
  'insights.ad.insights': Joi.object().keys({
    adId: Joi.string().trim()
  }),
  'savedAudiences.list': Joi.object().keys({
    accountId: Joi.string().trim().required()
  }),
  'fbPixels.list': Joi.object().keys({
    accountId: Joi.string().trim().required()
  }),
  'user.list': Joi.object().keys({
    offset: Joi.number().integer().min(0),
    limit: Joi.number().integer().min(1).max(MAX_LIMIT)
  }),
  'user.delete': Joi.object().keys({
    id: Joi.string().trim().required()
  }),
  'user.toggleAccess': Joi.object().keys({
    id: Joi.string().trim().required()
  }),
  'bufferFbPost.edit': Joi.object().keys({
    fbPostUrl: Joi.string().trim().required(),
    title: Joi.string().trim().required()
  }),
  'ad.stopOrRestart': Joi.object().keys({
    fbAdId: Joi.string().trim().required()
  })
}

module.exports = schemas
