const _ = require('lodash')

module.exports = exports = function (Joi) {
  return {
    base: Joi.number(),
    name: 'number',
    language: {
      invalidValue: '!!Invalid value',
      invalidMinBudgetParameter: '!!Invalid minimum budget parameter',
      unsupportedCurrency: '!!Unsupported currency'
    },
    rules: [
      {
        name: 'minBudgetByCurrency',
        params: {
          currencyTable: Joi.object().required()
        },
        validate (params, value, state, options) {
          let currencyTable = params.currencyTable
          let currency = _.get(state, 'parent.currency')
          let currencyItem = _.get(currencyTable, currency)
          let minBudget = parseFloat(_.get(currencyItem, 'minBudget'))
          value = parseFloat(value)
          if (!currencyItem) {
            return this.createError('number.unsupportedCurrency', {
              v: value
            }, state, options)
          }

          if (isNaN(minBudget)) {
            return this.createError('number.invalidMinBudgetParameter', {
              v: value
            }, state, options)
          }

          if (isNaN(value)) {
            return this.createError('number.invalidValue', {
              v: value
            }, state, options)
          }

          if (value < minBudget) {
            return this.createError('number.min', {
              limit: minBudget, value
            }, state, options)
          }

          return value
        }
      }
    ]
  }
}
