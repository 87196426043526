<template>
  <div>
    <error-reveal :error="error"></error-reveal>
    <agent-edit ref="editDialog" :partner="partner" @saved="onAgentSaved()"></agent-edit>
    <data-grid
      :items="items"
      :columns="columns"
      emptytext="No agents."
      additemtext="Add agent"
      refreshtext="Refresh"
      @additem="onAddNewItem"
      @itemaction="onItemAction"
      @refresh="onGridRefresh"
    ></data-grid>
    <data-store
      ref="store"
      :service="service"
      :filter="filter"
      :sort="sort"
      @beforeload="onBeforeLoad"
      @afterload="onAfterLoad"
      @dataloaded="onDataLoaded"
      @loaderror="onLoadError"
    ></data-store>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../../store/mutation-types'
import DataGrid from '../../../lists/DataGrid'
import DataStore from '../../../data/Store'
import ErrorReveal from '../../../errors/Reveal'
import AgentEdit from './AgentEdit'
import i18nService from '../../../services/i18n'
import partnerService from '../../../services/partner'
export default {
  name: 'agents',
  components: {
    DataGrid,
    DataStore,
    AgentEdit,
    ErrorReveal
  },
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [],
      columns: [{
        title: 'Name',
        name: 'name',
        valign: 'top'
      }, {
        title: 'Created at',
        name: 'createdAt',
        align: 'left',
        width: 200
      }, {
        title: 'Token updated at',
        name: 'tokenUpdatedAt',
        align: 'left',
        width: 200
      }, {
        actions: [{
          name: 'edit',
          html: '<i class="fas fa-sm fa-edit"></i>'
        }],
        width: 40,
        align: 'center'
      }],
      service: null,
      filter: null,
      sort: null,
      error: null
    }
  },
  created () {
    this.service = () => {
      return partnerService.listAgents(this.partner.id, {})
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onAddNewItem () {
      this.$refs.editDialog.show()
    },
    onBeforeLoad () {
      this[mutationTypes.BUSY_ON]()
    },
    onAfterLoad () {
      this[mutationTypes.BUSY_OFF]()
    },
    onDataLoaded (items, result) {
      this.items = items.map(this.parseItem)
    },
    onLoadError (error) {
      this[mutationTypes.BUSY_OFF]()
      this.error = error
    },
    onItemAction (action, item) {
      if (action.name === 'edit') {
        this.$refs.editDialog.show(item.id)
      }
    },
    onGridRefresh () {
      this.$refs.store.load()
    },
    onDownloadPerformance (item) {
      partnerService.download(item.id)
    },
    parseItem (rawData) {
      return {
        id: rawData.id,
        name: rawData.name,
        createdAt: i18nService.formatDateTime(rawData.createdAt, i18nService.FMT_SHORT, i18nService.FMT_SHORT),
        tokenUpdatedAt: i18nService.formatDateTime(rawData.tokenUpdatedAt, i18nService.FMT_SHORT, i18nService.FMT_SHORT)
      }
    },
    precisionRound (number, precision) {
      var factor = Math.pow(10, precision)
      return Math.round(number * factor) / factor
    },
    renderAds (rawData) {
      var ads = rawData.stats.ads
      return `${ads.total} / ${ads.active}`
    },
    renderBuffer (rawData) {
      var buffer = rawData.stats.buffer
      return buffer ? `${buffer.total} / ${buffer.processed}` : '-'
    },
    onAgentSaved () {
      this.onGridRefresh()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
