var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card", on: { click: _vm.onClick } }, [
    _c("header", [
      _vm.data.headerImage
        ? _c("div", { staticClass: "image" }, [
            _c("img", { attrs: { src: _vm.data.headerImage, alt: "" } })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text", class: { "with-image": _vm.data.headerImage } },
        [
          _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.data.title))]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.data.description))
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _vm.data.image
      ? _c("div", { staticClass: "body" }, [
          _c("img", {
            attrs: { src: _vm.data.image, alt: "" },
            on: { click: _vm.onImageClick }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "footer",
      _vm._l(_vm.actions, function(action) {
        return _c(
          "button",
          {
            staticClass: "button",
            class: action.class,
            on: {
              click: function($event) {
                return _vm.onAction(action)
              }
            }
          },
          [_vm._v(_vm._s(action.text))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }