var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal fade", attrs: { id: _vm.domUid } }, [
    _c(
      "div",
      { staticClass: "modal-dialog modal-lg modal-dialog-scrollable" },
      [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-12" },
                [
                  _c("error-callout", { attrs: { error: _vm.error } }),
                  _vm._v(" "),
                  _c("info-callout", { attrs: { message: _vm.info } })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-12" }, [
                _c("div", {}, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "buffer-dialog-tabContent" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tab-pane fade show active p-3 border-left border-bottom border-right",
                          attrs: {
                            id: "nav-bufferdialog-items",
                            role: "tabpanel",
                            "aria-labelledby": "nav-bufferdialog-items-tab"
                          }
                        },
                        [
                          _c("buffer-item-list", {
                            attrs: { adsetprotoId: _vm.adsetprotoId }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tab-pane fade p-3 border-left border-bottom border-right",
                          attrs: {
                            id: "nav-bufferdialog-externalfeed",
                            role: "tabpanel",
                            "aria-labelledby":
                              "nav-bufferdialog-externalfeed-tab"
                          }
                        },
                        [
                          _c(
                            "joi-form",
                            {
                              attrs: {
                                data: _vm.data[_vm.sourceType.FEED],
                                schema: _vm.validationSchema
                              },
                              on: { formdataready: _vm.saveExternalFeedSource }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("label", [_vm._v("Input Feed URL:")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.data[_vm.sourceType.FEED].params
                                              .url,
                                          expression:
                                            "data[sourceType.FEED].params.url"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "params.url"
                                      },
                                      domProps: {
                                        value:
                                          _vm.data[_vm.sourceType.FEED].params
                                            .url
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data[_vm.sourceType.FEED]
                                              .params,
                                            "url",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary trans-uppercase",
                                        attrs: { type: "submit" }
                                      },
                                      [_vm._v("Save")]
                                    ),
                                    _vm._v(" "),
                                    _vm.data[_vm.sourceType.FEED].id
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger trans-uppercase float-right",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteSource(
                                                  _vm.sourceType.FEED,
                                                  _vm.data[_vm.sourceType.FEED]
                                                    .id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Delete")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tab-pane fade p-3 border-left border-bottom border-right",
                          attrs: {
                            id: "nav-bufferdialog-pageposts",
                            role: "tabpanel",
                            "aria-labelledby": "nav-bufferdialog-pageposts-tab"
                          }
                        },
                        [
                          _c(
                            "joi-form",
                            {
                              attrs: {
                                data: _vm.data[_vm.sourceType.PAGEPOSTS],
                                schema: _vm.validationSchema
                              },
                              on: { formdataready: _vm.savePagePostsSource }
                            },
                            [
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-8" },
                                  [
                                    _c("label", [_vm._v("Facebook pages:")]),
                                    _vm._v(" "),
                                    _c("page-input", {
                                      attrs: {
                                        pages: _vm.pages,
                                        "error-path": "params.pages"
                                      },
                                      model: {
                                        value:
                                          _vm.data[_vm.sourceType.PAGEPOSTS]
                                            .params.pages,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.data[_vm.sourceType.PAGEPOSTS]
                                              .params,
                                            "pages",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "data[sourceType.PAGEPOSTS].params.pages"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-4" },
                                  [
                                    _c("label", [
                                      _vm._v("Maximum num of items:")
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.data[_vm.sourceType.PAGEPOSTS]
                                              .params.maxNumOfItems,
                                          expression:
                                            "data[sourceType.PAGEPOSTS].params.maxNumOfItems"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        name: "params.maxNumOfItems",
                                        type: "number",
                                        min: "1",
                                        step: "1"
                                      },
                                      domProps: {
                                        value:
                                          _vm.data[_vm.sourceType.PAGEPOSTS]
                                            .params.maxNumOfItems
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data[_vm.sourceType.PAGEPOSTS]
                                              .params,
                                            "maxNumOfItems",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("label", [_vm._v("Post types:")]),
                                    _vm._v(" "),
                                    _vm._l(_vm.pagePostsTypes, function(
                                      postType
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          key: postType.name,
                                          staticClass:
                                            "form-check form-check-inline ml-2"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].params.postTypes,
                                                expression:
                                                  "data[sourceType.PAGEPOSTS].params.postTypes"
                                              }
                                            ],
                                            staticClass: "form-check-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: "cbPostType" + postType.name
                                            },
                                            domProps: {
                                              value: postType.name,
                                              checked: Array.isArray(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.postTypes
                                              )
                                                ? _vm._i(
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params.postTypes,
                                                    postType.name
                                                  ) > -1
                                                : _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].params.postTypes
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$a =
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params.postTypes,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = postType.name,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.data[
                                                          _vm.sourceType
                                                            .PAGEPOSTS
                                                        ].params,
                                                        "postTypes",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.data[
                                                          _vm.sourceType
                                                            .PAGEPOSTS
                                                        ].params,
                                                        "postTypes",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params,
                                                    "postTypes",
                                                    $$c
                                                  )
                                                }
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass: "form-check-label",
                                              attrs: {
                                                for:
                                                  "cbPostType" + postType.name
                                              }
                                            },
                                            [_vm._v(_vm._s(postType.title))]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      attrs: {
                                        "error-path": "params.postTypes"
                                      }
                                    })
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-3" },
                                  [
                                    _c("label", [_vm._v("Strategy:")]),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.strategy,
                                            expression:
                                              "data[sourceType.PAGEPOSTS].params.strategy"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { name: "params.strategy" },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params,
                                              "strategy",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("-")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "thresholds" } },
                                          [_vm._v("Thresholds")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "top" } },
                                          [_vm._v("Top posts")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _vm.data[_vm.sourceType.PAGEPOSTS].params
                                .strategy === "thresholds"
                                ? _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-3" },
                                      [
                                        _c("label", [_vm._v("Span:")]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params.thresholds
                                                      .spanHours,
                                                  expression:
                                                    "data[sourceType.PAGEPOSTS].params.thresholds.spanHours"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                min: "0",
                                                max: "72",
                                                step: "1",
                                                name:
                                                  "params.thresholds.spanHours"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].params.thresholds.spanHours
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params.thresholds,
                                                    "spanHours",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-append"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "input-group-text"
                                                  },
                                                  [_vm._v("hour(s)")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.data[_vm.sourceType.PAGEPOSTS].params
                                .strategy === "thresholds"
                                ? _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Like:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds.like,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.thresholds.like"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "params.thresholds.like",
                                            type: "number",
                                            min: "0",
                                            step: "1"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.thresholds.like
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds,
                                                "like",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Share:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds.share,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.thresholds.share"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "params.thresholds.share",
                                            type: "number",
                                            min: "0",
                                            step: "1"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.thresholds.share
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds,
                                                "share",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Comment:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds.comment,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.thresholds.comment"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "params.thresholds.comment",
                                            type: "number",
                                            min: "0",
                                            step: "1"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.thresholds.comment
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds,
                                                "comment",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Reach:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds.reach,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.thresholds.reach"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "params.thresholds.reach",
                                            type: "number",
                                            min: "0",
                                            step: "1"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.thresholds.reach
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds,
                                                "reach",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Click:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds.click,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.thresholds.click"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "params.thresholds.click",
                                            type: "number",
                                            min: "0",
                                            step: "1"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.thresholds.click
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.thresholds,
                                                "click",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.data[_vm.sourceType.PAGEPOSTS].params
                                .strategy === "top"
                                ? _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Top N:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.top.numOf,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.top.numOf"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            name: "params.top.numOf",
                                            type: "number",
                                            min: "1",
                                            step: "1"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.top.numOf
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.top,
                                                "numOf",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-3" },
                                      [
                                        _c("label", [_vm._v("Order by:")]),
                                        _vm._v(" "),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].params.top.orderBy,
                                                expression:
                                                  "data[sourceType.PAGEPOSTS].params.top.orderBy"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              name: "params.top.orderBy"
                                            },
                                            on: {
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].params.top,
                                                  "orderBy",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "" } },
                                              [_vm._v("-")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "like" } },
                                              [_vm._v("Like")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "share" } },
                                              [_vm._v("Share")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "comment" } },
                                              [_vm._v("Comment")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "reach" } },
                                              [_vm._v("Reach")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "click" } },
                                              [_vm._v("Click")]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-3" },
                                      [
                                        _c("label", [_vm._v("Span:")]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "input-group" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params.top.spanHours,
                                                  expression:
                                                    "data[sourceType.PAGEPOSTS].params.top.spanHours"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "number",
                                                min: "0",
                                                max: "72",
                                                step: "1",
                                                name: "params.top.spanHours"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].params.top.spanHours
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.data[
                                                      _vm.sourceType.PAGEPOSTS
                                                    ].params.top,
                                                    "spanHours",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group-append"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "input-group-text"
                                                  },
                                                  [_vm._v("hour(s)")]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-2" },
                                      [
                                        _c("label", [_vm._v("Time:")]),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.top.referTime,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.top.referTime"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "time",
                                            name: "params.top.referTime"
                                          },
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.top.referTime
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.top,
                                                "referTime",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c("label", [_vm._v("Link type:")]),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.linkType,
                                            expression:
                                              "data[sourceType.PAGEPOSTS].params.linkType"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { name: "params.linkType" },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params,
                                              "linkType",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("-")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "original" } },
                                          [_vm._v("Original links")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "facebook" } },
                                          [_vm._v("Facebook links")]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-6" },
                                  [
                                    _c("label", [_vm._v("Contents type:")]),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.contentsType,
                                            expression:
                                              "data[sourceType.PAGEPOSTS].params.contentsType"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { name: "params.contentsType" },
                                        on: {
                                          change: function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.$set(
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params,
                                              "contentsType",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("option", { attrs: { value: "" } }, [
                                          _vm._v("-")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "feed" } },
                                          [_vm._v("Feed")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          { attrs: { value: "remote" } },
                                          [_vm._v("Remote")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c("label", [_vm._v("URL parameters:")]),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.data[_vm.sourceType.PAGEPOSTS]
                                              .params.urlParameters,
                                          expression:
                                            "data[sourceType.PAGEPOSTS].params.urlParameters"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        name: "params.urlParameters"
                                      },
                                      domProps: {
                                        value:
                                          _vm.data[_vm.sourceType.PAGEPOSTS]
                                            .params.urlParameters
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.data[_vm.sourceType.PAGEPOSTS]
                                              .params,
                                            "urlParameters",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.pagePostsTextFilters, function(
                                filter
                              ) {
                                return _c(
                                  "div",
                                  { key: filter.name, staticClass: "form-row" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-6" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(filter.title) + " contains:"
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.filters[filter.name]
                                                  .contains,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.filters[filter.name].contains"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.filters[filter.name]
                                                .contains
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.filters[filter.name],
                                                "contains",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-6" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(filter.title) +
                                              " DOES NOT contain:"
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.filters[filter.name]
                                                  .notContains,
                                              expression:
                                                "data[sourceType.PAGEPOSTS].params.filters[filter.name].notContains"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          domProps: {
                                            value:
                                              _vm.data[_vm.sourceType.PAGEPOSTS]
                                                .params.filters[filter.name]
                                                .notContains
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.data[
                                                  _vm.sourceType.PAGEPOSTS
                                                ].params.filters[filter.name],
                                                "notContains",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-row" }, [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-md-12" },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-primary trans-uppercase",
                                        attrs: { type: "submit" }
                                      },
                                      [_vm._v("Save")]
                                    ),
                                    _vm._v(" "),
                                    _vm.data[_vm.sourceType.PAGEPOSTS].id
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-danger trans-uppercase float-right",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteSource(
                                                  _vm.sourceType.PAGEPOSTS,
                                                  _vm.data[
                                                    _vm.sourceType.PAGEPOSTS
                                                  ].id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Delete")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tab-pane fade p-3 border-left border-bottom border-right",
                          attrs: {
                            id: "nav-bufferdialog-uploadfile",
                            role: "tabpanel",
                            "aria-labelledby": "nav-bufferdialog-uploadfile-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "div",
                              { staticClass: "form-group col-md-12" },
                              [
                                _c("label", [_vm._v("Upload contents:")]),
                                _vm._v(" "),
                                _c("file-upload", {
                                  ref: "fileBufferContents",
                                  attrs: {
                                    name: "fileBufferContents",
                                    postAction: _vm.bufferUploadEndpoint,
                                    headers: _vm.fileUploadHeaders,
                                    multiple: "false"
                                  },
                                  on: {
                                    uploadclick: function($event) {
                                      return _vm.saveSource(_vm.sourceType.FILE)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "form-text text-info" },
                                  [
                                    _vm._v(
                                      "Accepted formats: UTF8 encoded CSV ("
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: _vm.sampleCSVUrl,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v("download sample")]
                                    ),
                                    _vm._v(").")
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Adset buffer")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("×")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", [
      _c(
        "div",
        {
          staticClass: "nav nav-tabs",
          attrs: { id: "buffer-dialog-tab", role: "tablist" }
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-item nav-link active",
              attrs: {
                id: "nav-bufferdialog-items-tab",
                "data-toggle": "tab",
                href: "#nav-bufferdialog-items",
                role: "tab",
                "aria-controls": "nav-bufferdialog-items",
                "aria-selected": "true"
              }
            },
            [_vm._v("Items")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-item nav-link",
              attrs: {
                id: "nav-bufferdialog-externalfeed-tab",
                "data-toggle": "tab",
                href: "#nav-bufferdialog-externalfeed",
                role: "tab",
                "aria-controls": "nav-bufferdialog-externalfeed",
                "aria-selected": "false"
              }
            },
            [_vm._v("External feed")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-item nav-link",
              attrs: {
                id: "nav-bufferdialog-pageposts-tab",
                "data-toggle": "tab",
                href: "#nav-bufferdialog-pageposts",
                role: "tab",
                "aria-controls": "nav-bufferdialog-pageposts",
                "aria-selected": "false"
              }
            },
            [_vm._v("Page posts")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "nav-item nav-link",
              attrs: {
                id: "nav-bufferdialog-uploadfile-tab",
                "data-toggle": "tab",
                href: "#nav-bufferdialog-uploadfile",
                role: "tab",
                "aria-controls": "nav-bufferdialog-uploadfile",
                "aria-selected": "false"
              }
            },
            [_vm._v("Upload file")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }