<template>
  <div id="app">
    <div class="busy-overlay" style="display: none">
      <img src="../assets/loader.gif" />
    </div>
    <component v-if="$layout" v-bind:is="$layout.component" v-bind="$layout.props"></component>
  </div>
</template>

<script>
/* global */
import { mapState, mapMutations } from 'vuex'
import * as mutationTypes from './store/mutation-types'
import $ from 'jquery'
// import wsService from './services/ws'
import fbService from './services/fb'

export default {
  name: 'app',
  props: {
    busyDelayMs: {
      type: Number,
      default: 200
    }
  },
  data () {
    return {
      showBusyOverlay: false
    }
  },
  computed: {
    ...mapState(['busy', 'serviceFbReady'])
  },
  watch: {
    busy: 'onBusyChanged'
  },
  created: function () {
    // wsService.connect()
    fbService.init().then(() => {
      this[mutationTypes.SERVICE_READY](fbService)
    })
  },
  mounted: function () {
  },
  methods: {
    ...mapMutations([mutationTypes.SERVICE_READY]),
    onBusyChanged (newVal) {
      if (newVal) {
        this.showBusy()
      } else {
        this.hideBusy()
      }
    },
    showBusy () {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(this.displayBusy.bind(this, true), this.busyDelayMs)
      }
    },
    hideBusy () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
      this.displayBusy(false)
    },
    displayBusy (showOverlay) {
      if (this.$el) {
        if (showOverlay) {
          $('.busy-overlay', this.$el).show()
          $('.busy-overlay', this.$el).attr('tabIndex', -1).focus()
        } else {
          $('.busy-overlay', this.$el).hide()
        }
      }
    }
  }
}
</script>

<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    position: absolute;

    .busy-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #888;
      filter:alpha(opacity=50);
      -moz-opacity:0.7;
      -khtml-opacity: 0.7;
      opacity: 0.7;
      z-index: 10000;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }
  }
</style>
