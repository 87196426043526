import store from '../store'

import LayoutPublic from '../layouts/LayoutPublic'
import LayoutSecured from '../layouts/LayoutSecured'
import LayoutError from '../layouts/LayoutError'

import PageNotFound from '../pages/PageNotFound'
import Welcome from '../pages/Welcome'
import Preregistered from '../pages/Preregistered'
import ViewDefaultIndex from '../pages/ViewDefaultIndex'
// import Dashboard from '../pages/Dashboard'

import PartnersList from '../pages/partners/List'
import PartnersEdit from '../pages/partners/Edit'
import PartnersSettings from '../pages/partners/Settings'
import PartnersAnalytics from '../pages/partners/Analytics'

import ClientsList from '../pages/clients/List'
import ClientsEdit from '../pages/clients/Edit'
import ClientsAnalytics from '../pages/clients/Analytics'
import ClientsCampaignAnalytics from '../pages/clients/CampaignAnalytics'

import AdSetProtosList from '../pages/adsetprotos/List'
import AdSetProtosEdit from '../pages/adsetprotos/Edit'
import AdSetProtosAnalytics from '../pages/adsetprotos/Analytics'
// import AdSetProtosAds from '../pages/adsetprotos/AdsPerformance'

import InsightsQuery from '../pages/insights/Query'
import InsightsDisplay from '../pages/insights/Display'

import SystemAnalyticsIndex from '../pages/systemAnalytics/Index'
import SettingsIndex from '../pages/settings/Index'
import ToolsIndex from '../pages/tools/Index'

import UsersList from '../pages/users/List'

export default [
  {
    path: '',
    redirect: to => {
      if (store.state.user) {
        return {name: 'dashboard'}
      }
      return {name: 'welcome'}
    }
  },
  {
    name: 'preregistered',
    path: '/preregistered',
    component: Preregistered,
    meta: {
      layout: LayoutPublic
    }
  },
  {
    name: 'welcome',
    path: '/welcome',
    component: Welcome,
    meta: {
      layout: LayoutPublic
    }
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    // component: Dashboard,
    meta: {
      layout: LayoutSecured
    },
    redirect: to => {
      return {name: 'partner-list'}
    }
  },
  {
    path: '/partners',
    component: ViewDefaultIndex,
    meta: {
      layout: LayoutSecured
    },
    children: [
      {
        name: 'partners',
        path: '',
        redirect: to => {
          return {name: 'partner-list'}
        }
      },
      {
        name: 'partner-list',
        path: 'list',
        component: PartnersList
      },
      {
        name: 'partner-edit',
        path: 'edit/:id?',
        component: PartnersEdit
      },
      {
        name: 'partner-settings',
        path: 'settings/:partnerId?',
        component: PartnersSettings
      },
      {
        name: 'partner-analytics',
        path: 'analytics/:partnerId?',
        component: PartnersAnalytics
      },
      {
        name: 'partner-client-list',
        path: ':partnerId/clients',
        component: ClientsList
      },
      {
        name: 'partner-client-edit',
        path: ':partnerId/clients/edit/:id?',
        component: ClientsEdit
      },
      {
        name: 'partner-client-analytics',
        path: ':partnerId/clients/analytics/:id',
        component: ClientsAnalytics
      },
      {
        name: 'partner-client-campaign-analytics',
        path: ':partnerId/clients/campaign-analytics/:clientId/:campaignId',
        component: ClientsCampaignAnalytics
      }
    ]
  },
  {
    path: '/clients',
    component: ViewDefaultIndex,
    meta: {
      layout: LayoutSecured
    },
    children: [
      {
        name: 'clients',
        path: '',
        redirect: to => {
          return {name: 'not-found'}
        }
      },
      {
        name: 'client-adsetproto-list',
        path: ':clientId/adsetprotos',
        component: AdSetProtosList
      },
      {
        name: 'client-adsetproto-edit',
        path: ':clientId/adsetprotos/edit/:id?',
        component: AdSetProtosEdit
      },
      {
        name: 'client-adsetproto-analytics',
        path: ':clientId/adsetprotos/analytics/:id?',
        component: AdSetProtosAnalytics
      }
    ]
  },
  {
    path: '/users',
    component: ViewDefaultIndex,
    meta: {
      layout: LayoutSecured
    },
    children: [
      {
        name: 'users',
        path: '',
        redirect: to => {
          return {name: 'user-list'}
        }
      },
      {
        name: 'user-list',
        path: 'list',
        component: UsersList
      }
    ]
  },
  {
    path: '/insights',
    component: ViewDefaultIndex,
    meta: {
      layout: LayoutSecured,
      breadcrumb: 'Insights'
    },
    children: [
      {
        name: 'insights',
        path: '',
        redirect: to => {
          return {name: 'insights-query'}
        }
      },
      {
        name: 'insights-query',
        path: 'query',
        component: InsightsQuery
      },
      {
        name: 'insights-display',
        path: 'display',
        component: InsightsDisplay
      }
    ]
  },
  {
    path: '/system-analytics',
    name: 'system-analytics',
    component: SystemAnalyticsIndex,
    meta: {
      layout: LayoutSecured
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsIndex,
    meta: {
      layout: LayoutSecured
    }
  },
  {
    path: '/tools',
    name: 'tools',
    component: ToolsIndex,
    meta: {
      layout: LayoutSecured
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: PageNotFound,
    meta: {
      layout: LayoutError
    }
  }
]
