
<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary"> <i class="fas fa-fw fa-cog"></i> {{title}}</h6>
    </div>

    <div class="card-body">
      <div class="">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-ads-tab" data-toggle="tab" href="#nav-ads" role="tab" aria-controls="nav-ads" aria-selected="true">Ads</a>
            <a class="nav-item nav-link" id="nav-fbrequests-tab" data-toggle="tab" href="#nav-fbrequests" role="tab" aria-controls="nav-fbrequests" aria-selected="true">FB</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active p-3 border-left border-bottom border-right" id="nav-ads" role="tabpanel" aria-labelledby="nav-ads-tab">
            <system-analytics-ads></system-analytics-ads>
          </div>
          <div class="tab-pane fade show active p-3 border-left border-bottom border-right" id="nav-fbrequests" role="tabpanel" aria-labelledby="nav-fbrequests-tab">
            <system-analytics-fb-requests></system-analytics-fb-requests>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import SystemAnalyticsAds from './Ads.vue'
import SystemAnalyticsFbRequests from './FbRequests'

export default {
  name: 'system-analytics',
  components: {
    SystemAnalyticsAds,
    SystemAnalyticsFbRequests
  },
  data () {
    return {
      title: 'System analytics'
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
