var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "joi-form",
    {
      attrs: { data: _vm.data, schema: _vm.validationSchema },
      on: { formdataready: _vm.save }
    },
    [
      _c("div", { staticClass: "card shadow mb-4" }, [
        _c("div", { staticClass: "card-header py-3" }, [
          _c(
            "a",
            {
              staticClass: "float-right",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.back()
                }
              }
            },
            [
              _c("i", { staticClass: "fas fa-fw fa-arrow-circle-left" }),
              _vm._v(" Back")
            ]
          ),
          _vm._v(" "),
          _vm.partner
            ? _c("h6", { staticClass: "m-0 font-weight-bold text-primary" }, [
                _vm._v(_vm._s(_vm.partner.name) + " "),
                _c("i", { staticClass: "fas fa-fw fa-angle-double-right" }),
                _vm._v(" " + _vm._s(_vm.title))
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card-body",
            class: { "danger-border": _vm.isDangerBorderNeeded }
          },
          [
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-6" },
                [_c("error-reveal", { attrs: { error: _vm.error } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Name:")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.name,
                      expression: "data.name"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", name: "name" },
                  domProps: { value: _vm.data.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data, "name", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Campaign management:")]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-radio custom-control-inline"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.externalCampaignManagement,
                            expression: "data.externalCampaignManagement"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "radio",
                          id: "radioExternalCampaignManagementNo",
                          name: "externalCampaignManagement",
                          disabled: _vm.data.id
                        },
                        domProps: {
                          value: false,
                          checked: _vm._q(
                            _vm.data.externalCampaignManagement,
                            false
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.data,
                              "externalCampaignManagement",
                              false
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "radioExternalCampaignManagementNo" }
                        },
                        [_vm._v("Internal")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "custom-control custom-radio custom-control-inline"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.externalCampaignManagement,
                            expression: "data.externalCampaignManagement"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "radio",
                          id: "radioExternalCampaignManagementYes",
                          name: "externalCampaignManagement",
                          disabled: _vm.data.id
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(
                            _vm.data.externalCampaignManagement,
                            true
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.data,
                              "externalCampaignManagement",
                              true
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "radioExternalCampaignManagementYes" }
                        },
                        [_vm._v("External")]
                      )
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Agent:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.agent,
                        expression: "data.agent"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "agent",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.agents, function(item) {
                      return _c(
                        "option",
                        { key: item.id, domProps: { value: item.user.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("span", { attrs: { "error-path": "agent" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" })
            ]),
            _vm._v(" "),
            _vm.isExternalCampaignManagement
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", [_vm._v("Facebook campaign ID:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.lazy",
                          value: _vm.data.externalCampaignId,
                          expression: "data.externalCampaignId",
                          modifiers: { lazy: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "externalCampaignId",
                        disabled: !_vm.loadedAgent || _vm.data.id
                      },
                      domProps: { value: _vm.data.externalCampaignId },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.data,
                            "externalCampaignId",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {
                      attrs: { "error-path": "externalCampaignId" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-md-6" })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isExternalCampaignManagement
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", [_vm._v("Facebook campaign name:")]),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: { type: "text", disabled: true },
                      domProps: { value: _vm.data.externalNameTpl }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-md-6" })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isInternalCampaignManagement
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", [_vm._v("Facebook campaign name:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.externalNameTpl,
                          expression: "data.externalNameTpl"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "externalNameTpl",
                        disabled: _vm.isExternalCampaignManagement
                      },
                      domProps: { value: _vm.data.externalNameTpl },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.data,
                            "externalNameTpl",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "form-text text-info" }, [
                      _vm._v("Serial number of the campaign: %serial% ")
                    ]),
                    _vm._v(" "),
                    _c("span", { attrs: { "error-path": "externalNameTpl" } })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-md-6" })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Ad account:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.accountId,
                        expression: "data.accountId"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled:
                        !_vm.loadedAgent ||
                        _vm.data.id ||
                        _vm.isExternalCampaignManagement
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "accountId",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.adAccounts, function(item) {
                      return _c(
                        "option",
                        {
                          key: item.account_id,
                          domProps: { value: item.account_id }
                        },
                        [
                          _vm._v(
                            _vm._s(item.name) +
                              " (" +
                              _vm._s(item.account_id) +
                              "; " +
                              _vm._s(item.currency) +
                              "; " +
                              _vm._s(
                                _vm.accountStatusText(item.account_status)
                              ) +
                              ")"
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("span", { attrs: { "error-path": "accountId" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-6" }, [
                _c("label", [_vm._v("Marketing goal:")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.marketingGoal,
                        expression: "data.marketingGoal"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      disabled:
                        !_vm.data.accountId ||
                        _vm.data.id ||
                        _vm.isExternalCampaignManagement
                    },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data,
                          "marketingGoal",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.marketingGoals, function(item) {
                      return _c(
                        "option",
                        { key: item.name, domProps: { value: item.name } },
                        [_vm._v(_vm._s(item.title))]
                      )
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("span", { attrs: { "error-path": "marketingGoal" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-md-6" })
            ]),
            _vm._v(" "),
            _vm.isInternalCampaignManagement
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", [_vm._v("Period budget:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.periodBudget,
                            expression: "data.periodBudget"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          disabled: !_vm.data.accountId,
                          name: "periodBudget"
                        },
                        domProps: { value: _vm.data.periodBudget },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "periodBudget",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(_vm._s(_vm.data.currency))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { attrs: { "error-path": "periodBudget" } })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", [_vm._v("Daily budget:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "input-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.dailyBudget,
                            expression: "data.dailyBudget"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          disabled: !_vm.data.accountId,
                          name: "dailyBudget"
                        },
                        domProps: { value: _vm.data.dailyBudget },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "dailyBudget",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c("span", { staticClass: "input-group-text" }, [
                          _vm._v(_vm._s(_vm.data.currency))
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("span", { attrs: { "error-path": "dailyBudget" } })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isExternalCampaignManagement
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-12" }, [
                    _c("div", { staticClass: "custom-control custom-switch" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.trackCampaign,
                            expression: "data.trackCampaign"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          type: "checkbox",
                          id: "switchCampaignTracking"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.data.trackCampaign)
                            ? _vm._i(_vm.data.trackCampaign, null) > -1
                            : _vm.data.trackCampaign
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.data.trackCampaign,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.data,
                                    "trackCampaign",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.data,
                                    "trackCampaign",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.data, "trackCampaign", $$c)
                            }
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "switchCampaignTracking" }
                        },
                        [_vm._v("Track external campaign")]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c(
                "div",
                { staticClass: "form-group col-md-12" },
                [
                  _vm.isInternalCampaignManagement
                    ? _c("client-schedule", {
                        attrs: { errorPathPrefix: "scheduling." },
                        model: {
                          value: _vm.data.scheduling,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "scheduling", $$v)
                          },
                          expression: "data.scheduling"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { attrs: { "error-path": "scheduling" } }),
                  _vm._v(" "),
                  _vm.data.timezone
                    ? _c("small", { staticClass: "form-text text-info" }, [
                        _vm._v("Timezone: " + _vm._s(_vm.data.timezone))
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-12" }, [
                _c("div", { staticClass: "custom-control custom-switch" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.isPolitical,
                        expression: "data.isPolitical"
                      }
                    ],
                    staticClass: "custom-control-input",
                    attrs: { type: "checkbox", id: "switchPolitical" },
                    domProps: {
                      checked: Array.isArray(_vm.data.isPolitical)
                        ? _vm._i(_vm.data.isPolitical, null) > -1
                        : _vm.data.isPolitical
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.data.isPolitical,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.data,
                                "isPolitical",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.data,
                                "isPolitical",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.data, "isPolitical", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "switchPolitical" }
                    },
                    [_vm._v("Political ads")]
                  ),
                  _vm._v(" "),
                  _c("span", { attrs: { "error-path": "isPolitical" } })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "form-group col-md-12" }, [
                _c("div", { staticClass: "custom-control custom-switch" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.notifOnStoppingAds,
                        expression: "data.notifOnStoppingAds"
                      }
                    ],
                    staticClass: "custom-control-input",
                    attrs: { type: "checkbox", id: "switchNotifOnStoppingAds" },
                    domProps: {
                      checked: Array.isArray(_vm.data.notifOnStoppingAds)
                        ? _vm._i(_vm.data.notifOnStoppingAds, null) > -1
                        : _vm.data.notifOnStoppingAds
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.data.notifOnStoppingAds,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.data,
                                "notifOnStoppingAds",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.data,
                                "notifOnStoppingAds",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.data, "notifOnStoppingAds", $$c)
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "custom-control-label",
                      attrs: { for: "switchNotifOnStoppingAds" }
                    },
                    [_vm._v("Send notification on stopping ads")]
                  ),
                  _vm._v(" "),
                  _c("span", { attrs: { "error-path": "notifOnStoppingAds" } })
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.data.notifOnStoppingAds
              ? _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-6" }, [
                    _c("label", [_vm._v("Recepients:")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.notifOnStoppingAdsRecepients,
                            expression: "data.notifOnStoppingAdsRecepients"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "6" },
                        domProps: {
                          value: _vm.data.notifOnStoppingAdsRecepients
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.data,
                              "notifOnStoppingAdsRecepients",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("small", { staticClass: "form-text text-muted" }, [
                        _vm._v("One mail address per line")
                      ]),
                      _vm._v(" "),
                      _c("span", {
                        attrs: { "error-path": "notifOnStoppingAdsRecepients" }
                      })
                    ])
                  ])
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer text-muted" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary trans-uppercase",
              attrs: { type: "submit" }
            },
            [_vm._v("Save")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }