function plugin (Vue, options = {}) {
  Object.defineProperties(Vue.prototype, {
    $layout: {
      get: function () {
        var layout = null
        for (var i = 0; i < this.$route.matched.length; i++) {
          if (this.$route.matched[i].meta && this.$route.matched[i].meta.layout) {
            layout = this.$route.matched[i].meta.layout
            if (!layout.component) {
              layout = {component: layout, props: {}}
            }
          }
        }
        return layout
      }
    }
  })
}

export default plugin
