
<template>
  <div :id="domUid" class="modal fade">
    <joi-form :data="data" :schema="validationSchema" v-on:formdataready="save">
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">{{title}}</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>


          <!-- Modal body -->
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <error-callout :error="error"></error-callout>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Name:</label>
                <input type="text" v-model="data.name" name="name" class="form-control">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Access token:</label>
                <textarea v-model="data.accessToken" name="accessToken" class="form-control" rows="5"></textarea>
              </div>
            </div>

          </div>
          <div class="card-footer text-muted">
            <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
          </div>
        </div>
      </div>
    </joi-form>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import merge from 'merge'
import * as mutationTypes from '../../../store/mutation-types'
import JoiForm from '../../../inputs/joi-form'
import ErrorCallout from '../../../errors/Callout'
import validationSchemas from '../../../validators/schemas'
import partnerService from '../../../services/partner'

export default {
  name: 'agent-edit',
  components: {
    JoiForm,
    ErrorCallout
  },
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      data: {
        id: null,
        name: null,
        accessToken: null
      },
      title: 'Add system user',
      validationSchema: validationSchemas['partner.agent.create'],
      error: null
    }
  },
  computed: {
    domUid () {
      return 'agent-edit-' + this._uid
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    show (id) {
      if (this.modalElement) {
        this.modalElement.modal('show')
      } else {
        this.modalElement = $(this.$el).modal({
          focus: true,
          show: true
        })
      }

      this.reset()

      if (id) {
        this.load(id)
      }
    },
    hide () {
      if (this.modalElement) {
        this.modalElement.modal('hide')
      }
    },
    reset () {
      this.data.id = null
      this.data.accessToken = null
      this.error = null
      this.validationSchema = validationSchemas['partner.agent.create']
    },
    load (id) {
      this.reset()
      this[mutationTypes.BUSY_ON]()
      return partnerService.loadAgent(this.partner.id, id)
      .then((data) => {
        this[mutationTypes.BUSY_OFF]()
        this.title = 'Change system user token'
        this.data = merge(true, data)
        this.validationSchema = validationSchemas['partner.agent.update']
      })
      .catch((err) => {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      })
    },
    async save (formData) {
      this[mutationTypes.BUSY_ON]()

      try {
        await partnerService.saveAgent(this.partner.id, this.data.id, this.data)
        this[mutationTypes.BUSY_OFF]()
        this.$emit('saved')
        this.hide()
      } catch (err) {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
