import { render, staticRenderFns } from "./ViewDefaultIndex.vue?vue&type=template&id=4a538a1e&scoped=true&"
import script from "./ViewDefaultIndex.vue?vue&type=script&lang=js&"
export * from "./ViewDefaultIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a538a1e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/adflow-staging/frontend/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('4a538a1e', component.options)
    } else {
      api.reload('4a538a1e', component.options)
    }
    module.hot.accept("./ViewDefaultIndex.vue?vue&type=template&id=4a538a1e&scoped=true&", function () {
      api.rerender('4a538a1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/ViewDefaultIndex.vue"
export default component.exports