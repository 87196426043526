
<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <error-reveal :error="error"></error-reveal>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <chart-time-series title="FB requests in the last 24 hours" :subtitle="chartGenericSubtitle" :height="400" :colors="chartData.colors" :pointsInTime="chartData.pointsInTime" :series="chartData.series" :showLegend="true"></chart-time-series>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import ChartTimeSeries from '../../charts/TimeSeries.vue'
import ErrorReveal from '../../errors/Reveal'
import analyticsService from '../../services/analytics'
import moment from 'moment'
import 'moment-timezone'

export default {
  name: 'system-analytics-fbrequests',
  components: {
    ErrorReveal,
    ChartTimeSeries
  },
  props: {
  },
  data () {
    let baseTime = moment()
    let from = baseTime.clone().subtract(24, 'hours')
    let to = baseTime.clone()

    return {
      error: null,
      chartsInterval: {
        from,
        to
      },
      chartData: {
        colors: ['#302ae8', '#6E9130', '#e80202'],
        pointsInTime: [],
        series: [
          {
            name: 'Created',
            visible: true,
            data: []
          },
          {
            name: 'Success',
            visible: true,
            data: []
          },
          {
            name: 'Failed',
            visible: true,
            data: []
          }
        ]
      },
    }
  },
  computed: {
    chartGenericSubtitle () {
      return this.chartsInterval.from.format('YYYY.MM.DD HH:mm') + ' - ' + this.chartsInterval.to.format('YYYY.MM.DD HH:mm')
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    async load () {
      this.info = null
      this[mutationTypes.BUSY_ON]()
      try {
        let rawData = await analyticsService.queryFbRequestsAnalytics({
          from: this.chartsInterval.from.format(),
          to: this.chartsInterval.to.format()
        })
        this[mutationTypes.BUSY_OFF]()
        this.buildChartsData(rawData)
      } catch (err) {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      }
    },
    buildChartsData (rawData) {
      this.mergeChartsDataInto(rawData, ['fb_requests', 'fb_succ_requests', 'fb_fail_requests'], 'chartData')
    },
    mergeChartsDataInto (rawData, measurements, dataKey) {
      this[dataKey].pointsInTime = []

      let timeSeriesKey = _.findKey(rawData, (entries, key) => entries.length || measurements.includes(key))
      if (timeSeriesKey) {
        this[dataKey].pointsInTime = rawData[timeSeriesKey].map(dataPoint => moment(dataPoint.time).tz('Europe/Budapest'))
      }

      for (let [seriesIdx, measurement] of measurements.entries()) {
        this[dataKey].series[seriesIdx].data = []

        if (rawData[measurement]) {
          for (let dataPoint of rawData[measurement]) {
            this[dataKey].series[seriesIdx].data.push(dataPoint.value || 0)
          }
        } else {
          this[dataKey].series[seriesIdx].data = []
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
