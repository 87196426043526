
<template>
  <div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <error-reveal :error="error"></error-reveal>
        <info-reveal :message="info"></info-reveal>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <button type="button" class="btn btn-primary trans-uppercase" @click="clearCache()">Clear cache</button>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import ErrorReveal from '../../errors/Reveal'
import InfoReveal from '../../containers/InfoReveal'
import cacheService from '../../services/cache'

export default {
  name: 'settings-cache',
  components: {
    ErrorReveal,
    InfoReveal
  },
  data () {
    return {
      error: null,
      info: null
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    async clearCache () {
      this.info = null
      this[mutationTypes.BUSY_ON]()

      try {
        await cacheService.flushAll()
        this[mutationTypes.BUSY_OFF]()
        this.info = 'Cache cleared.'
      } catch (err) {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
