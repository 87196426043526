var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-row" }, [
    _c("div", { staticClass: "form-group col-md-7" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fixed,
              expression: "fixed"
            }
          ],
          staticClass: "form-control",
          attrs: { disabled: !_vm.reference, type: "number", step: "0.00001" },
          domProps: { value: _vm.fixed },
          on: {
            change: _vm.onFixedInputChange,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.fixed = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group-append" }, [
          _c("span", { staticClass: "input-group-text" }, [
            _vm._v(_vm._s(_vm.unit))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group col-md-1 text-center align-middle pt-2" },
      [_vm._v("/")]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-group col-md-4" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.percent,
              expression: "percent"
            }
          ],
          staticClass: "form-control",
          attrs: { disabled: !_vm.reference, type: "number", step: "1" },
          domProps: { value: _vm.percent },
          on: {
            change: _vm.onPercentInputChange,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.percent = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("%")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }