<template>
  <div>
    <error-reveal :error="error"></error-reveal>
    <div class="card shadow mb-4">
      <div class="card-header py-3" v-if="client">
        <router-link :to="{name: 'partner-client-list', params: {partnerId: client.partner.id}}" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</router-link>
        <h6 class="m-0 mt-1 font-weight-bold text-primary">{{client.partner.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{client.name}} <i class="fas fa-fw fa-angle-double-right"></i> Adsets</h6>
      </div>
      <div class="card-body">
        <card-list
          :items="items"
          emptytext="No adsets."
          additemtext="Create adset"
          refreshtext="Refresh"
          @additem="onAddNewItem"
          @refresh="onGridRefresh"
        >
          <template v-slot:card="slotProps">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                   <span class="card-title clickable h5" @click="onItemAction('edit', slotProps.item)">{{slotProps.item.name}}</span>
                </div>
                <div class="col-md-6 text-right">
                  <button type="button" class="btn btn-primary btn-xs" @click="onItemAction('edit', slotProps.item)">
                    <span><i class="fas fa-sm fa-edit"></i></span>
                  </button>
                  <button type="button" class="btn btn-primary btn-xs" @click="onItemAction('view-analytics', slotProps.item)">
                    <span><i class="fas fa-sm fa-chart-bar"></i></span>
                  </button>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <p class="small text-muted mb-1"><span v-html="slotProps.item.adsStats"></span></p>
                </div>
              </div>
            </div>
          </template>
        </card-list>

        <!-- <data-grid
          :items="items"
          :columns="columns"
          emptytext="No adsets."
          additemtext="Create adset"
          refreshtext="Refresh"
          @additem="onAddNewItem"
          @itemaction="onItemAction"
          @itemcolumnclick="onItemColumnClick"
          @refresh="onGridRefresh"
        ></data-grid> -->
      </div>
    </div>
    <data-store
      ref="store"
      :service="service"
      :filter="filter"
      :sort="sort"
      @beforeload="onBeforeLoad"
      @afterload="onAfterLoad"
      @dataloaded="onDataLoaded"
      @loaderror="onLoadError"
    ></data-store>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import CardList from '../../lists/CardList'
// import DataGrid from '../../lists/DataGrid'
import DataStore from '../../data/Store'
import ErrorReveal from '../../errors/Reveal'
import adsetProtoService from '../../services/adsetproto'
import i18nService from '../../services/i18n'
import SafeString from '../../utils/SafeString'
import TimespanSpends from '../../utils/TimespanSpends'
import ClientRequired from '../../mixins/ClientRequired'

export default {
  name: 'adsetprotos-list',
  mixins: [ClientRequired],
  components: {
    // DataGrid,
    CardList,
    DataStore,
    ErrorReveal
  },
  data () {
    return {
      client: null,
      items: [],
      columns: [{
        title: new SafeString('Name'),
        name: 'name',
        valign: 'top',
        clickable: true
      // }, {
      //   title: 'Spends',
      //   name: 'spendsInfo',
      //   align: 'left',
      //   width: 200
      // }, {
      //   title: 'Buffer',
      //   name: 'buffer',
      //   align: 'center',
      //   width: 90
      // }, {
      //   title: 'Ads',
      //   name: 'ads',
      //   align: 'center',
      //   width: 80
      // }, {
      //   title: 'Budget',
      //   name: 'budget',
      //   align: 'right',
      //   width: 90
      // }, {
      //   title: 'Spent',
      //   name: 'spent',
      //   align: 'right',
      //   width: 90
      // }, {
      //   title: 'Locked',
      //   name: 'locked',
      //   align: 'right',
      //   width: 90
      // }, {
      //   title: 'Balance',
      //   name: 'balance',
      //   align: 'right',
      //   width: 90
      // }, {
      //   title: 'Created at',
      //   name: 'createdAt',
      //   width: 150,
      //   align: 'center',
      //   valign: 'top'
      }, {
        actions: [{
          name: 'edit',
          html: '<i class="fas fa-sm fa-edit"></i>'
        }],
        width: 75,
        align: 'right'
      }],
      service: null,
      filter: null,
      sort: null,
      error: null
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onClientLoaded (error, client) {
      this.error = error
      if (client) {
        this.client = client
        this.service = () => {
          return adsetProtoService.list({client: client.id})
        }
      } else {
        this.client = null
        this.filter = null
        this.service = null
      }
    },
    onAddNewItem () {
      this.$router.push({name: 'client-adsetproto-edit', params: {clientId: this.client.id}})
    },
    onBeforeLoad () {
      this[mutationTypes.BUSY_ON]()
    },
    onAfterLoad () {
      this[mutationTypes.BUSY_OFF]()
    },
    onDataLoaded (items, result) {
      this.items = items.map(this.parseItem)
    },
    onLoadError (error) {
      this[mutationTypes.BUSY_OFF]()
      this.error = error
    },
    onItemAction (action, item) {
      if (action === 'edit') {
        this.$router.push({name: 'client-adsetproto-edit', params: {clientId: this.client.id, id: item.id}})
      }
      if (action === 'view-analytics') {
        this.$router.push({name: 'client-adsetproto-analytics', params: {clientId: this.client.id, id: item.id}})
      }

      // if (action.name === 'viewads') {
      //   this.$router.push({name: 'adsetproto-ads', params: {id: item.id}})
      // }
    },
    onItemColumnClick (item, column) {
      if (column.name === 'name') {
        this.$router.push({name: 'client-adsetproto-edit', params: {clientId: this.client.id, id: item.id}})
      }
    },
    onGridRefresh () {
      this.$refs.store.load()
    },
    parseItem (rawData) {
      return {
        id: rawData.id,
        name: rawData.name,
        adsStats: this.renderAdsStats(rawData)
        // description: new SafeString(`${rawData.name}`)
        // periodBudget: i18nService.formatMoneyAmount(rawData.periodBudget),
        // budget: i18nService.formatMoneyAmount(_.get(rawData, 'currentPeriod.budget', 0)),
        // spent: i18nService.formatMoneyAmount(_.get(rawData, 'currentPeriod.spent', 0)),
        // locked: i18nService.formatMoneyAmount(_.get(rawData, 'currentPeriod.locked', 0)),
        // balance: i18nService.formatMoneyAmount(_.get(rawData, 'currentPeriod.balance', 0)),
        // createdAt: i18nService.formatDateTime(rawData.createdAt, i18nService.FMT_SHORT, i18nService.FMT_SHORT),
        // ads: this.renderAds(rawData),
        // buffer: this.renderBuffer(rawData),
        // spendsInfo: this.renderSpendsInfo(rawData)
      }
    },
    renderAdsStats (rawData) {
      if (!rawData.currentCampaign) {
        return '<b>Ads:</b> -'
      }

      let stats = rawData.stats.ads

      return `<b>Ads:</b> ${stats.total} / ${stats.running}`
    },
    precisionRound (number, precision) {
      let factor = Math.pow(10, precision)
      return Math.round(number * factor) / factor
    }
    // renderAds (rawData) {
    //   let ads = rawData.stats.ads
    //   return `${ads.total} / ${ads.running}`
    // },
    // renderBuffer (rawData) {
    //   let buffer = rawData.stats.buffer
    //   return buffer ? `${buffer.total} / ${buffer.processed}` : '-'
    // },
    // renderSpendsInfo (rawData) {
    //   return new SafeString(TimespanSpends.renderSpendsInfo(rawData.stats.timespanSpends, rawData.live))
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
