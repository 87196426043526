<script>
import api from './api'
export default {
  load (id) {
    return api.getData('users/' + id)
  },
  save (id, formData) {
    if (!id) {
      return api.post('users', formData)
    }
    return api.put(`users/${id}`, formData)
  },
  remove (id) {
    return api.delete(`users/${id}`)
  },
  list (params) {
    return api.get('users', params)
  },
  toggleAccess (id) {
    return api.put(`users/${id}/toggleaccess`)
  },
  listAdAccounts (id, params) {
    return api.get(`users/${id}/adaccounts`, params)
  },
  listPages (id, params) {
    return api.get(`users/${id}/pages`, params)
  },
  listSavedAudiences (id, accountId, params) {
    return api.get(`users/${id}/adaccounts/${accountId}/savedAudiences`, params)
  },
  listAdsPixels (id, accountId, params) {
    return api.get(`users/${id}/adaccounts/${accountId}/adsPixels`, params)
  },
  listCustomConversions (id, accountId, params) {
    return api.get(`users/${id}/adaccounts/${accountId}/customConversions`, params)
  },
  readCampaignDetails (id, campaignId, params) {
    return api.get(`users/${id}/campaign/${campaignId}`, params)
  },
  readAdTemplateDetails (id, adId, params) {
    return api.get(`users/${id}/adtemplate/${adId}`, params)
  }
}
</script>
