var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("error-reveal", { attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("div", { staticClass: "card shadow mb-4" }, [
        _c(
          "div",
          { staticClass: "card-header py-3" },
          [
            _c(
              "router-link",
              {
                staticClass: "float-right",
                attrs: { to: { name: "partners" } }
              },
              [
                _c("i", { staticClass: "fas fa-fw fa-arrow-circle-left" }),
                _vm._v(" Back")
              ]
            ),
            _vm._v(" "),
            _vm.partner
              ? _c(
                  "h6",
                  { staticClass: "m-0 mt-1 font-weight-bold text-primary" },
                  [
                    _vm._v(_vm._s(_vm.partner.name) + " "),
                    _c("i", { staticClass: "fas fa-fw fa-angle-double-right" }),
                    _vm._v(" Clients")
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("card-list", {
              attrs: {
                items: _vm.items,
                emptytext: "No clients.",
                additemtext: "Create client",
                refreshtext: "Refresh"
              },
              on: { additem: _vm.onAddNewItem, refresh: _vm.onGridRefresh },
              scopedSlots: _vm._u([
                {
                  key: "titleHead",
                  fn: function() {
                    return [
                      _c("div", {}, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.statusFilter,
                                expression: "statusFilter"
                              }
                            ],
                            staticClass: "custom-select custom-select-sm",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.statusFilter = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "active" } }, [
                              _vm._v("Only active clients")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "inactive" } }, [
                              _vm._v("Only inactive clients")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "all" } }, [
                              _vm._v("All clients")
                            ])
                          ]
                        )
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "card",
                  fn: function(slotProps) {
                    return [
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          class: { "opacity-50": !slotProps.item.active }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-md-6" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "card-title clickable h5",
                                  on: {
                                    click: function($event) {
                                      return _vm.onItemAction(
                                        "adsetprotos",
                                        slotProps.item
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(slotProps.item.name))]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-md-6 text-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-xs",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onItemAction(
                                        "edit",
                                        slotProps.item
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "fas fa-sm fa-edit"
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary btn-xs",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onItemAction(
                                        "view-analytics",
                                        slotProps.item
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "fas fa-sm fa-chart-bar"
                                    })
                                  ])
                                ]
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row mt-2" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              slotProps.item.isPolitical
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "small  mb-1 text-error text-danger"
                                    },
                                    [_c("b", [_vm._v("POLITICAL")])]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "small text-muted mb-1" },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        slotProps.item.currentCampaignInfo
                                      )
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                { staticClass: "small text-muted mb-1" },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(slotProps.item.adsStats)
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "small mb-0" }, [
                                slotProps.item.active
                                  ? _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onItemAction(
                                              "inactivate",
                                              slotProps.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-sm fa-pause-circle"
                                        }),
                                        _vm._v(" Inactivate")
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !slotProps.item.active
                                  ? _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onItemAction(
                                              "activate",
                                              slotProps.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-sm fa-play-circle"
                                        }),
                                        _vm._v(" Activate")
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _vm._v(" "),
                              slotProps.item.currentCampaignId
                                ? _c("p", { staticClass: "small mt-1 mb-0" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onItemAction(
                                              "download",
                                              slotProps.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-sm fa-download"
                                        }),
                                        _vm._v(" Download performance")
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              slotProps.item.currentCampaignId
                                ? _c("p", { staticClass: "small mt-1 mb-0" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.onItemAction(
                                              "view-campaign-analytics",
                                              slotProps.item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-sm fa-chart-bar"
                                        }),
                                        _vm._v(" View campaign analytics")
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("data-store", {
        ref: "store",
        attrs: {
          service: _vm.service,
          filter: _vm.filters,
          sort: _vm.sort,
          autoload: false
        },
        on: {
          beforeload: _vm.onBeforeLoad,
          afterload: _vm.onAfterLoad,
          dataloaded: _vm.onDataLoaded,
          loaderror: _vm.onLoadError
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }