<template>
  <div :id="domUid" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Error</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          {{message}}
        </div>

        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'

export default {
  name: 'error-reveal',
  props: ['error'],
  data () {
    return {
    }
  },
  computed: {
    domUid () {
      return 'error-reveal-' + this._uid
    },
    message () {
      return this.error ? this.error.message : ''
    }
  },
  watch: {
    error: 'onErrorChanged'
  },
  mounted: function () {
  },
  methods: {
    onErrorChanged (error) {
      if (error) {
        this.show()
      } else {
        this.hide()
      }
    },
    show () {
      if (this.modalElement) {
        this.modalElement.modal('show')
      } else {
        this.modalElement = $(this.$el).modal({
          focus: true,
          show: true
        })
      }
    },
    hide () {
      if (this.modalElement) {
        this.modalElement.modal('hide')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
