<script>
import api from './api'
export default {
  load (id) {
    return api.get('clients/' + id)
  },
  save (id, formData) {
    if (!id) {
      return api.post('clients', formData)
    }
    return api.put('clients/' + id, formData)
  },
  list (params) {
    return api.get('clients', params)
  },
  downloadCurrentCampaignPerformance  (id) {
    return api.download(`clients/${id}/downloadCurrentCampaignPerformance`)
  },
  activate (id) {
    return api.put(`clients/${id}/activate`)
  },
  inactivate (id) {
    return api.put(`clients/${id}/inactivate`)
  }
}
</script>
