import Vue from 'vue'
import Vuex from 'vuex'
import * as mutations from './mutations'
import * as actions from './actions'
import sessionstorage from 'sessionstorage'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    user: JSON.parse(sessionstorage.getItem('user')),
    busy: false,
    serviceFbReady: false,
    headerActions: []
  },
  mutations,
  actions,
  strict: debug
  // plugins: debug ? [createLogger()] : []
})
