<script>
/* global FB APPS */
import Vue from 'vue'
import Promise from 'bluebird'
import merge from 'merge'
import api from './api'

const eventbus = new Vue()
const FBAPP = APPS.fbmarketing

export default {
  getEventBus () {
    return eventbus
  },

  init () {
    return new Promise((resolve, reject) => {
      try {
        window.fbAsyncInit = () => {
          FB.init({
            appId: FBAPP.clientID,
            xfbml: true,
            version: FBAPP.version
          })
          FB.AppEvents.logPageView()
          resolve()
        }

        (function (d, s, id) {
          var js
          var fjs = d.getElementsByTagName(s)[0]

          if (d.getElementById(id)) {
            return
          }
          js = d.createElement(s)
          js.id = id
          js.src = '//connect.facebook.net/en_US/sdk.js'
          fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
      } catch (err) {
        reject(err)
      }
    })
  },

  login () {
    return new Promise((resolve, reject) => {
      FB.login((response) => {
        var identity = null
        if (response.status === 'connected' && response.authResponse) {
          identity = merge(true, response.authResponse)
          identity.id = identity.userID
          delete identity.userID
          identity.appKey = FBAPP.appKey
          return resolve(identity)
        }

        return reject(new Error('Cannot login'))
      }, {
        scope: FBAPP.scope.join(','),
        return_scopes: true
      })
    })
  },

  async readPostDetails (postUrl) {
    return api.get(`fb/post/${encodeURIComponent(postUrl)}`)
  },

  async readCampaignDetails (campaignId) {
    return api.get(`fb/campaign/${campaignId}`)
  }
}
</script>
