
<template>
  <joi-form :data="data" :schema="validationSchema" v-on:formdataready="save">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <a @click.prevent="back()" href="#" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</a>
        <h6 class="m-0 font-weight-bold text-primary">{{title}}</h6>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <error-reveal :error="error"></error-reveal>
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Name:</label>
            <input type="text" v-model="data.name" name="name" class="form-control">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Contact name:</label>
            <input type="text" v-model="data.contactName" name="contactName" class="form-control">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label>Contact phone:</label>
            <input type="text" v-model="data.contactPhone" name="contactPhone" class="form-control">
          </div>
        </div>

      </div>
      <div class="card-footer text-muted">
        <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
      </div>
    </div>
  </joi-form>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import JoiForm from '../../inputs/joi-form'
import ErrorReveal from '../../errors/Reveal'
import validationSchemas from '../../validators/schemas'
import partnerService from '../../services/partner'

export default {
  name: 'partners-edit',
  components: {
    JoiForm,
    ErrorReveal
  },
  data () {
    return {
      data: {
        name: null,
        contactName: null,
        contactPhone: null
      },
      title: 'Create partner',
      validationSchema: validationSchemas['partner.edit'],
      error: null
    }
  },
  created: function () {
    if (this.$route.params.id) {
      this.load(this.$route.params.id)
    }
  },
  watch: {
    '$route': function (newVal, oldVal) {
      if (newVal.params.id) {
        this.load(newVal.params.id)
      }
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onAddNewItem () {
      this.$router.push('partners/edit')
    },
    back () {
      this.$router.push({name: 'partner-list'})
    },
    load (id) {
      this.reset()
      this[mutationTypes.BUSY_ON]()
      return partnerService.load(id)
      .then((data) => {
        this[mutationTypes.BUSY_OFF]()
        this.title = 'Update partner'
        this.data = merge(true, data)
      })
      .catch((err) => {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      })
    },
    reset () {
    },
    async save (formData) {
      this[mutationTypes.BUSY_ON]()

      try {
        await partnerService.save(this.data.id, this.data)
        this[mutationTypes.BUSY_OFF]()
        this.back()
      } catch (err) {
        this[mutationTypes.BUSY_OFF]()
        this.error = err
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
