<script>
import api from './api'
export default {
  queryAdAnalytics (params) {
    return api.get('analytics/query/ads', params)
  },
  queryFbRequestsAnalytics (params) {
    return api.get('analytics/query/fbrequests', params)
  }
}
</script>
