import { render, staticRenderFns } from "./Ad.vue?vue&type=template&id=5cba5bf2&scoped=true&"
import script from "./Ad.vue?vue&type=script&lang=js&"
export * from "./Ad.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cba5bf2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/adflow-staging/frontend/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('5cba5bf2', component.options)
    } else {
      api.reload('5cba5bf2', component.options)
    }
    module.hot.accept("./Ad.vue?vue&type=template&id=5cba5bf2&scoped=true&", function () {
      api.rerender('5cba5bf2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/tools/Ad.vue"
export default component.exports