<template>
  <div>
    <div class="row mb-2">
      <div class="col-sm-4">
        <button v-if="addNewEnabled" type="button" name="button" class="btn btn-primary btn-sm" v-on:click="onAddNewItem"><i class="fas fa-fw fa-plus"></i> {{additemtext}}</button>
        &nbsp;
      </div>
      <div class="col-sm-4">
        <slot name="titleHead"></slot>
        {{title}}
      </div>
      <div class="col-sm-4 text-right">
        &nbsp;
        <button v-if="refreshEnabled" type="button" name="button" class="btn btn-secondary btn-sm" v-on:click="onRefresh"><i class="fas fa-fw fa-sync-alt"></i> {{refreshtext}}</button>
      </div>
    </div>
    <div v-if="!items || !items.length" class="text-center m-2">{{emptytext}}</div>
    <div class="card-list">
      <slot name="header"></slot>
        <div
          v-for="item of items"
          v-bind:key="item.id"
        >
          <div class="card">
            <slot name="card" v-bind:item="item"></slot>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import CardItem from './CardItem'
export default {
  name: 'card-list',
  components: {
    CardItem
  },
  props: {
    title: {
      default: '',
      type: String
    },
    items: {
      default: [],
      type: Array
    },
    emptytext: {
      default: 'Nincs megjeleníthető adat.',
      type: String
    },
    additemtext: {
      default: 'Hozzáadás',
      type: String
    },
    refreshtext: {
      default: 'Frissítés',
      type: String
    },
    addNewEnabled: {
      default: true,
      type: Boolean
    },
    refreshEnabled: {
      default: true,
      type: Boolean
    },
    enableRowSelect: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onAddNewItem () {
      this.$emit('additem')
    },
    onRefresh () {
      this.$emit('refresh')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '../../styles/main';

  .clickable {
    color: theme-color();
    cursor: pointer;
  }

  .btn-group-xs > .btn, .btn-xs {
    padding  : .25rem .4rem;
    font-size  : .875rem;
    line-height  : .5;
    border-radius : .2rem;
  }
</style>
