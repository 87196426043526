<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 mt-4 text-center">
        <router-link to="/"><img src="../../assets/logo-med.jpg" class="logo" alt="" /></router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-6 mt-4 text-center">
        <h5>Please contact us to validate your registration and enable your access.</h5>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'preregistered',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
