var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _vm.addNewEnabled
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm",
                attrs: { type: "button", name: "button" },
                on: { click: _vm.onAddNewItem }
              },
              [
                _c("i", { staticClass: "fas fa-fw fa-plus" }),
                _vm._v(" " + _vm._s(_vm.additemtext))
              ]
            )
          : _vm._e(),
        _vm._v("\n       \n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-4" },
        [
          _vm._t("titleHead"),
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 text-right" }, [
        _vm._v("\n       \n      "),
        _vm.refreshEnabled
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-sm",
                attrs: { type: "button", name: "button" },
                on: { click: _vm.onRefresh }
              },
              [
                _c("i", { staticClass: "fas fa-fw fa-sync-alt" }),
                _vm._v(" " + _vm._s(_vm.refreshtext))
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    !_vm.items || !_vm.items.length
      ? _c("div", { staticClass: "text-center m-2" }, [
          _vm._v(_vm._s(_vm.emptytext))
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-list" },
      [
        _vm._t("header"),
        _vm._v(" "),
        _vm._l(_vm.items, function(item) {
          return _c("div", { key: item.id }, [
            _c(
              "div",
              { staticClass: "card" },
              [_vm._t("card", null, { item: item })],
              2
            )
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }