var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "joi-form",
    {
      attrs: { data: _vm.data, schema: _vm.validationSchema },
      on: { formdataready: _vm.save }
    },
    [
      _c("div", { staticClass: "card shadow mb-4" }, [
        _c("div", { staticClass: "card-header py-3" }, [
          _c("h6", { staticClass: "m-0 font-weight-bold text-primary" }, [
            _vm._v(_vm._s(_vm.title))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [_c("error-reveal", { attrs: { error: _vm.error } })],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Partner:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.partnerId,
                      expression: "data.partnerId"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.data,
                        "partnerId",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                  _vm._v(" "),
                  _vm._l(_vm.partners, function(item) {
                    return _c("option", { domProps: { value: item.id } }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Client:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.clientId,
                      expression: "data.clientId"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.data,
                        "clientId",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("- All -")]),
                  _vm._v(" "),
                  _vm._l(_vm.clients, function(item) {
                    return _c("option", { domProps: { value: item.id } }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("div", { staticClass: "custom-control custom-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.listAllClients,
                      expression: "listAllClients"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", id: "switchClientStatusAll" },
                  domProps: {
                    checked: Array.isArray(_vm.listAllClients)
                      ? _vm._i(_vm.listAllClients, null) > -1
                      : _vm.listAllClients
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.listAllClients,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.listAllClients = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.listAllClients = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.listAllClients = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "switchClientStatusAll" }
                  },
                  [_vm._v("List inactive clients")]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("Adset:")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.adsetProtoId,
                      expression: "data.adsetProtoId"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.data,
                        "adsetProtoId",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [_vm._v("- All -")]),
                  _vm._v(" "),
                  _vm._l(_vm.adsetProtos, function(item) {
                    return _c("option", { domProps: { value: item.id } }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [
                _c("label", [_vm._v("Interval:")]),
                _vm._v(" "),
                _c("date-picker", {
                  staticClass: "form-control",
                  attrs: {
                    config: _vm.intervalFlatpickrConfig,
                    "error-path": "interval"
                  },
                  model: {
                    value: _vm.data.interval,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "interval", $$v)
                    },
                    expression: "data.interval"
                  }
                }),
                _vm._v(" "),
                _c("span", { attrs: { "error-path": "interval.min" } }),
                _vm._v(" "),
                _c("span", { attrs: { "error-path": "interval.max" } })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-footer text-muted" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary trans-uppercase",
              attrs: { type: "submit" }
            },
            [_vm._v("Save")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }