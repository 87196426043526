var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("error-reveal", { attrs: { error: _vm.error } }),
      _vm._v(" "),
      _c("div", { staticClass: "card shadow mb-4" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("data-grid", {
              attrs: {
                items: _vm.items,
                columns: _vm.columns,
                emptytext: "No partners.",
                additemtext: "Create partner",
                refreshtext: "Refresh"
              },
              on: {
                additem: _vm.onAddNewItem,
                itemaction: _vm.onItemAction,
                itemcolumnclick: _vm.onItemColumnClick,
                refresh: _vm.onGridRefresh
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("data-store", {
        ref: "store",
        attrs: { service: _vm.service, filter: _vm.filter, sort: _vm.sort },
        on: {
          beforeload: _vm.onBeforeLoad,
          afterload: _vm.onAfterLoad,
          dataloaded: _vm.onDataLoaded,
          loaderror: _vm.onLoadError
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header py-3" }, [
      _c("h6", { staticClass: "m-0 font-weight-bold text-primary" }, [
        _vm._v("Partners")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }