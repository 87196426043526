var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 mt-4 text-center" },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "logo",
              attrs: { src: require("../../assets/logo-med.jpg"), alt: "" }
            })
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-sm-12 col-md-6 mt-4 text-center" }, [
        _c("h5", [
          _vm._v(
            "Please contact us to validate your registration and enable your access."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }