<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 mt-4  small-centered text-center">
        <h3>Page not found</h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-2 mt-4 text-center">
        <a @click.prevent="goBack" href="" class="btn btn-primary trans-uppercase">Back</a>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-2 mt-4 text-center">
        <router-link to="/" class="btn btn-secondary trans-uppercase">Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagenotfound',
  data () {
    return {
    }
  },
  methods: {
    goBack () {
      window.history.back()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '../../styles/main';
</style>
