const _ = require('lodash')

module.exports = exports = function (Joi) {
  return {
    base: Joi.any(),
    name: 'any',
    language: {
      invalidFixedPart: '!!Érvénytelen érték'
    },
    rules: [
      {
        name: 'valuePercent',
        params: {
          required: Joi.boolean().required()
        },
        validate (params, value, state, options) {
          let required = params.required
          let fixed = parseFloat(value)
          if (isNaN(fixed)) {
            fixed = parseFloat(_.get(value, 'fixed'))
          }

          if (isNaN(fixed) && !required) {
            return null
          }

          if (isNaN(fixed)) {
            return this.createError('any.invalidFixedPart', {
              v: value
            }, state, options)
          }

          return fixed
        }
      }
    ]
  }
}
