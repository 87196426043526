var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-2" }, [
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", [_vm._v("Placements:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-control",
            domProps: { value: _vm.value.placementType },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["placementType"].concat(argsArray)
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _vm._l(_vm.placementTypes, function(item) {
              return _c(
                "option",
                { key: item.name, domProps: { value: item.name } },
                [_vm._v(_vm._s(item.title))]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.placementType" }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", [_vm._v("Ad publishing method:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-control",
            domProps: { value: _vm.value.method },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(void 0, ["method"].concat(argsArray))
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _vm._l(_vm.methods, function(item) {
              return _c(
                "option",
                { key: item.name, domProps: { value: item.name } },
                [_vm._v(_vm._s(item.title))]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("span", { attrs: { "error-path": "adManagementPolicy.method" } })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c(
          "label",
          { attrs: { "error-path": "adManagementPolicy.savedAudienceId" } },
          [_vm._v("Targeting by saved audience:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-control",
            domProps: { value: _vm.value.savedAudienceId },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["savedAudienceId"].concat(argsArray)
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _vm._l(_vm.savedAudiences, function(item) {
              return _c(
                "option",
                { key: item.id, domProps: { value: item.id } },
                [_vm._v(_vm._s(item.name))]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c(
          "label",
          { attrs: { "error-path": "adManagementPolicy.adsPixelId" } },
          [_vm._v("Tracking by ads pixel:")]
        ),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-control",
            domProps: { value: _vm.value.adsPixelId },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["adsPixelId"].concat(argsArray)
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _vm._l(_vm.adsPixels, function(item) {
              return _c(
                "option",
                { key: item.id, domProps: { value: item.id } },
                [_vm._v(_vm._s(item.name))]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", { attrs: { "error-path": "adManagementPolicy.urlTags" } }, [
          _vm._v("URL Tags:")
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.value.urlTags },
          on: {
            input: function($event) {
              var i = arguments.length,
                argsArray = Array(i)
              while (i--) argsArray[i] = arguments[i]
              return _vm.emitValue.apply(void 0, ["urlTags"].concat(argsArray))
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c(
          "label",
          { attrs: { "error-path": "adManagementPolicy.promotedUrl" } },
          [_vm._v("Promoted URL:")]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text" },
          domProps: { value: _vm.value.promotedUrl },
          on: {
            input: function($event) {
              var i = arguments.length,
                argsArray = Array(i)
              while (i--) argsArray[i] = arguments[i]
              return _vm.emitValue.apply(
                void 0,
                ["promotedUrl"].concat(argsArray)
              )
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", [_vm._v("Action cost metric:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.actionCostMetric,
                expression: "value.actionCostMetric"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.value,
                  "actionCostMetric",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _vm._l(_vm.actionCostMetricList, function(item) {
              return _c(
                "option",
                { key: item.value, domProps: { value: item.value } },
                [
                  _vm._v(_vm._s(item.name)),
                  item.custom_event_type
                    ? _c("span", [
                        _vm._v(" (" + _vm._s(item.custom_event_type) + ")")
                      ])
                    : _vm._e()
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.actionCostMetric" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", [_vm._v("Result metric:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value.resultMetric,
                expression: "value.resultMetric"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.value,
                  "resultMetric",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _vm._l(_vm.resultMetricList, function(item) {
              return _c(
                "option",
                { key: item.value, domProps: { value: item.value } },
                [
                  _vm._v(_vm._s(item.name)),
                  item.custom_event_type
                    ? _c("span", [
                        _vm._v(" (" + _vm._s(item.custom_event_type) + ")")
                      ])
                    : _vm._e()
                ]
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.resultMetric" }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-4" }, [
        _c("label", [_vm._v("\n        Free run threshold:\n      ")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "number", min: "0", step: "1" },
            domProps: { value: _vm.value.freerunThreshold },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["freerunThreshold"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c("span", { staticClass: "input-group-text" }, [
              _vm._v(_vm._s(_vm.currency))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.freerunThreshold" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-md-4" }, [
        _c("label", [_vm._v("Max avg. action cost:")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            staticClass: "form-control",
            staticStyle: { width: "100px" },
            attrs: { type: "number", min: "0", step: "0.00001" },
            domProps: { value: _vm.value.maxAvgActionCost },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["maxAvgActionCost"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c("span", { staticClass: "input-group-text" }, [
              _vm._v(_vm._s(_vm.currency))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.maxAvgActionCost" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-md-4" }, [
        _c("label", [_vm._v("Auto resume threshold:")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "number", min: "0", step: "0.00001" },
            domProps: { value: _vm.value.resumeAvgActionCost },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["resumeAvgActionCost"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _c("span", { staticClass: "input-group-text" }, [
              _vm._v(_vm._s(_vm.currency))
            ])
          ])
        ]),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.resumeAvgActionCost" }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-4" }, [
        _c("label", [_vm._v("Ad max. lifetime:")]),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "number", min: "1", step: "1" },
            domProps: { value: _vm.value.adMaxLifetimeInHour },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["adMaxLifetimeInHour"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _vm._m(0)
        ]),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.adMaxLifetimeInHour" }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group col-md-4" }, [
        _c("label", [_vm._v("No. of max. running ad:")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: {
            type: "number",
            min: "0",
            step: "1",
            name: "numberOfMaxRunningAd"
          },
          domProps: { value: _vm.value.numberOfMaxRunningAd },
          on: {
            input: function($event) {
              var i = arguments.length,
                argsArray = Array(i)
              while (i--) argsArray[i] = arguments[i]
              return _vm.emitValue.apply(
                void 0,
                ["numberOfMaxRunningAd"].concat(argsArray)
              )
            }
          }
        }),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.numberOfMaxRunningAd" }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", [_vm._v("Maximum no. of ad per adset:")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control w-25",
          attrs: {
            type: "number",
            min: "0",
            step: "1",
            name: "numberOfMaxAdPerAdset"
          },
          domProps: { value: _vm.value.numberOfMaxAdPerAdset },
          on: {
            input: function($event) {
              var i = arguments.length,
                argsArray = Array(i)
              while (i--) argsArray[i] = arguments[i]
              return _vm.emitValue.apply(
                void 0,
                ["numberOfMaxAdPerAdset"].concat(argsArray)
              )
            }
          }
        }),
        _vm._v(" "),
        _c("span", {
          attrs: { "error-path": "adManagementPolicy.numberOfMaxAdPerAdset" }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("label", [
          _vm._v('Stop ads if "Unique inline link clicks" minimum:')
        ]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control w-25",
          attrs: {
            type: "number",
            min: "1",
            step: "1",
            name: "maxUniqueInlineLinkClicks"
          },
          domProps: { value: _vm.value.maxUniqueInlineLinkClicks },
          on: {
            input: function($event) {
              var i = arguments.length,
                argsArray = Array(i)
              while (i--) argsArray[i] = arguments[i]
              return _vm.emitValue.apply(
                void 0,
                ["maxUniqueInlineLinkClicks"].concat(argsArray)
              )
            }
          }
        }),
        _vm._v(" "),
        _c("span", {
          attrs: {
            "error-path": "adManagementPolicy.maxUniqueInlineLinkClicks"
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-12" }, [
        _c("div", { staticClass: "custom-control custom-switch" }, [
          _c("input", {
            staticClass: "custom-control-input",
            attrs: { type: "checkbox", id: "switchNewAdsEnabled" },
            domProps: { checked: _vm.value.newAdsEnabled },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["newAdsEnabled"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "switchNewAdsEnabled" }
            },
            [_vm._v("Creating new ads enabled")]
          ),
          _vm._v(" "),
          _c("span", {
            attrs: { "error-path": "adManagementPolicy.newAdsEnabled" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-control custom-switch mt-1" }, [
          _c("input", {
            staticClass: "custom-control-input",
            attrs: { type: "checkbox", id: "switchAutoStopAdsEnabled" },
            domProps: { checked: _vm.value.autoStopAdsEnabled },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["autoStopAdsEnabled"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "switchAutoStopAdsEnabled" }
            },
            [_vm._v("Auto stop ads enabled")]
          ),
          _vm._v(" "),
          _c("span", {
            attrs: { "error-path": "adManagementPolicy.autoStopAdsEnabled" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-control custom-switch mt-1" }, [
          _c("input", {
            staticClass: "custom-control-input",
            attrs: { type: "checkbox", id: "switchAutoResumeAdsEnabled" },
            domProps: { checked: _vm.value.autoResumeAdsEnabled },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["autoResumeAdsEnabled"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "switchAutoResumeAdsEnabled" }
            },
            [_vm._v("Auto resume ads enabled")]
          ),
          _vm._v(" "),
          _c("span", {
            attrs: { "error-path": "adManagementPolicy.autoResumeAdsEnabled" }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "custom-control custom-switch mt-1" }, [
          _c("input", {
            staticClass: "custom-control-input",
            attrs: { type: "checkbox", id: "switchNoTrackingSpecs" },
            domProps: { checked: _vm.value.noTrackingSpecs },
            on: {
              input: function($event) {
                var i = arguments.length,
                  argsArray = Array(i)
                while (i--) argsArray[i] = arguments[i]
                return _vm.emitValue.apply(
                  void 0,
                  ["noTrackingSpecs"].concat(argsArray)
                )
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "custom-control-label",
              attrs: { for: "switchNoTrackingSpecs" }
            },
            [_vm._v("Do not add tracking specs to ads")]
          ),
          _vm._v(" "),
          _c("span", {
            attrs: { "error-path": "adManagementPolicy.noTrackingSpecs" }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v("hour(s)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }