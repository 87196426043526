
<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold text-primary"> <i class="fas fa-fw fa-cog"></i> {{title}}</h6>
    </div>

    <div class="card-body">
      <div class="">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-cache-tab" data-toggle="tab" href="#nav-cache" role="tab" aria-controls="nav-cache" aria-selected="true">Cache</a>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active p-3 border-left border-bottom border-right" id="nav-cache" role="tabpanel" aria-labelledby="nav-cache-tab">
            <settings-cache></settings-cache>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import SettingsCache from './Cache'

export default {
  name: 'settings-edit',
  components: {
    SettingsCache
  },
  data () {
    return {
      title: 'Settings'
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
