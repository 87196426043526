var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _vm.addNewEnabled
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm",
                attrs: { type: "button", name: "button" },
                on: { click: _vm.onAddNewItem }
              },
              [
                _c("i", { staticClass: "fas fa-fw fa-plus" }),
                _vm._v(" " + _vm._s(_vm.additemtext))
              ]
            )
          : _vm._e(),
        _vm._v("\n       \n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4 text-right" }, [
        _vm._v("\n       \n      "),
        _vm.refreshEnabled
          ? _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-sm",
                attrs: { type: "button", name: "button" },
                on: { click: _vm.onRefresh }
              },
              [
                _c("i", { staticClass: "fas fa-fw fa-sync-alt" }),
                _vm._v(" " + _vm._s(_vm.refreshtext))
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    !_vm.items || !_vm.items.length
      ? _c("div", { staticClass: "text-center m-2" }, [
          _vm._v(_vm._s(_vm.emptytext))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.items && _vm.items.length
      ? _c(
          "table",
          {
            staticClass:
              "table table-hover table-responsive-sm table-bordered table-striped table-sm",
            attrs: { width: "100%", cellspacing: "0" }
          },
          [
            _c("thead", [
              _c(
                "tr",
                [
                  _vm.enableRowSelect
                    ? _c(
                        "th",
                        { staticClass: "text-center", attrs: { width: "30" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.masterRowCheck,
                                expression: "masterRowCheck"
                              }
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.masterRowCheck)
                                ? _vm._i(_vm.masterRowCheck, null) > -1
                                : _vm.masterRowCheck
                            },
                            on: {
                              click: function($event) {
                                return _vm.onRowSelectCheckboxClick()
                              },
                              change: function($event) {
                                var $$a = _vm.masterRowCheck,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.masterRowCheck = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.masterRowCheck = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.masterRowCheck = $$c
                                }
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.columns, function(column) {
                    return _c("th", {
                      class: {
                        "text-left": _vm.getColumnTitleAlign(column) === "left",
                        "text-center":
                          _vm.getColumnTitleAlign(column) === "center",
                        "text-right":
                          _vm.getColumnTitleAlign(column) === "right"
                      },
                      attrs: { width: column.width, align: column.align },
                      domProps: {
                        innerHTML: _vm._s(_vm.getColumnTitle(column))
                      }
                    })
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.items, function(item) {
                return _c(
                  "tr",
                  {
                    class: _vm.getMetaClassName(item),
                    on: {
                      click: function($event) {
                        return _vm.onItemClick(item)
                      }
                    }
                  },
                  [
                    _vm.enableRowSelect
                      ? _c("td", { staticClass: "text-center" }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            on: {
                              click: function($event) {
                                return _vm.onRowSelectCheckboxClick()
                              }
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.columns, function(column) {
                      return _c(
                        "td",
                        {
                          class: {
                            "text-left": column.align === "left",
                            "text-center": column.align === "center",
                            "text-right": column.align === "right"
                          },
                          attrs: { width: column.width, valign: column.valign },
                          on: {
                            click: function($event) {
                              return _vm.onItemColumnClick(item, column)
                            }
                          }
                        },
                        [
                          column.name
                            ? _c("span", {
                                staticClass: "content-cell",
                                class: { clickable: column.clickable },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.getColumnValue(item, column.name)
                                  )
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          column.actions
                            ? _c(
                                "span",
                                { staticClass: "actions" },
                                _vm._l(column.actions, function(action) {
                                  return _c("span", { staticClass: "action" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary btn-xs",
                                        class: action.class,
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            return _vm.onItemAction(
                                              action,
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [
                                        action.html
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(action.html)
                                              }
                                            })
                                          : _vm._e(),
                                        action.text
                                          ? _c("span", [
                                              _vm._v(_vm._s(action.text))
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            : _vm._e()
                        ]
                      )
                    })
                  ],
                  2
                )
              }),
              0
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }