var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal fade", attrs: { id: _vm.domUid } },
    [
      _c(
        "joi-form",
        {
          attrs: { data: _vm.data, schema: _vm.validationSchema },
          on: { formdataready: _vm.save }
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("×")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "form-row" }, [
                  _c(
                    "div",
                    { staticClass: "form-group col-md-12" },
                    [_c("error-callout", { attrs: { error: _vm.error } })],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-12" }, [
                    _c("label", [_vm._v("Name:")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.name,
                          expression: "data.name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "name" },
                      domProps: { value: _vm.data.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "name", $event.target.value)
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-group col-md-12" }, [
                    _c("label", [_vm._v("Access token:")]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.accessToken,
                          expression: "data.accessToken"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { name: "accessToken", rows: "5" },
                      domProps: { value: _vm.data.accessToken },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.data, "accessToken", $event.target.value)
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card-footer text-muted" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary trans-uppercase",
                    attrs: { type: "submit" }
                  },
                  [_vm._v("Save")]
                )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }