<template>
  <div>
    <error-reveal :error="error"></error-reveal>
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Users</h6>
      </div>
      <div class="card-body">
        <data-grid
          :items="items"
          :columns="columns"
          :addNewEnabled="false"
          emptytext="No users."
          refreshtext="Refresh"
          @itemaction="onItemAction"
          @refresh="onGridRefresh"
        ></data-grid>
      </div>
    </div>
    <data-store
      ref="store"
      :service="service"
      :filter="filter"
      :sort="sort"
      @beforeload="onBeforeLoad"
      @afterload="onAfterLoad"
      @dataloaded="onDataLoaded"
      @loaderror="onLoadError"
    ></data-store>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import DataGrid from '../../lists/DataGrid'
import DataStore from '../../data/Store'
import ErrorReveal from '../../errors/Reveal'
import userService from '../../services/user'
import i18nService from '../../services/i18n'
import Confirm from '../../mixins/Confirm'

export default {
  name: 'feeds-list',
  mixins: [Confirm],
  components: {
    DataGrid,
    DataStore,
    ErrorReveal
  },
  data () {
    return {
      items: [],
      columns: [{
        title: 'Name',
        name: 'displayName'
      }, {
        title: 'Access',
        name: 'access',
        width: 150
      }, {
        title: 'Registration time',
        name: 'createdAt',
        width: 150,
        align: 'center'
      }, {
        actions: [{
          name: 'toggleAccess',
          html: '<i class="fas fa-sm fa-exchange-alt"></i>'
        }, {
          name: 'delete',
          html: '<i class="fas fa-sm fa-user-times"></i>',
          class: 'ml-1'
        }],
        width: 100,
        align: 'center'
      }],
      service: userService.list,
      filter: null,
      sort: null,
      error: null
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onBeforeLoad () {
      this[mutationTypes.BUSY_ON]()
    },
    onAfterLoad () {
      this[mutationTypes.BUSY_OFF]()
    },
    onDataLoaded (items, result) {
      this.items = items.filter((item) => item.id !== this.user.id).map(this.parseItem)
    },
    onLoadError (error) {
      this[mutationTypes.BUSY_OFF]()
      this.error = error
    },
    onItemAction (action, item) {
      if (action.name === 'delete') {
        this.onDeleteUser(item)
      }
      if (action.name === 'toggleAccess') {
        this.onToggleAccess(item)
      }
    },
    onGridRefresh () {
      this.$refs.store.load()
    },
    onDeleteUser (item) {
      this.confirm('Delete', 'Are you sure?').then((confirmed) => {
        if (confirmed) {
          this[mutationTypes.BUSY_ON]()
          userService.remove(item.id).then((data) => {
            this[mutationTypes.BUSY_OFF]()
            this.onGridRefresh()
          })
          .catch(() => {
            this[mutationTypes.BUSY_OFF]()
          })
        }
      })
    },
    onToggleAccess (item) {
      this[mutationTypes.BUSY_ON]()
      userService.toggleAccess(item.id).then((data) => {
        this[mutationTypes.BUSY_OFF]()
        this.onGridRefresh()
      })
      .catch(() => {
        this[mutationTypes.BUSY_OFF]()
      })
    },
    parseItem (rawData) {
      return {
        id: rawData.id,
        displayName: rawData.displayName,
        access: this.renderAccess(rawData.accessGranted),
        createdAt: i18nService.formatDateTime(rawData.createdAt, i18nService.FMT_SHORT, i18nService.FMT_SHORT)
      }
    },
    renderAccess (accessGranted) {
      return accessGranted ? 'Granted' : 'Banned'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
