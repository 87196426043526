var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "alert alert-danger alert-dismissible fade d-none",
      attrs: { role: "error-information" }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.message) + "\n  "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button", "aria-label": "Close" },
          on: {
            click: function($event) {
              return _vm.hide()
            }
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }