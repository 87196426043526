<template>
  <div>
    <error-reveal :error="error"></error-reveal>
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <router-link :to="{name: 'partners'}" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</router-link>
        <h6 class="m-0 mt-1 font-weight-bold text-primary" v-if="partner">{{partner.name}} <i class="fas fa-fw fa-angle-double-right"></i> Clients</h6>
      </div>
      <div class="card-body">
        <card-list
          :items="items"
          emptytext="No clients."
          additemtext="Create client"
          refreshtext="Refresh"
          @additem="onAddNewItem"
          @refresh="onGridRefresh"
        >
          <template v-slot:titleHead>
            <div class="">
              <select v-model="statusFilter" class="custom-select custom-select-sm">
                <option value="active">Only active clients</option>
                <option value="inactive">Only inactive clients</option>
                <option value="all">All clients</option>
              </select>
            </div>
          </template>
          <template v-slot:card="slotProps">
            <div class="card-body" v-bind:class="{'opacity-50': !slotProps.item.active}">
              <div class="row">
                <div class="col-md-6">
                   <span class="card-title clickable h5" @click="onItemAction('adsetprotos', slotProps.item)">{{slotProps.item.name}}</span>
                </div>
                <div class="col-md-6 text-right">
                  <button type="button" class="btn btn-primary btn-xs" @click="onItemAction('edit', slotProps.item)">
                    <span><i class="fas fa-sm fa-edit"></i></span>
                  </button>
                  <button type="button" class="btn btn-primary btn-xs" @click="onItemAction('view-analytics', slotProps.item)">
                    <span><i class="fas fa-sm fa-chart-bar"></i></span>
                  </button>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <p class="small  mb-1 text-error text-danger" v-if="slotProps.item.isPolitical"><b>POLITICAL</b></p>
                  <p class="small text-muted mb-1"><span v-html="slotProps.item.currentCampaignInfo"></span></p>
                  <p class="small text-muted mb-1"><span v-html="slotProps.item.adsStats"></span></p>
                  <p class="small mb-0">
                    <a v-if="slotProps.item.active" href="#" class="" @click.prevent="onItemAction('inactivate', slotProps.item)"><i class="fas fa-sm fa-pause-circle"></i> Inactivate</a>
                    <a v-if="!slotProps.item.active" href="#" class="" @click.prevent="onItemAction('activate', slotProps.item)"><i class="fas fa-sm fa-play-circle"></i> Activate</a>
                  </p>
                  <p class="small mt-1 mb-0" v-if="slotProps.item.currentCampaignId">
                     <a href="#" class="" @click.prevent="onItemAction('download', slotProps.item)"><i class="fas fa-sm fa-download"></i> Download performance</a>
                  </p>
                  <p class="small mt-1 mb-0" v-if="slotProps.item.currentCampaignId">
                     <a href="#" class="" @click.prevent="onItemAction('view-campaign-analytics', slotProps.item)"><i class="fas fa-sm fa-chart-bar"></i> View campaign analytics</a>
                  </p>
                </div>
              </div>
            </div>
          </template>
        </card-list>
      </div>
    </div>
    <data-store
      ref="store"
      :service="service"
      :filter="filters"
      :sort="sort"
      :autoload="false"
      @beforeload="onBeforeLoad"
      @afterload="onAfterLoad"
      @dataloaded="onDataLoaded"
      @loaderror="onLoadError"
    ></data-store>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import CardList from '../../lists/CardList'
import DataStore from '../../data/Store'
import ErrorReveal from '../../errors/Reveal'
import clientService from '../../services/client'
import PartnerRequired from '../../mixins/PartnerRequired'
import SafeString from '../../utils/SafeString'
// import TimespanSpends from '../../utils/TimespanSpends'
import i18nService from '../../services/i18n'
import Confirm from '../../mixins/Confirm'

function defaultFilters () {
  return { active: true }
}

export default {
  name: 'clients-list',
  mixins: [PartnerRequired, Confirm],
  components: {
    CardList,
    DataStore,
    ErrorReveal
  },
  data () {
    return {
      partner: null,
      items: [],
      service: null,
      filters: defaultFilters(),
      statusFilter: 'active',
      sort: null,
      error: null
    }
  },
  watch: {
    statusFilter: 'onStatusFilterChanged'
  },
  created: function () {
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onPartnerLoaded (error, partner) {
      this.error = error
      if (partner) {
        this.partner = partner
        this.service = () => {
          return clientService.list({...this.filters, partner: partner.id})
        }
      } else {
        this.partner = null
        this.filters = defaultFilters()
        this.service = null
      }
    },
    onStatusFilterChanged (newStatusFilter) {
      switch (newStatusFilter) {
        case 'active':
          this.filters.active = true
          break
        case 'inactive':
          this.filters.active = false
          break
        case 'all':
        default:
          this.filters.active = undefined
          break

      }
      this.onGridRefresh()
    },
    onAddNewItem () {
      this.$router.push({name: 'partner-client-edit'})
    },
    onBeforeLoad () {
      this[mutationTypes.BUSY_ON]()
    },
    onAfterLoad () {
      this[mutationTypes.BUSY_OFF]()
    },
    onDataLoaded (items, result) {
      this.items = items.map(this.parseItem)
    },
    onLoadError (error) {
      this[mutationTypes.BUSY_OFF]()
      this.error = error
    },
    onItemAction (action, item) {
      if (action === 'edit') {
        this.$router.push({name: 'partner-client-edit', params: {partnerId: this.partner.id, id: item.id}})
      }
      if (action === 'view-analytics') {
        this.$router.push({name: 'partner-client-analytics', params: {partnerId: this.partner.id, id: item.id}})
      }
      if (action === 'adsetprotos') {
        this.$router.push({name: 'client-adsetproto-list', params: {clientId: item.id}})
      }
      if (action === 'download') {
        clientService.downloadCurrentCampaignPerformance(item.id)
      }
      if (action === 'activate') {
        this.activate(item.id)
      }
      if (action === 'inactivate') {
        this.inactivate(item.id)
      }
      if (action === 'view-campaign-analytics') {
        this.$router.push({name: 'partner-client-campaign-analytics', params: {partnerId: this.partner.id, clientId: item.id, campaignId: item.currentCampaignId}})
      }
    },
    onGridRefresh () {
      this.$refs.store.load()
    },
    parseItem (rawData) {
      return {
        id: rawData.id,
        name: rawData.name,
        // contactName: rawData.contactName,
        // contactPhone: rawData.contactPhone,
        // monthlyBudget: i18nService.formatMoneyAmount(_.get(rawData, 'monthlyBudget', 0)),
        // dailyBudget: i18nService.formatMoneyAmount(_.get(rawData, 'dailyBudget', 0)),
        // monthlySpendsInfo: this.renderSpendsInfo(rawData, 'month'),
        // dailySpendsInfo: this.renderSpendsInfo(rawData, 'day'),
        currentCampaignId: _.get(rawData, 'currentCampaign.id'),
        currentCampaignInfo: this.renderCurrentCampaignInfo(rawData),
        adsStats: this.renderAdsStats(rawData),
        active: rawData.active,
        isPolitical: rawData.isPolitical
      }
    },
    renderCurrentCampaignInfo (rawData) {
      if (!rawData.currentCampaign) {
        return '<b>FB campaign:</b> -'
      }

      let campaign = rawData.currentCampaign

      return `<b>FB campaign:</b> ${campaign.name} | ${i18nService.formatDateInterval(campaign.interval)} | ID:${_.get(campaign, 'external.fbCampaignId', 'n/a')}`
    },
    renderAdsStats (rawData) {
      if (!rawData.currentCampaign) {
        return '<b>Ads:</b> -'
      }

      let stats = rawData.stats.ads

      return `<b>Ads:</b> ${stats.total} / ${stats.running}`
    },
    activate (id) {
      this.confirm('Activate', new SafeString('Are you sure?')).then((confirmed) => {
        if (confirmed) {
          this[mutationTypes.BUSY_ON]()
          clientService.activate(id).then((result) => {
            this[mutationTypes.BUSY_OFF]()
            this.onGridRefresh()
          }).catch((err) => {
            this[mutationTypes.BUSY_OFF]()
            this.error = err
          })
        }
      })
    },
    inactivate (id) {
      this.confirm('Inactivate', new SafeString('Are you sure?')).then((confirmed) => {
        if (confirmed) {
          this[mutationTypes.BUSY_ON]()
          clientService.inactivate(id).then((result) => {
            this[mutationTypes.BUSY_OFF]()
            this.onGridRefresh()
          }).catch((err) => {
            this[mutationTypes.BUSY_OFF]()
            this.error = err
          })
        }
      })
    }
    // precisionRound (number, precision) {
    //   var factor = Math.pow(10, precision)
    //   return Math.round(number * factor) / factor
    // },
    // renderSpendsInfo (rawData, key) {
    //   return new SafeString(TimespanSpends.renderSpendsInfoForKey(_.get(rawData, 'stats.timespanSpends'), key, rawData.stats.campaigns.live > 0))
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
