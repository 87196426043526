<template>
</template>

<script>
import Promise from 'bluebird'

export default {
  name: 'data-store',
  props: {
    autoload: {
      default: true,
      type: Boolean
    },
    service: {
      type: Function
    },
    filter: {
      type: Object
    },
    sort: {
      type: Object
    },
    limit: {
      type: Number
    },
    page: {
      type: Number
    }
  },
  data () {
    return {
    }
  },
  watch: {
    service: function () {
      this.load()
    },
    filter: function (newVal) {
    }
  },
  created () {
    if (this.autoload && this.service) {
      this.load()
    }
  },
  methods: {
    load () {
      this.$emit('beforeload')
      this.runService()
        .then(this.processResult)
        .catch(this.processError)
    },
    collectServiceParams () {
      return {
        filter: this.filter,
        sort: this.sort,
        limit: this.limit,
        page: this.page
      }
    },
    runService () {
      if (!this.service) {
        return Promise.reject(new Error('Unknown service'))
      }
      return this.service(this.collectServiceParams())
    },
    processResult (result) {
      // var items = result[result.key]
      var items = result
      this.$emit('dataloaded', items, result)
      this.$emit('afterload')
    },
    processError (error) {
      this.$emit('loaderror', error)
    }
  }
}
</script>
