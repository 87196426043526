<script>
import api from './api'
export default {
  restartAd (fbAdId) {
    return api.put(`ads/${fbAdId}/restart`)
  },
  stopAd (fbAdId) {
    return api.put(`ads/${fbAdId}/stop`)
  }
}
</script>
