<script>
import _ from 'lodash'
import merge from 'merge'
import numeral from 'numeral'
import moment from 'moment'
import {hu as flatpickrHungarian} from 'flatpickr/dist/l10n/hu.js'
flatpickrHungarian.rangeSeparator = ' - '

moment.locale('hu')

export default {
  FMT_SHORT: 'short',
  formatDate (dt, dateFmt) {
    return dt ? moment(dt).format('L') : ''
  },
  formatMonth (dt, dateFmt) {
    return dt ? moment(dt).format('YYYY.MM') : ''
  },
  formatDateTime (dt, dateFmt, timeFmt) {
    return dt ? moment(dt).format('L LT') : ''
  },
  formatDateInterval (interval) {
    return moment(interval.from).format('L') + ' - ' + moment(interval.to).format('L')
  },
  duration (start, end) {
    var duration = moment.duration(moment(end).diff(moment(start)))
    return duration.humanize()
  },
  interval (start, end) {
    if (end) {
      return this.formatDateTime(end, this.FMT_SHORT, this.FMT_SHORT) +
        ' (időtartam: ' + this.duration(start, end) + ')'
    } else if (start) {
      return this.formatDateTime(start, this.FMT_SHORT, this.FMT_SHORT) + ' - ?'
    } else {
      return '-'
    }
  },
  formatSum (sum, precision) {
    return numeral(sum).format(
      precision === undefined
      ? '0,0'
      : ('0,0.' + _.repeat('0', precision)))
  },
  formatMoneyAmount (amount, precision) {
    return numeral(amount).format(
      precision === undefined
      ? '0,0'
      : ('0,0.' + _.repeat('0', precision)))
  },
  flatpickrConfig (customConfig) {
    return merge({
      locale: flatpickrHungarian,
      dateFormat: 'Y.m.d',
      time_24hr: true
    }, customConfig)
  }
}
</script>
