var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "joi-form",
        {
          ref: "form",
          attrs: { data: _vm.data, schema: _vm.validationSchema }
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "form-group col-md-6" },
              [
                _c("error-reveal", { attrs: { error: _vm.error } }),
                _vm._v(" "),
                _c("info-reveal", { attrs: { message: _vm.info } })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", [_vm._v("FB Ad ID:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data.fbAdId,
                    expression: "data.fbAdId"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "fbAdId" },
                domProps: { value: _vm.data.fbAdId },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.data, "fbAdId", $event.target.value)
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary trans-uppercase",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.restartAd()
                    }
                  }
                },
                [_vm._v("Restart ad")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-danger trans-uppercase",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.stopAd()
                    }
                  }
                },
                [_vm._v("Stop ad")]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }