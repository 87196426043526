<template>
  <div>
    <error-reveal :error="error"></error-reveal>
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Partners</h6>
      </div>
      <div class="card-body">
        <data-grid
          :items="items"
          :columns="columns"
          emptytext="No partners."
          additemtext="Create partner"
          refreshtext="Refresh"
          @additem="onAddNewItem"
          @itemaction="onItemAction"
          @itemcolumnclick="onItemColumnClick"
          @refresh="onGridRefresh"
        ></data-grid>
      </div>
    </div>
    <data-store
      ref="store"
      :service="service"
      :filter="filter"
      :sort="sort"
      @beforeload="onBeforeLoad"
      @afterload="onAfterLoad"
      @dataloaded="onDataLoaded"
      @loaderror="onLoadError"
    ></data-store>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import DataGrid from '../../lists/DataGrid'
import DataStore from '../../data/Store'
import ErrorReveal from '../../errors/Reveal'
import partnerService from '../../services/partner'
export default {
  name: 'partners-list',
  components: {
    DataGrid,
    DataStore,
    ErrorReveal
  },
  data () {
    return {
      items: [],
      columns: [{
        title: 'Name',
        name: 'name',
        valign: 'top',
        clickable: true
      }, {
        title: 'Contact name',
        name: 'contactName',
        align: 'left',
        width: 200
      }, {
        title: 'Contact phone',
        name: 'contactPhone',
        align: 'left',
        width: 200
      }, {
        actions: [{
          name: 'edit',
          html: '<i class="fas fa-sm fa-edit"></i>'
        }, {
          name: 'settings',
          html: '<i class="fas fa-sm fa-tools"></i>',
          class: 'ml-1'
        }, {
          name: 'analytics',
          html: '<i class="fas fa-sm fa-chart-bar"></i>',
          class: 'ml-1'
        }],
        width: 112,
        align: 'center'
      }],
      service: partnerService.list,
      filter: null,
      sort: null,
      error: null
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onAddNewItem () {
      this.$router.push({name: 'partner-edit'})
    },
    onBeforeLoad () {
      this[mutationTypes.BUSY_ON]()
    },
    onAfterLoad () {
      this[mutationTypes.BUSY_OFF]()
    },
    onDataLoaded (items, result) {
      this.items = items.map(this.parseItem)
    },
    onLoadError (error) {
      this[mutationTypes.BUSY_OFF]()
      this.error = error
    },
    onItemAction (action, item) {
      if (action.name === 'edit') {
        this.$router.push({name: 'partner-edit', params: {id: item.id}})
      }
      if (action.name === 'settings') {
        this.$router.push({name: 'partner-settings', params: {partnerId: item.id}})
      }
      if (action.name === 'analytics') {
        this.$router.push({name: 'partner-analytics', params: {partnerId: item.id}})
      }
    },
    onItemColumnClick (item, column) {
      if (column.name === 'name') {
        this.$router.push({name: 'partner-client-list', params: {partnerId: item.id}})
      }
    },
    onGridRefresh () {
      this.$refs.store.load()
    },
    onDownloadPerformance (item) {
      partnerService.download(item.id)
    },
    parseItem (rawData) {
      return {
        id: rawData.id,
        name: rawData.name,
        contactName: rawData.contactName,
        contactPhone: rawData.contactPhone
      }
    },
    precisionRound (number, precision) {
      var factor = Math.pow(10, precision)
      return Math.round(number * factor) / factor
    },
    renderAds (rawData) {
      var ads = rawData.stats.ads
      return `${ads.total} / ${ads.active}`
    },
    renderBuffer (rawData) {
      var buffer = rawData.stats.buffer
      return buffer ? `${buffer.total} / ${buffer.processed}` : '-'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // @import '../../../sass/foundation-import';
  // @import '../../../sass/layouts/list';
</style>
