var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav ml-auto" }, [
        _c("li", { staticClass: "nav-item dropdown no-arrow" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: {
                href: "#",
                id: "userDropdown",
                role: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false"
              }
            },
            [
              _c(
                "span",
                { staticClass: "mr-2 d-none d-lg-inline text-gray-600 small" },
                [_vm._v(_vm._s(this.user.displayName))]
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "img-profile rounded-circle",
                attrs: { src: this.user.profilePictureUrl }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "dropdown-menu dropdown-menu-right shadow animated--grow-in",
              attrs: { "aria-labelledby": "userDropdown" }
            },
            [
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: {
                    href: "#",
                    "data-toggle": "modal",
                    "data-target": "#logoutModal"
                  },
                  on: { click: _vm.logout }
                },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                  }),
                  _vm._v("\n          Logout\n        ")
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-link d-md-none rounded-circle mr-3",
        attrs: { id: "sidebarToggleTop" }
      },
      [_c("i", { staticClass: "fa fa-bars" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }