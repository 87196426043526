var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$breadcrumbs.length
    ? _c(
        "nav",
        { attrs: { "aria-label": "You are here:", role: "navigation" } },
        [
          _c(
            "ul",
            { staticClass: "breadcrumbs" },
            _vm._l(_vm.$breadcrumbs, function(crumb, idx) {
              return _c(
                "li",
                [
                  idx == _vm.$breadcrumbs.length - 1
                    ? _c("span", [
                        _c("span", { staticClass: "show-for-sr" }, [
                          _vm._v("Current: ")
                        ]),
                        _vm._v(" " + _vm._s(_vm.getItemText(crumb)))
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  idx < _vm.$breadcrumbs.length - 1
                    ? _c("router-link", { attrs: { to: crumb.path } }, [
                        _vm._v(_vm._s(_vm.getItemText(crumb)))
                      ])
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }