var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "form-row" }, [
      _c("div", { staticClass: "form-group col-md-6" }, [
        _c("label", [_vm._v("Scheduling:")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.data.type,
                expression: "data.type"
              }
            ],
            staticClass: "form-control",
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.data,
                  "type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              }
            }
          },
          [
            _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "static" } }, [_vm._v("Static")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "periodic" } }, [_vm._v("Periodic")])
          ]
        ),
        _vm._v(" "),
        _c("span", { attrs: { "error-path": _vm.errorPathPrefix + "type" } })
      ]),
      _vm._v(" "),
      _vm.data.type === "static"
        ? _c(
            "div",
            { staticClass: "form-group col-md-6" },
            [
              _c("label", [_vm._v("Interval:")]),
              _vm._v(" "),
              _c("date-picker", {
                key: "staticDatePicker",
                staticClass: "form-control",
                attrs: { config: _vm.staticFlatpickrConfig },
                model: {
                  value: _vm.data.static.interval,
                  callback: function($$v) {
                    _vm.$set(_vm.data.static, "interval", $$v)
                  },
                  expression: "data.static.interval"
                }
              }),
              _vm._v(" "),
              _c("span", {
                attrs: { "error-path": "data.static.interval.from" }
              }),
              _vm._v(" "),
              _c("span", { attrs: { "error-path": "data.static.interval.to" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "periodic"
        ? _c(
            "div",
            { staticClass: "form-group col-md-3" },
            [
              _c("label", [_vm._v("Start:")]),
              _vm._v(" "),
              _c("date-picker", {
                key: "periodicDatePicker",
                staticClass: "form-control",
                attrs: { config: _vm.periodicFlatpickrConfig },
                model: {
                  value: _vm.data.periodic.start,
                  callback: function($$v) {
                    _vm.$set(_vm.data.periodic, "start", $$v)
                  },
                  expression: "data.periodic.start"
                }
              }),
              _vm._v(" "),
              _c("span", { attrs: { "error-path": "data.periodic.start" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.data.type === "periodic"
        ? _c("div", { staticClass: "form-group col-md-3" }, [
            _c("label", [_vm._v("Period:")]),
            _vm._v(" "),
            _c("div", { staticClass: "row no-gutters" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.periodic.count,
                      expression: "data.periodic.count"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "number" },
                  domProps: { value: _vm.data.periodic.count },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.data.periodic, "count", $event.target.value)
                    }
                  }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.data.periodic.unit,
                        expression: "data.periodic.unit"
                      }
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.data.periodic,
                          "unit",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                    _vm._v(" "),
                    _vm._l(_vm.periodUnits, function(item) {
                      return _c(
                        "option",
                        { key: item.name, domProps: { value: item.name } },
                        [_vm._v(_vm._s(item.title))]
                      )
                    })
                  ],
                  2
                )
              ])
            ]),
            _vm._v(" "),
            _c("span", { attrs: { "error-path": "data.periodic.start" } })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }