
<template>
  <div :id="domUid" class="modal fade">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Adset buffer</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <error-callout :error="error"></error-callout>
              <info-callout :message="info"></info-callout>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <div class="">
                <nav>
                  <div class="nav nav-tabs" id="buffer-dialog-tab" role="tablist">
                    <a class="nav-item nav-link active" id="nav-bufferdialog-items-tab" data-toggle="tab" href="#nav-bufferdialog-items" role="tab" aria-controls="nav-bufferdialog-items" aria-selected="true">Items</a>
                    <a class="nav-item nav-link" id="nav-bufferdialog-externalfeed-tab" data-toggle="tab" href="#nav-bufferdialog-externalfeed" role="tab" aria-controls="nav-bufferdialog-externalfeed" aria-selected="false">External feed</a>
                    <a class="nav-item nav-link" id="nav-bufferdialog-pageposts-tab" data-toggle="tab" href="#nav-bufferdialog-pageposts" role="tab" aria-controls="nav-bufferdialog-pageposts" aria-selected="false">Page posts</a>
                    <a class="nav-item nav-link" id="nav-bufferdialog-uploadfile-tab" data-toggle="tab" href="#nav-bufferdialog-uploadfile" role="tab" aria-controls="nav-bufferdialog-uploadfile" aria-selected="false">Upload file</a>
                  </div>
                </nav>
                <div class="tab-content" id="buffer-dialog-tabContent">
                  <div class="tab-pane fade show active p-3 border-left border-bottom border-right" id="nav-bufferdialog-items" role="tabpanel" aria-labelledby="nav-bufferdialog-items-tab">
                    <buffer-item-list :adsetprotoId="adsetprotoId"></buffer-item-list>
                  </div>
                  <div class="tab-pane fade p-3 border-left border-bottom border-right" id="nav-bufferdialog-externalfeed" role="tabpanel" aria-labelledby="nav-bufferdialog-externalfeed-tab">
                    <joi-form :data="data[sourceType.FEED]" :schema="validationSchema" v-on:formdataready="saveExternalFeedSource">
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label>Input Feed URL:</label>
                          <input type="text" v-model="data[sourceType.FEED].params.url" name="params.url" class="form-control">
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
                          <button type="button" class="btn btn-danger trans-uppercase float-right" v-if="data[sourceType.FEED].id" @click="deleteSource(sourceType.FEED, data[sourceType.FEED].id)">Delete</button>
                        </div>
                      </div>
                    </joi-form>
                  </div>
                  <div class="tab-pane fade p-3 border-left border-bottom border-right" id="nav-bufferdialog-pageposts" role="tabpanel" aria-labelledby="nav-bufferdialog-pageposts-tab">
                    <joi-form :data="data[sourceType.PAGEPOSTS]" :schema="validationSchema" v-on:formdataready="savePagePostsSource">
                      <div class="form-row">
                        <div class="form-group col-md-8">
                          <label>Facebook pages:</label>
                          <page-input :pages="pages" v-model="data[sourceType.PAGEPOSTS].params.pages" error-path="params.pages"></page-input>
                        </div>
                        <div class="form-group col-md-4">
                          <label>Maximum num of items:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.maxNumOfItems" name="params.maxNumOfItems" type="number" min="1" step="1" class="form-control">
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label>Post types:</label>
                          <div class="form-check form-check-inline ml-2" v-for="postType in pagePostsTypes" v-bind:key="postType.name">
                            <input class="form-check-input" v-model="data[sourceType.PAGEPOSTS].params.postTypes" type="checkbox" :id="`cbPostType${postType.name}`" :value="postType.name">
                            <label class="form-check-label" :for="`cbPostType${postType.name}`">{{postType.title}}</label>
                          </div>
                          <span error-path="params.postTypes"></span>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-3">
                          <label>Strategy:</label>
                          <select v-model="data[sourceType.PAGEPOSTS].params.strategy" name="params.strategy" class="form-control">
                            <option value="">-</option>
                            <option value="thresholds">Thresholds</option>
                            <option value="top">Top posts</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-row" v-if="data[sourceType.PAGEPOSTS].params.strategy === 'thresholds'">
                        <div class="form-group col-md-3">
                          <label>Span:</label>
                          <div class="input-group">
                            <input class="form-control" type="number" min="0" max="72" step="1" name="params.thresholds.spanHours" v-model="data[sourceType.PAGEPOSTS].params.thresholds.spanHours">
                            <div class="input-group-append">
                              <span class="input-group-text">hour(s)</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row" v-if="data[sourceType.PAGEPOSTS].params.strategy === 'thresholds'">
                        <div class="form-group col-md-2">
                          <label>Like:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.thresholds.like" name="params.thresholds.like" type="number" min="0" step="1" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                          <label>Share:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.thresholds.share" name="params.thresholds.share" type="number" min="0" step="1" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                          <label>Comment:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.thresholds.comment" name="params.thresholds.comment" type="number" min="0" step="1" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                          <label>Reach:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.thresholds.reach" name="params.thresholds.reach" type="number" min="0" step="1" class="form-control">
                        </div>
                        <div class="form-group col-md-2">
                          <label>Click:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.thresholds.click" name="params.thresholds.click" type="number" min="0" step="1" class="form-control">
                        </div>
                      </div>

                      <div class="form-row" v-if="data[sourceType.PAGEPOSTS].params.strategy === 'top'">
                        <div class="form-group col-md-2">
                          <label>Top N:</label>
                          <input v-model="data[sourceType.PAGEPOSTS].params.top.numOf" name="params.top.numOf" type="number" min="1" step="1" class="form-control">
                        </div>
                        <div class="form-group col-md-3">
                          <label>Order by:</label>
                          <select v-model="data[sourceType.PAGEPOSTS].params.top.orderBy" name="params.top.orderBy" class="form-control">
                            <option value="">-</option>
                            <option value="like">Like</option>
                            <option value="share">Share</option>
                            <option value="comment">Comment</option>
                            <option value="reach">Reach</option>
                            <option value="click">Click</option>
                          </select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Span:</label>
                          <div class="input-group">
                            <input class="form-control" type="number" min="0" max="72" step="1" name="params.top.spanHours" v-model="data[sourceType.PAGEPOSTS].params.top.spanHours">
                            <div class="input-group-append">
                              <span class="input-group-text">hour(s)</span>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <label>Time:</label>
                          <input class="form-control" type="time" name="params.top.referTime" v-model="data[sourceType.PAGEPOSTS].params.top.referTime">
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label>Link type:</label>
                          <select v-model="data[sourceType.PAGEPOSTS].params.linkType" name="params.linkType" class="form-control">
                            <option value="">-</option>
                            <option value="original">Original links</option>
                            <option value="facebook">Facebook links</option>
                          </select>
                        </div>
                        <div class="form-group col-md-6">
                          <label>Contents type:</label>
                          <select v-model="data[sourceType.PAGEPOSTS].params.contentsType" name="params.contentsType" class="form-control">
                            <option value="">-</option>
                            <option value="feed">Feed</option>
                            <option value="remote">Remote</option>
                          </select>
                        </div>
                      </div>

                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <label>URL parameters:</label>
                          <input type="text" v-model="data[sourceType.PAGEPOSTS].params.urlParameters" name="params.urlParameters" class="form-control">
                        </div>
                      </div>

                      <div class="form-row" v-for="filter in pagePostsTextFilters" v-bind:key="filter.name">
                        <div class="form-group col-md-6">
                          <label>{{filter.title}} contains:</label>
                          <textarea class="form-control" v-model="data[sourceType.PAGEPOSTS].params.filters[filter.name].contains"></textarea>
                        </div>
                        <div class="form-group col-md-6">
                          <label>{{filter.title}} DOES NOT contain:</label>
                          <textarea class="form-control" v-model="data[sourceType.PAGEPOSTS].params.filters[filter.name].notContains"></textarea>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <button type="submit" class="btn btn-primary trans-uppercase">Save</button>
                          <button type="button" class="btn btn-danger trans-uppercase float-right" v-if="data[sourceType.PAGEPOSTS].id" @click="deleteSource(sourceType.PAGEPOSTS, data[sourceType.PAGEPOSTS].id)">Delete</button>
                        </div>
                      </div>
                    </joi-form>
                  </div>
                  <div class="tab-pane fade p-3 border-left border-bottom border-right" id="nav-bufferdialog-uploadfile" role="tabpanel" aria-labelledby="nav-bufferdialog-uploadfile-tab">
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label>Upload contents:</label>
                        <file-upload
                          name="fileBufferContents"
                          :postAction="bufferUploadEndpoint"
                          :headers="fileUploadHeaders"
                          @uploadclick="saveSource(sourceType.FILE)"
                          ref="fileBufferContents"
                          multiple="false"
                        ></file-upload>
                        <small class="form-text text-info">Accepted formats: UTF8 encoded CSV (<a :href="sampleCSVUrl" target="_blank">download sample</a>).</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import merge from 'merge'
import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import {rewriteUrl, getHeaders} from '../../interceptors/ApiRewrite'
import SafeString from '../../utils/SafeString'
import ErrorCallout from '../../errors/Callout'
import InfoCallout from '../../containers/InfoCallout'
import adsetprotoService from '../../services/adsetproto'
import validationSchemas from '../../validators/schemas'
import JoiForm from '../../inputs/joi-form'
import FileUpload from '../../inputs/file-upload'
import PageInput from '../../inputs/page-input'
import Confirm from '../../mixins/Confirm'
import BufferItemList from './BufferItemList'

const pagePostsTypes = [
  {name: 'status', title: 'Status'},
  {name: 'link', title: 'Link'},
  {name: 'photo', title: 'Photo'},
  {name: 'video', title: 'Video'}
]

const pagePostsTextFilters = [
  {name: 'link', title: 'Link'},
  {name: 'permalink', title: 'Permalink'},
  {name: 'message', title: 'Message'},
  {name: 'metatitle', title: 'Meta title'},
  {name: 'metadescription', title: 'Meta description'},
  {name: 'ogtitle', title: 'OG Title'},
  {name: 'ogdescription', title: 'OG Description'}
]

function getSourceInitData (type) {
  let result = {
    id: null,
    type: type,
    params: {}
  }

  switch (type) {
    case CONSTANTS.CAMPAIGNSOURCE.FEED:
      result.params.url = null
    break
    case CONSTANTS.CAMPAIGNSOURCE.PAGEPOSTS:
      result.params.pages = []
      result.params.maxNumOfItems = null
      result.params.postTypes = []
      result.params.strategy = null
      result.params.thresholds = {
        spanHours: null,
        like: null,
        share: null,
        comment: null,
        reach: null,
        click: null
      },
      result.params.top = {
        numOf: null,
        orderBy: null,
        spanHours: null,
        referTime: null
      },
      result.params.linkType = null
      result.params.contentsType = null
      result.params.urlParameters = null
      result.params.filters = {}
      for (const filter of pagePostsTextFilters) {
        result.params.filters[filter.name] = {contains: null, notContains: null}
      }
    break
  }

  return result
}

function getInitData () {
  let data = {}
  data[CONSTANTS.CAMPAIGNSOURCE.FEED] = getSourceInitData(CONSTANTS.CAMPAIGNSOURCE.FEED)
  data[CONSTANTS.CAMPAIGNSOURCE.PAGEPOSTS] = getSourceInitData(CONSTANTS.CAMPAIGNSOURCE.PAGEPOSTS)
  return data
}

export default {
  name: 'buffer-dialog',
  mixins: [Confirm],
  components: {
    ErrorCallout,
    InfoCallout,
    JoiForm,
    FileUpload,
    PageInput,
    BufferItemList
  },
  props: {
    adsetprotoId: {
      type: String,
      required: true
    },
    pages: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      data: getInitData(),
      sources: [],
      validationSchema: validationSchemas['adsetproto.source.edit'],
      fileUploadHeaders: getHeaders(),
      bufferUploadEndpoint: rewriteUrl(`api://adsetprotos/${this.adsetprotoId}/sources?type=${CONSTANTS.CAMPAIGNSOURCE.FILE}`),
      sampleCSVUrl: API_URL + '/contents sample.csv',
      pagePostsTextFilters: pagePostsTextFilters,
      pagePostsTypes: pagePostsTypes,
      sourceType: CONSTANTS.CAMPAIGNSOURCE,
      // partnerId: null,
      // clientId: null,
      // partners: [],
      // clients: [],
      // currentClientId: null,
      error: null,
      info: null
    }
  },
  computed: {
    domUid () {
      return 'buffer-dialog-' + this._uid
    }
  },
  watch: {
    'partnerId': 'onPartnerChanged'
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    show (currentClientId) {
      this.currentClientId = currentClientId
      if (this.modalElement) {
        this.modalElement.modal('show')
      } else {
        this.modalElement = $(this.$el).modal({
          focus: true,
          show: true
        })
      }

      this.reset()
      this.loadSources()
    },
    hide () {
      if (this.modalElement) {
        this.modalElement.modal('hide')
      }
    },
    reset () {
      this.data = getInitData()
      this.sources = []
    },
    resetSource (sourceType) {
      this.data[sourceType] = getSourceInitData(sourceType)
    },
    setSource (sourceType, sourceData) {
      this.data[sourceType] = merge.recursive(getSourceInitData(sourceType), sourceData)
    },
    async loadSources () {
      this[mutationTypes.BUSY_ON]()
      this.error = null
      this.info = null

      try {
        this.sources = await adsetprotoService.loadSources(this.adsetprotoId, {types: [this.sourceType.FEED, this.sourceType.PAGEPOSTS]})
        let feedSource = _.find(this.sources, {type: this.sourceType.FEED})
        if (feedSource) {
          this.setSource(this.sourceType.FEED, feedSource)
        }
        let pagePostsSource = _.find(this.sources, {type: this.sourceType.PAGEPOSTS})
        if (pagePostsSource) {
          this.setSource(this.sourceType.PAGEPOSTS, pagePostsSource)
        }
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
      }
    },
    async saveExternalFeedSource (formData) {
      return this.saveSource(this.sourceType.FEED, formData)
    },
    async savePagePostsSource (formData) {
      return this.saveSource(this.sourceType.PAGEPOSTS, formData)
    },
    async saveSource (sourceType, formData) {
      this[mutationTypes.BUSY_ON]()
      this.error = null
      this.info = null
      try {
        if (sourceType === CONSTANTS.CAMPAIGNSOURCE.FILE) {
          let uploadResult = await this.$refs.fileBufferContents.upload()
          let adsetprotoSource = uploadResult[0].response
          this.info = new SafeString(`File uploaded successfully, <b>${adsetprotoSource.readItemCount}</b> new item(s) added to the buffer.`)
        } else {
          let adsetprotoSource = await adsetprotoService.saveSource(this.adsetprotoId, formData.id, formData)
          this.info = 'Source saved.'
          this.setSource(sourceType, adsetprotoSource)
        }
      } catch (err) {
        this.error = err
      } finally {
        this[mutationTypes.BUSY_OFF]()
        if (sourceType === CONSTANTS.CAMPAIGNSOURCE.FILE) {
          this.$refs.fileBufferContents.clear()
        }
      }
    },
    deleteSource (sourceType, id) {
      this.error = null
      this.info = null
      this.confirm('Delete', 'Are you sure?').then((confirmed) => {
        if (confirmed) {
          this[mutationTypes.BUSY_ON]()
          adsetprotoService.removeSource(this.adsetprotoId, id).then((result) => {
            this[mutationTypes.BUSY_OFF]()
            this.resetSource(sourceType)
          }).catch((err) => {
            this[mutationTypes.BUSY_OFF]()
            this.error = err
          })
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
