<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>
import PartialsBreadcrumbs from '../partials/Breadcrumbs'
export default {
  name: 'view-default-index',
  components: {
    'partials-breadcrumbs': PartialsBreadcrumbs
  },
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
