var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 mt-4 mb-2 text-center" },
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("../../assets/logo-med.jpg"), alt: "" }
              })
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.loginWithFacebookEnabled
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-sm-12 col-md-6 mt-2 text-center" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary trans-uppercase",
                  attrs: { type: "button", disabled: !_vm.serviceFbReady },
                  on: {
                    click: function($event) {
                      return _vm.loginWithFacebook()
                    }
                  }
                },
                [_vm._v("Login with Facebook")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loginWithEmailAndPasswordEnabled
        ? _c(
            "div",
            [
              _vm.loginWithFacebookEnabled
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _vm._m(0)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loginWithFacebookEnabled
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _vm._m(1)
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "joi-form",
                {
                  attrs: { data: _vm.data, schema: _vm.validationSchema },
                  on: { formdataready: _vm.loginWithEmailAndPassword }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-row justify-content-center" },
                    [
                      _c("div", { staticClass: "form-group col-md-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.email,
                              expression: "data.email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "email",
                            placeholder: "email",
                            name: "email"
                          },
                          domProps: { value: _vm.data.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.data, "email", $event.target.value)
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row justify-content-center" },
                    [
                      _c("div", { staticClass: "form-group col-md-4" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.password,
                              expression: "data.password"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            placeholder: "password",
                            name: "password"
                          },
                          domProps: { value: _vm.data.password },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.data,
                                "password",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-row justify-content-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4 text-center" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary trans-uppercase",
                              attrs: { type: "submit" }
                            },
                            [_vm._v("Login")]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("error-reveal", { attrs: { error: _vm.error } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-3 mt-4 text-center" }, [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12 mt-4 mb-4 text-center" }, [
      _c("span", [_vm._v("or login with username and password")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }