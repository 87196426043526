
<template>
  <div>
    <joi-form :data="data" :schema="validationSchema" ref="form">
      <div class="form-row">
        <div class="form-group col-md-6">
          <error-reveal :error="error"></error-reveal>
          <info-reveal :message="info"></info-reveal>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>FB Ad ID:</label>
          <input type="text" v-model="data.fbAdId" name="fbAdId" class="form-control">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <button type="button" @click="restartAd()" class="btn btn-primary trans-uppercase">Restart ad</button>
          <button type="button" @click="stopAd()" class="btn btn-danger trans-uppercase">Stop ad</button>
        </div>
      </div>
    </joi-form>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import merge from 'merge'
import * as mutationTypes from '../../store/mutation-types'
import ErrorReveal from '../../errors/Reveal'
import InfoReveal from '../../containers/InfoReveal'
import JoiForm from '../../inputs/joi-form'
import validationSchemas from '../../validators/schemas'
import adService from '../../services/ad'
import Confirm from '../../mixins/Confirm'

export default {
  name: 'tools-ad',
  mixins: [Confirm],
  components: {
    ErrorReveal,
    InfoReveal,
    JoiForm
  },
  data () {
    return {
      data: {
        fbAdId: null
      },
      validationSchema: validationSchemas['ad.stopOrRestart'],
      error: null,
      info: null
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    async stopAd () {
      this.info = null

      this.$refs.form.validate(async (err, formData) => {
        if (err) {
          return
        }

        let confirmed = await this.confirm('Stopping ad', `Are you sure want to stop ad with ID ${this.data.fbAdId}?`)
        if (!confirmed) {
          return
        }

        try {
          this[mutationTypes.BUSY_ON]()
          await adService.stopAd(this.data.fbAdId)
          this.info = 'Ad stopped.'
        } catch (err) {
          this.error = err
        } finally {
          this[mutationTypes.BUSY_OFF]()
        }
      })
    },
    async restartAd () {
      this.$refs.form.validate(async (err, formData) => {
        if (err) {
          return
        }

        let confirmed = await this.confirm('Restarting ad', `Are you sure want to restart ad with ID ${this.data.fbAdId}?`)
        if (!confirmed) {
          return
        }

        try {
          this[mutationTypes.BUSY_ON]()
          await adService.restartAd(this.data.fbAdId)
          this.info = 'Ad stopped.'
        } catch (err) {
          this.error = err
        } finally {
          this[mutationTypes.BUSY_OFF]()
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
