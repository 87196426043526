
<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3" v-if="client">
        <a @click.prevent="back()" href="#" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</a>
        <h6 class="m-0 font-weight-bold text-primary">{{client.partner.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{client.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{title}}</h6>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-12">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-item nav-link active" id="nav-ads-tab" data-toggle="tab" href="#ads" role="tab" aria-controls="ads" aria-selected="true">Ads</a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active p-3 border-left border-bottom border-right" id="ads" role="tabpanel" aria-labelledby="nav-ads-tab">
              <ads-chart v-if="client && id" :clientId="client.id" :adsetProtoId="id"></ads-chart>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <error-reveal :error="error"></error-reveal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import ErrorReveal from '../../errors/Reveal'
import ClientRequired from '../../mixins/ClientRequired'
// import partnerService from '../../services/partner'
import AdsChart from '../systemAnalytics/Ads.vue'

export default {
  name: 'partners-analytics',
  mixins: [ClientRequired],
  components: {
    ErrorReveal,
    AdsChart
  },
  data () {
    return {
      client: null,
      id: null,
      title: 'Analytics',
      error: null
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    onClientLoaded (error, client) {
      this.error = error
      if (client) {
        this.client = client
        if (this.$route.params.id) {
          this.id = this.$route.params.id
        }
      } else {
        this.client = null
      }
    },
    back () {
      this.$router.push({name: 'client-adsetproto-list', params: {clientId: this.client.id}})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
