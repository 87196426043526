
<template>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <a @click.prevent="back()" href="#" class="float-right"><i class="fas fa-fw fa-arrow-circle-left"></i> Back</a>
      <h6 class="m-0 font-weight-bold text-primary" v-if="partner">{{partner.name}} <i class="fas fa-fw fa-angle-double-right"></i> {{title}}</h6>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="form-group col-md-12">
          <nav>
            <div class="nav nav-tabs" id="nav-tab" role="tablist">
              <a class="nav-item nav-link active" id="nav-ads-tab" data-toggle="tab" href="#ads" role="tab" aria-controls="ads" aria-selected="true">Ads</a>
            </div>
          </nav>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active p-3 border-left border-bottom border-right" id="ads" role="tabpanel" aria-labelledby="nav-ads-tab">
              <ads-chart v-if="partner && clientId && campaignId" :partnerId="partner.id" :clientId="clientId" :campaignId="campaignId"></ads-chart>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <error-reveal :error="error"></error-reveal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global */

import { mapMutations } from 'vuex'
import * as mutationTypes from '../../store/mutation-types'
import ErrorReveal from '../../errors/Reveal'
import PartnerRequired from '../../mixins/PartnerRequired'
// import partnerService from '../../services/partner'
import AdsChart from '../systemAnalytics/Ads.vue'

export default {
  name: 'partners-analytics',
  mixins: [PartnerRequired],
  components: {
    ErrorReveal,
    AdsChart
  },
  data () {
    return {
      partner: null,
      clientId: null,
      campaignId: null,
      title: 'Analytics',
      error: null
    }
  },
  methods: {
    ...mapMutations([mutationTypes.BUSY_ON, mutationTypes.BUSY_OFF]),
    async onPartnerLoaded (error, partner) {
      this.error = error
      if (partner) {
        this.partner = partner
        if (this.$route.params.clientId) {
          this.clientId = this.$route.params.clientId
        }
        if (this.$route.params.campaignId) {
          this.campaignId = this.$route.params.campaignId
        }
      } else {
        this.partner = null
      }
    },
    back () {
      this.$router.push({name: 'partner-client-list', params: {partnerId: this.partner.id}})
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
