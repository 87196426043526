var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.$layout
        ? _c(
            _vm.$layout.component,
            _vm._b({ tag: "component" }, "component", _vm.$layout.props, false)
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "busy-overlay", staticStyle: { display: "none" } },
      [_c("img", { attrs: { src: require("../assets/loader.gif") } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }